import React, { useEffect } from 'react';
import { getItems, shallow, useStore } from '../../../zustand/store';
import Modal from '../../Components/Modal';

export default function AlertOnSpk() {
    const items = ['isOnAlertOnSpk', 'userActions'];
    const z = useStore(getItems(items), shallow);
    // 취소
    const onCancel = () => {
        z.userActions.setIsOnAlertOnSpk(false);
    };

    // 스피커 온
    const onConfirm = () => {
        const audioEl = document.querySelector('audio');
        const afterVideoEl = document.querySelector('.after-video');
        audioEl.volume = 1;
        audioEl.muted = false;
        audioEl.play();
        try {
            afterVideoEl.volume = 1;
            afterVideoEl.play();
            afterVideoEl.pause();
        } catch (e) {
            console.log(e)
        }
        z.userActions.setIsOnSpk(true);
        z.userActions.setIsOnAlertOnSpk(false);
    };

    return (
        <Modal
            showConfirmBtn={true}
            confirmText="음소거 해제"
            confrimBtnTheme="danger"
            onConfirm={onConfirm}
            showCancelBtn={true}
            cancelText="취소"
            onCancel={onCancel}
            showCloseBtn={true}
            isOpen={z.isOnAlertOnSpk}
            size="sm"
        >
            <h3 style={{ textAlign: 'center', marginBottom: '2rem' }}>안내사항</h3>
            <p style={{ textAlign: 'center', color: '#B6B6B6' }}>회의 사운드를 듣기 위해 음소거를 해제해주세요</p>
        </Modal>
    );
}
