/* eslint-disable */
import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {getItems, shallow, useStore} from "../../zustand/store";
import Transition from 'react-transition-group/Transition'

const S = {
  Container: styled.div`
    z-index: 9999;
    background-color: rgb(22 22 22 / 56%);
    position: fixed;
    bottom: 90px;
    left: 50%;
    border-radius: 1rem;
    padding: 2rem;
    transition: opacity .4s, transform .4s, visibility .4s;

    &.entering {
      opacity: 1;
      transform: translate(-50%, 0);
      visibility: visible;
    }

    &.entered {
      opacity: 1;
      transform: translate(-50%, 0);
      visibility: visible;
    }

    &.exiting {
      opacity: 0;
      transform: translate(-50%, -20px);
      visibility: hidden;
    }

    &.exited {
      opacity: 0;
      transform: translate(-50%, -20px);
      visibility: hidden;
      pointer-events: none;
    }


    .popup-header {
      text-align: center;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    .popup-content {
      display: flex;
      justify-content: center;
      gap: 2rem;
      background-color: #3A393A;
      padding: 2rem;
      border-radius: 1rem;

      .separator {
        border: 1px solid #4C4C4E;
      }

      .view-mode-item {
        cursor: pointer;

        .svg-wrap {
          width: 70px;
          height: 70px;
          margin: 0 auto 0.5rem auto;
          display: grid;
          place-items: center;
        }

        svg {
          fill: #4c4c4e;
          transition: fill .25s;
        }

        .label {
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .view-mode-item:hover {
        svg {
          fill: #818183;
        }
      }

      .view-mode-item.active {
        svg {
          fill: #fff;
        }
      }

    }

    .popup-footer {
      display: flex;
      justify-content: center;

      .btn {
        width: 100px;
      }
    }

  `
}

function PopupViewMode({isOn, setIsOn}) {
  const items = ['sid', 'isPlayingContentShare', 'viewMode', 'isMobile', 'userActions']
  const z = useStore(getItems(items), shallow)
  const containerRef = useRef(null)

  // 외부 클릭 시 drawer 닫기
  useEffect(() => {
    if (containerRef) {
      function clickOutside(e) {
        if (containerRef.current.classList.contains('entered') && !containerRef.current.contains(e.target)) {
          setIsOn(false)
        }
      }

      document.addEventListener('click', clickOutside)
      return () => {
        document.removeEventListener('click', clickOutside)
      }
    }
  }, [containerRef])

  const changeViewMode = (e) => {
    z.userActions.setViewMode(e.currentTarget.dataset.viewMode)
  }
  return (<Transition in={isOn} timeout={400}>
    {state => <S.Container className={`popup view-mode ${state}`} ref={containerRef}>
      <div className="popup-header">화면 모드</div>
      <div className="popup-content">
        {z.isPlayingContentShare && (!z.isMobile ? <>
          <div className={`view-mode-item ${z.viewMode === 'content-w' ? 'active' : ''}`}
               style={{display: 'none'}}
               onClick={changeViewMode}
               data-view-mode="content-w">
            <div className="svg-wrap">
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 24.36 12.99">
                <g id="c" data-name="Layer 1">
                  <g>
                    <g>
                      <rect x=".29" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(.29 5.04) rotate(-90)"/>
                      <rect x="6.73" y="0" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"
                            transform="translate(6.73 11.48) rotate(-90)"/>
                      <rect x="13.18" y="0" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"
                            transform="translate(13.18 17.93) rotate(-90)"/>
                      <rect x="19.61" y="0" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"
                            transform="translate(19.61 24.36) rotate(-90)"/>
                    </g>
                    <g>
                      <rect y="6.53" width="11.49" height="6.46" rx="1.18" ry="1.18"/>
                      <rect x="12.86" y="6.53" width="11.49" height="6.46" rx="1.18"
                            ry="1.18"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">공유모드 가로</div>
          </div>
          <div className={`view-mode-item ${z.viewMode === 'content-h' ? 'active' : ''}`}
               onClick={changeViewMode}
               data-view-mode="content-h">
            <div className="svg-wrap">
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20.67 17.64">
                <g id="c" data-name="Layer 1">
                  <g>
                    <g>
                      <rect width="14.51" height="8.16" rx="1.23" ry="1.23"/>
                      <rect y="9.48" width="14.51" height="8.16" rx="1.23" ry="1.23"/>
                    </g>
                    <g>
                      <rect x="15.92" width="4.75" height="4.75" rx="1.24" ry="1.24"/>
                      <rect x="15.92" y="6.45" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                      <rect x="15.92" y="12.89" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">공유모드</div>
          </div>
          <div className={`view-mode-item ${z.viewMode === 'tile' ? 'active' : ''}`} onClick={changeViewMode}
               data-view-mode="tile">
            <div className="svg-wrap" style={{padding: '1rem'}}>
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.64 17.64">
                <g id="c" data-name="Layer 1">
                  <g>
                    <rect width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    <rect x="9.62" y="0" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    <rect y="9.62" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    <rect x="9.62" y="9.62" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">타일모드</div>
          </div>
        </> : <>
          <div className={`view-mode-item ${z.viewMode === 'tile' ? 'active' : ''}`} onClick={changeViewMode}
                data-view-mode="tile">
                <div className="svg-wrap" style={{padding: '1rem'}}>
                <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.64 17.64">
                    <g id="c" data-name="Layer 1">
                    <g>
                        <rect width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                        <rect x="9.62" y="0" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                        <rect y="9.62" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                        <rect x="9.62" y="9.62" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    </g>
                    </g>
                </svg>
                </div>
                <div className="label">타일모드</div>
            </div>
          <div className={`view-mode-item ${z.viewMode === 'content-m' ? 'active' : ''}`}
               onClick={changeViewMode}
               data-view-mode="content-m">
            <div className="svg-wrap">
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 24.36 12.99">
                <g id="c" data-name="Layer 1">
                  <g>
                    <g>
                      <rect x=".29" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(.29 5.04) rotate(-90)"/>
                      <rect x="6.73" y="0" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"
                            transform="translate(6.73 11.48) rotate(-90)"/>
                      <rect x="13.18" y="0" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"
                            transform="translate(13.18 17.93) rotate(-90)"/>
                      <rect x="19.61" y="0" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"
                            transform="translate(19.61 24.36) rotate(-90)"/>
                    </g>
                    <g>
                      <rect y="6.53" width="11.49" height="6.46" rx="1.18" ry="1.18"/>
                      <rect x="12.86" y="6.53" width="11.49" height="6.46" rx="1.18"
                            ry="1.18"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">공유모드</div>
          </div>
        </>)}
        {!z.isPlayingContentShare && (!z.isMobile ? <>
          <div className={`view-mode-item ${z.viewMode === 'tile' ? 'active' : ''}`} onClick={changeViewMode}
               data-view-mode="tile">
            <div className="svg-wrap" style={{padding: '1rem'}}>
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.64 17.64">
                <g id="c" data-name="Layer 1">
                  <g>
                    <rect width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    <rect x="9.62" y="0" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    <rect y="9.62" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    <rect x="9.62" y="9.62" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">타일모드</div>
          </div>
          <div className={`view-mode-item ${z.viewMode === 'spotlight-w' ? 'active' : ''}`}
               style={{display: 'none'}}
               onClick={changeViewMode}
               data-view-mode="spotlight-w">
            <div className="svg-wrap">
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 24.36 12.99">
                <g id="c" data-name="Layer 1">
                  <g>
                    <g>
                      <rect x=".29" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(.29 5.04) rotate(-90)"/>
                      <rect x="6.73" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(6.73 11.48) rotate(-90)"/>
                      <rect x="13.18" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(13.18 17.93) rotate(-90)"/>
                      <rect x="19.61" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(19.61 24.36) rotate(-90)"/>
                    </g>
                    <g>
                      <rect y="6.53" width="11.49" height="6.46" rx="1.18" ry="1.18"/>
                      <rect x="12.86" y="6.53" width="11.49" height="6.46" rx="1.18"
                            ry="1.18"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">고정모드 가로</div>
          </div>
          <div className={`view-mode-item ${z.viewMode === 'spotlight-h' ? 'active' : ''}`}
               onClick={changeViewMode}
               data-view-mode="spotlight-h">
            <div className="svg-wrap">
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20.67 17.64">
                <g id="c" data-name="Layer 1">
                  <g>
                    <g>
                      <rect width="14.51" height="8.16" rx="1.23" ry="1.23"/>
                      <rect y="9.48" width="14.51" height="8.16" rx="1.23" ry="1.23"/>
                    </g>
                    <g>
                      <rect x="15.92" width="4.75" height="4.75" rx="1.24" ry="1.24"/>
                      <rect x="15.92" y="6.45" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                      <rect x="15.92" y="12.89" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">고정모드</div>
          </div>
          <div className={`view-mode-item ${z.viewMode === 'activator-w' ? 'active' : ''}`}
               style={{display: 'none'}}
               onClick={changeViewMode}
               data-view-mode="activator-w">
            <div className="svg-wrap">
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 24.07 17.66">
                <g id="c" data-name="Layer 1">
                  <g>
                    <rect x="1.84" y="6.19" width="20.4" height="11.47" rx="1.63" ry="1.63"/>
                    <g>
                      <rect x="0" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(0 4.75) rotate(-90)"/>
                      <rect x="6.45" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(6.45 11.2) rotate(-90)"/>
                      <rect x="12.89" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(12.89 17.64) rotate(-90)"/>
                      <rect x="19.32" y="0" width="4.75" height="4.75" rx="1.24" ry="1.24"
                            transform="translate(19.32 24.07) rotate(-90)"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">화자모드 가로</div>
          </div>
          <div className={`view-mode-item ${z.viewMode === 'activator-h' ? 'active' : ''}`}
               onClick={changeViewMode}
               data-view-mode="activator-h">
            <div className="svg-wrap">
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 25.05 17.35">
                <g id="c" data-name="Layer 1">
                  <g>
                    <g>
                      <rect x="20.3" width="4.75" height="4.75" rx="1.24" ry="1.24"/>
                      <rect x="20.3" y="6.3" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                      <rect x="20.3" y="12.6" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                    </g>
                    <rect y="3.53" width="18.3" height="10.29" rx="1.46" ry="1.46"/>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">화자모드</div>
          </div>
        </> : <>
          <div className={`view-mode-item ${z.viewMode === 'tile' ? 'active' : ''}`} onClick={changeViewMode}
               data-view-mode="tile">
            <div className="svg-wrap" style={{padding: '1rem'}}>
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.64 17.64">
                <g id="c" data-name="Layer 1">
                  <g>
                    <rect width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    <rect x="9.62" y="0" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    <rect y="9.62" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                    <rect x="9.62" y="9.62" width="8.02" height="8.02" rx="2.09" ry="2.09"/>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">타일모드</div>
          </div>
          <div className={`view-mode-item ${z.viewMode === 'activator-m' ? 'active' : ''}`}
               onClick={changeViewMode}
               data-view-mode="activator-m">
            <div className="svg-wrap">
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 25.05 17.35">
                <g id="c" data-name="Layer 1">
                  <g>
                    <g>
                      <rect x="20.3" width="4.75" height="4.75" rx="1.24" ry="1.24"/>
                      <rect x="20.3" y="6.3" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                      <rect x="20.3" y="12.6" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                    </g>
                    <rect y="3.53" width="18.3" height="10.29" rx="1.46" ry="1.46"/>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">화자모드</div>
          </div>
          <div className={`view-mode-item ${z.viewMode === 'spotlight-m' ? 'active' : ''}`}
               onClick={changeViewMode}
               data-view-mode="spotlight-m">
            <div className="svg-wrap">
              <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20.67 17.64">
                <g id="c" data-name="Layer 1">
                  <g>
                    <g>
                      <rect width="14.51" height="8.16" rx="1.23" ry="1.23"/>
                      <rect y="9.48" width="14.51" height="8.16" rx="1.23" ry="1.23"/>
                    </g>
                    <g>
                      <rect x="15.92" width="4.75" height="4.75" rx="1.24" ry="1.24"/>
                      <rect x="15.92" y="6.45" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                      <rect x="15.92" y="12.89" width="4.75" height="4.75" rx="1.24"
                            ry="1.24"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">고정모드</div>
          </div>
        </>)}
      </div>
    </S.Container>}
  </Transition>);
}

export default PopupViewMode;