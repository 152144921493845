import React from 'react';
import Checkbox from '../Components/Checkbox';
import { getItems, shallow, useStore } from '../../zustand/store';

export default function CbHideNotUseCam() {
    const items = ['isOnHideNotUseCam', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const onChange = (e) => {
        z.userActions.setIsOnHideNotUseCam(e.target.checked);
    };
    return (
        <Checkbox id="cb-hide-not-use-cam" name="cb-hide-not-use-cam" text={'카메라 미사용 참가자 숨기기'} checked={z.isOnHideNotUseCam} onChange={onChange} />
    );
}
