import React from 'react'
import styled from "styled-components";

const StyledLabel = styled.label`
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    input {
        display: none;
    }

    input + span.icon {
        margin-left: 10px;
        width: 17px;
        height: 17px;
        border-radius: .42rem;
        border: 1px solid #ababab;
    }

    input:checked + span.icon {
        border: 1px solid var(--color-primary);
        background-color: var(--color-primary);
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
        background-position: center;
        background-size: 120%;
    }
`

export default function Checkbox({id, name, text, value, checked, onChange}) {
  return (
    <StyledLabel>
        <span>{text}</span>
        <input type="checkbox" id={id} name={name} value={value} checked={checked} onChange={onChange}/>
        <span className='icon'></span>
    </StyledLabel>
  )
}
