import React, { useEffect, useRef, useState } from 'react';
import tool from '../../util/tools';
import { getItems, shallow, useStore } from '../../zustand/store';
import S from './Login.styled';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import BtnCam from '../ComponentsF/BtnCam';
import BtnMic from '../ComponentsF/BtnMic';
import BtnSpk from '../ComponentsF/BtnSpk';
import SelectCam from '../ComponentsF/SelectCam';
import SelectSpk from '../ComponentsF/SelectSpk';
import SelectMic from '../ComponentsF/SelectMic';
import IconBtn from '../Components/IconBtn';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from '../../api/meeting';
import AlertMeetingError from './Modal/AlertMettingError';
import Cover from '../Components/Cover';
import { toast, Flip } from 'react-toastify';

function BtnSetting({ isOnSetting, setIsOnSetting }) {
    const toggleSetting = () => {
        setIsOnSetting(!isOnSetting);
    };
    return <IconBtn icon="setting" theme="secondary" isOn={isOnSetting} onType={2} onTheme="white" onClick={toggleSetting} />;
}

function PreviewVideo(props) {
    const items = ['usingStream', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef?.current) {
            videoRef.current.srcObject = z.usingStream;
        }
    }, [videoRef, z.usingStream]);

    return <video className="preview-video" ref={videoRef} autoPlay playsInline muted />;
}

function Login({ meetingType, isPublicLink }) {
    console.debug('Login Rendered');
    let { publicCode, meetingIdx, uuid } = useParams();
    try {
        publicCode = atob(publicCode).replace(/[a-z]/gi, '');
        meetingIdx = publicCode;
    } catch (e) {}

    const items = ['uuid', 'nickname', 'org1', 'org2', 'email', 'urTy', 'isUr', 'infoTy', 'meetingType', 'userActions', 'webinarActions'];
    const z = useStore(getItems(items), shallow);
    const [isOnSetting, setIsOnSetting] = useState(false);
    const [denied, setDenied] = useState('loading');
    const [renderPage, setRenderPage] = useState('UserLoading');
    const navigate = useNavigate();
    const inputNicknameRef = useRef(null);
    const inputOrgRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const _data = {
        webinar: {
            title: `웨비나 진행 (W-${dayjs().format('YY')}-${String(meetingIdx).padStart(5, 0)})`,
            favicon: '/favicon.ico',
            logoImg: '/images/logo-bridge.svg',
            bgImg: '/images/bg.jpg',
            getMeeting: api.getWebinar,
            getMeetingUser: api.getWebinarUser,
            meetingUrl: '/meeting',
        },
        brmeet: {
            title: `BRmeet (M-${dayjs().format('YY')}-${String(meetingIdx).padStart(5, 0)})`,
            favicon: '/favicon.svg',
            logoImg: '/images/logo-brmeet.png',
            bgImg: '/images/bg-brmeet.jpg',
            getMeeting: api.getBrmeet,
            getMeetingUser: api.getBrmeetUser,
            createDevator: api.createBrmeetDevator,
            meetingUrl: '/brmeet/meeting',
        },
        'brmeet-b': {
            title: `BRmeet-B (M-${dayjs().format('YY')}-${String(meetingIdx).padStart(5, 0)})`,
            favicon: '/favicon.ico',
            logoImg: '/images/logo-brmeet-b.png',
            bgImg: '/images/bg.jpg',
            getMeeting: api.getBrmeetB,
            getMeetingUser: api.getBrmeetBUser,
            createDevator: api.createBrmeetBDevator,
            meetingUrl: '/brmeet-b/meeting',
            publicJoin: api
        },
    };

    useEffect(() => {
        (async () => {
            // 미팅 타입 셋팅
            z.userActions.setMeetingType(meetingType);

            // 타이틀 변경
            const htmlTitle = document.querySelector('title');
            htmlTitle.innerHTML = _data[meetingType].title;

            // 파비콘 변경
            const favicon = document.querySelector('link[rel=icon]');
            favicon.href = _data[meetingType].favicon;

            // 터치스크린일 경우 스피커 on
            if (tool.getIsTouchScreen()) z.userActions.setIsOnSpk(true);

            // 미팅정보, 유저정보 불러오기
            let { data: meeting } = await _data[meetingType].getMeeting(meetingIdx);

            console.log({meetingType, meetingIdx, meeting})
            // 미팅이 없을 경우 에러페이지 노출
            if (!meeting) return setRenderPage('MeetingNotFound');

            // 퍼블릭링크가 아니라면 유저정보 불러오기
            let user, POST_SURVEY_YN, POST_SURVEY_SUBMIT_YN;
            if (!isPublicLink) {
                let data = await _data[meetingType].getMeetingUser(meetingIdx, uuid);
                user = data.data;
                POST_SURVEY_YN = data.POST_SURVEY_YN;
                POST_SURVEY_SUBMIT_YN = data.POST_SURVEY_SUBMIT_YN;

                // 유저정보가 없을 경우 에러페이지 노출
                if (!user) return setRenderPage('UserNotFound');

                // 유저정보 zustand에 저장
                await z.userActions.initUser(user);
            }
            // 퍼블링링크라면 유저 정보 초기화
            else {
                await z.userActions.initUser({
                    meetingIdx: meetingIdx,
                });
            }

            z.webinarActions.setUseChimeWebinar(meeting.USE_CHIME_WEBINAR === 1);

            // 웨비나 참석자일 경우 웨비나 정보 셋팅
            if (meetingType === 'webinar' && user.INFO_TY === 3) {
                meeting.POST_SURVEY_YN = POST_SURVEY_YN;
                meeting.POST_SURVEY_SUBMIT_YN = POST_SURVEY_SUBMIT_YN;
                z.webinarActions.setWebinar(meeting);
            }

            // BRmeet 인 경우 미팅 상태 체크
            if (['brmeet', 'brmeet-b'].includes(meetingType)) {
                if (meeting.STATUS === 3 || (meeting.STATUS === 1 && dayjs().isAfter(dayjs(meeting.END_DT)))) return setRenderPage('EndedMeeting');
                if (meeting.STATUS === -1) return setRenderPage('CanceledMeeting');
            }
            // Webinar 인 경우 미팅 상태 체크
            else if (meetingType === 'webinar') {
                if (user.INFO_TY < 3) {
                    if (meeting.STATUS === 9) return setRenderPage('CanceledMeeting');
                    else if (meeting.STATUS === 3) return setRenderPage('EndedMeeting');
                    else if (meeting.MT_STATUS === 3) return setRenderPage('EndedMeeting');
                }
            }

            // 만약 미팅 에러로 인해 퇴장된거라면
            let errCode = searchParams.get('errCode');
            if (errCode) {
                z.userActions.setIsOnAlertMeetingError(true);
            }

            // 토론자라면 장치정보 불러오기 및 셋팅
            if (isPublicLink || user.INFO_TY < 3) {
                setRenderPage('UserLoaded');
                let { isAccess, stream, name, message } = await tool.getAccessOfMediaDevice();
                z.userActions.setHasMediaAccess(isAccess);
                if (!isAccess) {
                    let pVideo = await tool.getVideoAccess();
                    let pAudio = await tool.getAudioAccess();
                    if (!pVideo && !pAudio) setDenied('all');
                    else if (!pVideo) setDenied('video');
                    else if (!pAudio) setDenied('audio');
                } else {
                    setDenied('');
                    z.userActions.fetchDevices();
                }
            }
            // 시청자라면 바로 웨비나 입장
            else if (meeting.USE_CHIME_WEBINAR && user.INFO_TY === 3) {
                navigate(`/meeting`, { replace: false });
            } else {
                return setRenderPage('UserNotFound');
            }

            // 장치정보 변경 이벤트 리스너
            navigator.mediaDevices.ondevicechange = () => {
                z.userActions.fetchDevices();
            };
        })();
    }, []);

    useEffect(() => {
        if (renderPage !== 'UserLoaded') return;
        const tabDom = document.querySelector('.cover .tab');
        if (isOnSetting) {
            tabDom.classList.remove('active-info-group');
            tabDom.classList.add('active-setting-group');
        } else {
            tabDom.classList.remove('active-setting-group');
            tabDom.classList.add('active-info-group');
        }
    }, [isOnSetting, renderPage]);

    const showInfoGroup = () => {
        setIsOnSetting(false);
    };

    const onChangeInputNickname = (e) => {
        z.userActions.setNickname(e.target.value);
        e.target.value ? e.target.classList.remove('error') : e.target.classList.add('error');
    };
    const onChangeInputOrg = (e) => {
        z.userActions.setOrg1(e.target.value);
        e.target.value ? e.target.classList.remove('error') : e.target.classList.add('error');
    };
    const onChangeInputEmail = (e) => {
        z.userActions.setEmail(e.target.value);
        emailRegex.test(e.target.value) ? e.target.classList.remove('error') : e.target.classList.add('error');
    };
    const onClickEnter = async (e) => {
        console.log({meetingType, data: _data[meetingType], meetingIdx})
        let { data: meeting } = await _data[meetingType].getMeeting(meetingIdx);
        console.log(meeting)
        if (denied) return;
        // BRmeet 인 경우
        if (['brmeet', 'brmeet-b'].includes(meetingType)) {
            if (meeting.STATUS === 3 || (meeting.STATUS === 1 && dayjs().isAfter(dayjs(meeting.END_DT)))) return setRenderPage('EndedMeeting');
            else if (meeting.STATUS === -1) return setRenderPage('CanceledMeeting');
            else if (isPublicLink) {
                toast.dismiss();
                if (!(z.nickname && z.org1 && z.email)) return toast.warn('성명, 소속, 이메일을 확인해주세요');
                if (!emailRegex.test(z.email)) return toast.warn('이메일 형식을 확인해주세요');
            } else if (!(z.nickname && z.org1)) {
                toast.dismiss();
                return toast.warn('성명, 소속을 확인해주세요');
            }

            if (isPublicLink) {
                try {
                    let data = await _data[meetingType].createDevator(meetingIdx, z.nickname, z.org1, z.email);
                    console.log('# data :L', data);
                    await z.userActions.initUser(data);
                } catch (e) {
                    console.error(e);
                    if (e.response.status === 400) return toast.warn(e.response.data);
                    else return toast.warn('알수없는 오류가 발생했습니다.\n새로고침 후 다시 시도해주세요.');
                }
            } else {
                await axios.patch(`/api/meeting/externalUser/${z.uuid}`, { nickname: z.nickname, org: z.org1 });
            }
        }
        // Webinar 인 경우
        else if (meetingType === 'webinar') {
            if (meeting.STATUS === 9) return setRenderPage('CanceledMeeting');
            else if (meeting.STATUS === 3) return setRenderPage('EndedMeeting');
            else if (meeting.MT_STATUS === 3) return setRenderPage('EndedMeeting');
        }

        navigate(e.target.dataset.link);
    };

    return (
        <>
            {renderPage === 'UserLoading' ? (
                <Cover
                    bgImg={_data[meetingType].bgImg}
                    logoImg={_data[meetingType].logoImg}
                    useLoader={true}
                    title={`${meetingType === 'webinar' ? '웨비나' : '미팅'} 정보를 가져오고 있습니다`}
                    content="잠시만 기다려주세요"
                />
            ) : renderPage === 'UserNotFound' ? (
                <Cover
                    bgImg={_data[meetingType].bgImg}
                    logoImg={_data[meetingType].logoImg}
                    useLogo={true}
                    useLoader={false}
                    title="유저를 찾지 못했습니다"
                    content="이용해 주셔서 감사합니다"
                />
            ) : renderPage === 'MeetingNotFound' ? (
                <Cover
                    bgImg={_data[meetingType].bgImg}
                    logoImg={_data[meetingType].logoImg}
                    useLogo={true}
                    useLoader={false}
                    title="미팅을 찾지 못했습니다"
                    content="이용해 주셔서 감사합니다"
                />
            ) : renderPage === 'EndedMeeting' ? (
                <Cover
                    bgImg={_data[meetingType].bgImg}
                    logoImg={_data[meetingType].logoImg}
                    useLogo={true}
                    useLoader={false}
                    title={`종료된 ${meetingType === 'webinar' ? '웨비나' : '미팅'} 입니다`}
                    content="이용해 주셔서 감사합니다"
                />
            ) : renderPage === 'CanceledMeeting' ? (
                <Cover
                    bgImg={_data[meetingType].bgImg}
                    logoImg={_data[meetingType].logoImg}
                    useLogo={true}
                    useLoader={false}
                    title={`취소된 ${meetingType === 'webinar' ? '웨비나' : '미팅'} 입니다`}
                    content="이용해 주셔서 감사합니다"
                />
            ) : (
                <S.Container bgImg={_data[meetingType].bgImg} logoImg={_data[meetingType].logoImg} meetingType={meetingType}>
                    <div className="left">
                        <div className="img-wrap">
                            <img src={_data[meetingType].logoImg} alt="logo" />
                        </div>
                        <div className="desc">
                            Choose your audio and
                            <br />
                            video setting for Meeting now
                        </div>
                    </div>
                    <div className="right">
                        <div className="cover">
                            <div className="row preview-video">
                                <div className="preview-video-wrap">
                                    <PreviewVideo />
                                    <div className="device-control-wrap">
                                        <div className="btn-wrap">
                                            <BtnCam isLogin={true} />
                                        </div>
                                        <div className="btn-wrap btn-spk-wrap">
                                            <BtnSpk />
                                        </div>
                                        <div className="btn-wrap">
                                            <BtnMic />
                                        </div>
                                        <div className="btn-wrap">
                                            <BtnSetting isOnSetting={isOnSetting} setIsOnSetting={setIsOnSetting} />
                                        </div>
                                    </div>
                                </div>
                                {denied === 'loading' && (
                                    <div className="txt-permission-denied" style={{ color: '#aeaeae' }}>
                                        장치권한 확인하는중...
                                    </div>
                                )}
                                {denied === 'all' && <div className="txt-permission-denied">카메라/마이크 권한을 허용해주세요</div>}
                                {denied === 'video' && <div className="txt-permission-denied">카메라 권한을 허용해주세요</div>}
                                {denied === 'audio' && <div className="txt-permission-denied">마이크 권한을 허용해주세요</div>}
                                {isOnSetting && (
                                    <div className="btn btn-transparent btn-back" onClick={showInfoGroup} style={{ height: '45px' }}>
                                        이전으로
                                    </div>
                                )}
                                {!isOnSetting && (
                                    <div
                                        to={`${_data[meetingType].meetingUrl}`}
                                        data-link={`${_data[meetingType].meetingUrl}`}
                                        className={`btn btn-transparent btn-join ${denied ? 'disabled' : ''}`}
                                        style={{ height: '45px' }}
                                        onClick={onClickEnter}
                                    >
                                        입장하기
                                    </div>
                                )}
                            </div>
                            <div className="row info">
                                <div className="tab active-info-group">
                                    <div className="info-group">
                                        <div className="ipt-group">
                                            <div className="label">성명</div>
                                            <input
                                                ref={inputNicknameRef}
                                                type="text"
                                                className="ipt-transparent"
                                                value={z.nickname || ''}
                                                onChange={onChangeInputNickname}
                                                disabled={z.isUr || meetingType === 'webinar'}
                                                readOnly={z.isUr || meetingType === 'webinar'}
                                                maxLength={10}
                                            />
                                        </div>
                                        <div className="ipt-group">
                                            <div className="label">소속</div>
                                            <input
                                                ref={inputOrgRef}
                                                type="text"
                                                className="ipt-transparent"
                                                value={z.org1 || ''}
                                                onChange={onChangeInputOrg}
                                                maxLength={30}
                                                disabled={z.isUr || meetingType === 'webinar'}
                                                readOnly={z.isUr || meetingType === 'webinar'}
                                            />
                                        </div>
                                        <div className="ipt-group">
                                            <div className="label">이메일</div>
                                            <input
                                                type="text"
                                                className="ipt-transparent"
                                                value={z.email || ''}
                                                onChange={onChangeInputEmail}
                                                disabled={!isPublicLink}
                                                readOnly={!isPublicLink}
                                            />
                                        </div>
                                    </div>
                                    <div className="setting-group">
                                        <div className="sel-group">
                                            <div className="label">카메라</div>
                                            <SelectCam />
                                        </div>
                                        <div className="sel-group">
                                            <div className="label">스피커</div>
                                            <SelectSpk />
                                        </div>
                                        <div className="sel-group">
                                            <div className="label">마이크</div>
                                            <SelectMic />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isOnSetting && (
                                <div className="row back">
                                    <div className="btn btn-transparent" onClick={showInfoGroup} style={{ height: '45px' }}>
                                        이전으로
                                    </div>
                                </div>
                            )}
                            {!isOnSetting && (
                                <div className="row join">
                                    {denied === 'loading' && (
                                        <div className="txt-permission-denied" style={{ color: '#aeaeae' }}>
                                            장치권한 확인하는중...
                                        </div>
                                    )}
                                    {denied === 'all' && <div className="txt-permission-denied">카메라/마이크 권한을 허용해주세요</div>}
                                    {denied === 'video' && <div className="txt-permission-denied">카메라 권한을 허용해주세요</div>}
                                    {denied === 'audio' && <div className="txt-permission-denied">마이크 권한을 허용해주세요</div>}
                                    <div
                                        to={`${_data[meetingType].meetingUrl}`}
                                        data-link={`${_data[meetingType].meetingUrl}`}
                                        className={`btn btn-transparent btn-join ${denied ? 'disabled' : ''}`}
                                        style={{ height: '45px' }}
                                        onClick={onClickEnter}
                                    >
                                        입장하기
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <AlertMeetingError />
                </S.Container>
            )}
        </>
    );
}

export default Login;
