/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {getItems, shallow, useStore} from "../../../zustand/store";
import Transition from 'react-transition-group/Transition'
import c from '../../../config/config';
import {useSocket} from "../../../Contexts/SocketContext";


const S = {
    Container: styled.div`
        position: fixed;
        top: 50%;
        left: 50%;
        transition: transform 0.4s, opacity 0.4s;
        width: 90%;
        max-width: 400px;
        background: rgb(22 22 22 / 98%);
        border-radius: 1rem;
        z-index: 9999;

        .modal-body {
            .content {
                padding: 3rem 2rem 2rem 2rem;

                .ipt-group {
                    .label {
                        margin-left: 0.5rem;
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
        .modal-footer {
            .row {
                padding-right: 2rem;
                padding-bottom: 2rem;
                display: flex;
                justify-content: end;
                .btn {
                    padding: 1rem 2rem;
                }
                .btn-close {
                    margin-right: 1rem;
                }
            }
        }

        &.entering {
            opacity: 1;
            transform: translate(-50%, calc(-50%));
        }

        &.entered {
            opacity: 1;
            transform: translate(-50%, calc(-50%));
        }

        &.exiting {
            opacity: 0;
            transform: translate(-50%, calc(-50% - 20px));
            visibility: hidden;
        }

        &.exited {
            visibility: hidden;
			pointer-events: none;
            opacity: 0;
            transform: translate(-50%, calc(-50% - 20px));
        }

        // 태블릿 가로/세로 공통 CSS
        @media screen and (max-width: ${c.bp.tw.maxWidth}px) and (orientation: ${c.bp.tw.orientation}),
            screen and (max-width: ${c.bp.th.maxWidth}px) and (orientation: ${c.bp.th.orientation}) {
            width: 90%;
        }
        // 모바일 가로
        @media screen and (max-width: ${c.bp.mw.maxWidth}px) and (max-height: ${c.bp.mw.maxHeight}px) {
            .modal-header {
                .title {
                    font-size: 1.6rem;
                    padding: 1.5rem 1.5rem 0;
                }
            }
            .modal-body {
                .content {
                    padding: 1rem 3rem 0;
                    .setting-group {
                        font-size: 1.2rem;
                        .sel-group {
                            margin-bottom: 0.6rem;
                        }
                    }
                }
            }
            .modal-footer {
                .row {
                    padding: 1.5rem;
                    .btn {
                        font-size: 1.3rem;
                    }
                }
            }
        }
        // 모바일 세로
        @media screen and (max-width: ${c.bp.mh.maxWidth}px) and (orientation: ${c.bp.mh.orientation}) {
            .modal-header {
                .title {
                    font-size: 1.6rem;
                }
            }
            .modal-body {
                .content {
                    flex-direction: column;
                    padding: 3rem 2rem 0;
                    .preview-video-wrap {
                        width: 100%;
                    }
                    .setting-group {
                        width: 100%;
                        font-size: 1.2rem;
                    }
                }
            }
            .modal-footer {
                .row {
                    .btn {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    `,
};

function ModalChangeNickname() {
	const items = ['sid', 'users', 'changeNicknameUUID', 'isOnModalChangeNickname', 'userActions', 'tempActions']
	const z = useStore(getItems(items), shallow)
	const user = z.users[z.changeNicknameUUID];
	const socket = useSocket();
	const [value, setValue] = useState('');
	const containerRef = useRef(null);
	const closeModal = () => {
		setValue('');
		z.tempActions.setChangeNicknameUUID('');
		z.userActions.setIsOnModalChangeNickname(false);
	}
	const submit = () => {
		socket.request.changeNickname({uuid: z.changeNicknameUUID, nickname: value})
		closeModal();
	}

	// 외부 클릭 시 drawer 닫기
	useEffect(() => {
		if (containerRef) {
			function clickOutside(e) {
				if (containerRef.current.classList.contains('entered') && !containerRef.current.contains(e.target)) {
					closeModal()
				}
			}

			document.addEventListener('click', clickOutside)
			return () => {
				document.removeEventListener('click', clickOutside)
			}
		}
	}, [containerRef])

	return (
		<Transition in={z.isOnModalChangeNickname} timeout={400}>
			{state => (
				<S.Container className={`modal modal-setting ${state}`} ref={containerRef}>
					<div className="modal-body">
						<div className="content">
							<div className="ipt-group">
								<div className="label">변경할 이름을 입력해주세요</div>
								<input type="text" className="form-control" placeholder={user?.nickname} value={value} onKeyUp={(e) => {if(e.keyCode === 13) submit() }} onChange={(e) => {
									setValue(e.currentTarget.value)
								}}/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<div className="row">
							<div className="btn btn-secondary btn-close" onClick={closeModal}>취소</div>
							<div className="btn btn-success btn-submit" onClick={submit}>변경</div>
						</div>
					</div>
				</S.Container>
			)}
		</Transition>
	);
}

export default ModalChangeNickname;