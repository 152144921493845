import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

const S = {
    Container: styled.div`
      position: relative;
      // display CSS
      .pick-display {
        cursor: pointer;
        position: relative;
        border-radius: 1rem;
        padding: 1.3rem 3.5rem 1.3rem 1.5rem;
        //background-color: #251F31;
        background-color: rgba(112, 108, 137, 0.38);
      }

      .pick-display:after {
        content: '';
        width: 7px; /* 사이즈 */
        height: 7px; /* 사이즈 */
        border-radius: 1px;
        border-top: 3px solid #9B9A9F; /* 선 두께 */
        border-right: 3px solid #9B9A9F; /* 선 두께 */
        display: inline-block;
        transform: translateY(-70%) rotate(135deg); /* 각도 */
        transition: transform .25s;
        position: absolute;
        top: 50%; /* 기본 0px 값으로 해주세요 */
        right: 1.5rem; /* 기본 0px 값으로 해주세요 */
      }

      // display CSS End

      // options CSS
      .options {
        border-radius: 1rem;
        top: 100%;
        opacity: 0;
        z-index: 1;
        width: 100%;
        display: none;
        overflow: auto;
        max-height: 156px;
        position: absolute;
        padding: 1.3rem 1rem;
        flex-direction: column;
        background-color: #1E172B;
      }

      .option {
        cursor: pointer;
        border-radius: 1rem;
        padding: 1rem;
        margin-bottom: .5rem;

        &:hover {
          background-color: #312A41;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .option.pick {
        background-color: #473F57;
      }

      // display CSS End

      // select open CSS
      &.open .pick-display:after {
        transform: translateY(-30%) rotate(315deg); /* 각도 */
      }

      &.before-open .options {
        display: flex;
      }

      &.open .options {
        display: flex;
        opacity: 1;
      }

      &.open.top .options {
        top: 0;
        transform: translateY(-100%);
        flex-direction: column-reverse;
      }

      // select open CSS End

      // theme CSS
      &.secondary {
        .pick-display {
          background-color: var(--color-secondary);
        }

        .options {
          background-color: var(--color-dark-1);
        }

        .option:hover {
          background-color: var(--color-dark-2);
        }

        .option.pick {
          background-color: var(--color-dark-3);
        }
      }

      // theme CSS End
    `
}

function CustomSelect({options, onClickOption, theme}) {
    const [pickIdx, setPickIdx] = useState(0);
    const customSelectRef = useRef(null)
    const optionsRef = useRef(null)
    const toggleOpen = () => {
        const isOpen = customSelectRef.current.classList.contains('open');
        if (isOpen) {
            customSelectRef.current.classList.remove('open');
        } else {
            customSelectRef.current.classList.add('before-open')
            const wh = window.innerHeight;
            const t = optionsRef.current.getBoundingClientRect().top;
            const h = optionsRef.current.clientHeight;
            customSelectRef.current.classList.remove('before-open')
            if (wh < t + h) {
                customSelectRef.current.classList.add('top')
            } else {
                customSelectRef.current.classList.remove('top')
            }
            customSelectRef.current.classList.add('open')
        }
    }
    const onClickLi = (e) => {
        setPickIdx(e.currentTarget.dataset.idx)
        customSelectRef.current.classList.remove('open');
        customSelectRef.current.classList.remove('top');
        if (onClickOption) onClickOption(e.currentTarget);
    }
    useEffect(() => {
        function clickOutside(e) {
            if (!customSelectRef.current.contains(e.target)) {
                customSelectRef.current.classList.remove('open');
                customSelectRef.current.classList.remove('top');
            }
        }

        document.addEventListener('click', clickOutside)
        return () => {
            document.removeEventListener('click', clickOutside)
        }
    }, [])
    useEffect(() => {
        options.forEach((x, i) => {
            if (x.isPick) setPickIdx(i);
        })
    }, [options])

    return (<S.Container className={`custom-select ${theme ? theme : ''}`} ref={customSelectRef}>
        <div className="pick-display ellipsis" onClick={toggleOpen} draggable={false}>{options[pickIdx]?.label}</div>
        <ul className="options" ref={optionsRef}>
            {options.length > 0 && options.map((x, i) =>
                <li className={`option ${i == pickIdx ? 'pick' : ''}`}
                    data-idx={i}
                    data-value={x.value}
                    key={x.key}
                    onClick={onClickLi}>{x.label}
                </li>)}
        </ul>
    </S.Container>);
}

export default CustomSelect;