import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getItems, shallow, useStore } from '../../zustand/store';
import { useSocket } from '../../Contexts/SocketContext';
import CbHideNotUseCam from './CbHideNotUseCam';

const S = {
    Wrapper: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        background-size: 65%;
        background-image: url('/icon/more.svg');
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 5px;
        transition: background-color;
        cursor: pointer;

        .set {
            position: absolute;
            top: 0;
            right: 0;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            background-color: #313132;
            color: #b4b5b6;
            border-radius: 1rem;
            z-index: 10;
            width: max-content;

            li {
                padding: 0.5rem .7rem .5rem 1rem;
                border-radius: 5px;
                min-width: max-content;
                width: 100%;
                cursor: pointer;
            }

            li:hover {
                background-color: #666565;
                color: white;
            }

            &.close {
                display: none;
            }
        }
    `,
};

function BtnMore2({ meetingType }) {
    const items = ['meetingIdx', 'uuid', 'urTy', 'infoTy', 'isMakeHost', 'isOnForceMuteAll', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const setRef = useRef(null);
    const socket = useSocket();

    const onClickWrapper = (e) => {
        e.stopPropagation();
        setRef.current.classList.remove('close');
        setRef.current.classList.add('open');
    };
    const onClickUl = (e) => {
        e.stopPropagation();
    };
    const goToInvite = () => {
        window.open(`${process.env.REACT_APP_BRMEET_DOMAIN}/sso/detail/${z.meetingIdx}/${z.uuid}`);
        setRef.current.classList.remove('open');
        setRef.current.classList.add('close');
    };
    const forceMuteAll = () => {
        z.isOnForceMuteAll ? socket.request.offForceMuteAll() : socket.request.onForceMuteAll();
    };
    const onMuteAll = () => {
        socket.request.onMuteAll();
        setRef.current.classList.remove('open');
        setRef.current.classList.add('close');
    };

    return (
        <S.Wrapper onClick={onClickWrapper} className="btn-more">
            <ul className="set close" onClick={onClickUl} ref={setRef}>
                {(z.infoTy === 1 || z.isMakeHost) && <li onClick={onMuteAll}>모두 음소거</li>}
                {(['brmeet', 'brmeet-b'].includes(meetingType) && z.urTy < 3) && <li onClick={goToInvite}>초대하기</li>}
                <li>
                    <CbHideNotUseCam />
                </li>
            </ul>
        </S.Wrapper>
    );
}

export default BtnMore2;
