import {Cookies} from 'react-cookie';

const cookies = new Cookies();
export const setCookie = (key, value) => {
  // 쿠키 30일 보관
  const expireDay = 30;
  // console.debug('셋쿠키 발동!', {key, value})
  cookies.set(key, value + '', {maxAge: expireDay * (3600 * 24), path: '/'})
}
export const getCookie = (key) => {
  // console.debug('겟쿠키 발동!', {key})
  return cookies.get(key)
}
export const getCookies = () => {
  // console.debug('겟쿠키스 발동!')
  return cookies.getAll()
}
export const removeCookie = (key) => {
  // console.debug('리무브 쿠키 발동!', {key})
  cookies.remove(key)
}
export const clearCookie = () => { Object.keys(getCookies()).forEach(x => {removeCookie(x)}) }
