import React from 'react';
import {getItems, shallow, useStore} from "../../zustand/store";
import IconBtn from "../Components/IconBtn";

function BtnSendMessage(props) {
    const items = ['isOnDrawerChat', 'userActions']
    const z = useStore(getItems(items), shallow)

    return (

        <>
            <IconBtn icon='sendMessage'
                     theme="primary"
            />
        </>

    );
}

export default BtnSendMessage;