import React from 'react';
import IconBtn from "../Components/IconBtn";
import {getItems, shallow, useStore} from "../../zustand/store";
import styled from 'styled-components';

const S = {
    BtnWrap: styled.div`
        position: relative;

        .tooltip {
            position: absolute;
            background-color: rgb(76, 76, 78);
            color: white;
            border-radius: 1rem;
            top: -10px;
            left: 50%;
            transform: translate(-50%, -100%);
            width: max-content;
            padding: 1rem 2rem;
            text-align: center;
            visibility: hidden;
            pointer-events: none;
            opacity: 0;
            transition: opacity .15s, visibility .15s;
        }

        &.on:hover {
            .tooltip {
                opacity: 1;
                visibility: visible;
            }
        }
    `
}

function BtnRecord({label}) {
    const items = ['isOnRecord', 'userActions']
    const z = useStore(getItems(items), shallow)

    const toggleRecord = async () => {
        z.userActions.setIsOnAlertRecord(true)
    }
    
    return (
        <S.BtnWrap className={`btn-wrap ${z.isOnRecord ? 'on' : 'off'}`}>
            <IconBtn icon='record'
                    theme="secondary"
                    onTheme="secondary"
                    isOn={z.isOnRecord}
                    onType={2}
                    onClick={toggleRecord}
                    disabled={z.isOnRecord}
                    label={label}
            />
            <div className='tooltip'>
                회의종료와 함께<br />
                녹화가 종료됩니다.
            </div>
        </S.BtnWrap>
    );
}

export default BtnRecord;