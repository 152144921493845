/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import {
    ConsoleLogger,
    DefaultActiveSpeakerPolicy,
    DefaultDeviceController,
    DefaultMeetingSession,
    DefaultModality,
    LogLevel,
    AudioProfile,
    MeetingSessionConfiguration,
    BackgroundBlurVideoFrameProcessor,
    BackgroundReplacementVideoFrameProcessor,
    DefaultVideoTransformDevice,
    AllHighestVideoBandwidthPolicy
} from 'amazon-chime-sdk-js';

import { getItems, shallow, useStore } from '../../zustand/store';
import tool from '../../util/tools';
import { useSocket, useControlSocket } from '../../Contexts/SocketContext';
import { useMeetingSession, useMeetingSessionActions } from '../../Contexts/MeetingSessionContext';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import c from '../../config/config';


console.log(dayjs().format('YYYY-MM-DD HH:mm:ss'));
const MeetingSetting = ({meetingType}) => {
    const audioRef = useRef(null);
    const observer = useRef(null);

    const items = [
        'webinarStatus',
        'meetingResponse',
        'attendeeResponse',
        'meetingStarted',
        'isMobile',
        'viewMode',
        'forceExitList',
        'forceMuteList',
        'unForceMuteList',
        'isOnForceMuteAll',
        'isOnHeaderFooter',
        'bgFilterImgs',
        'bgFilterImgsBlob',
        'createDt',

        'users',
        'meetingIdx',
        'uuid',
        'userid',
        'infoTy',
        'urTy',
        'isUr',
        'bridgeYn',
        'sid',
        'attendeeId',
        'nickname',
        'org1',
        'org2',

        'isOnCam',
        'isOnSpk',
        'isOnMic',
        'usingCam',
        'usingSpk',
        'usingMic',
        'usingBgFilter',
        'usingTransformDevice',
        'limitCamCnt',

        'userActions',
        'usersActions',
        'meetingActions',
        'messagesActions',
        'webinarActions',
        'resetStore',
    ];
    const z = useStore(getItems(items), shallow);

    const socket = useSocket();
    const controlSocket = useControlSocket();
    const meetingSession = useMeetingSession();
    const meetingSessionActions = useMeetingSessionActions();
    const navigate = useNavigate();
    const _data = {
        webinar: {
            loginUrl: `/login/${z.meetingIdx}/${z.uuid}`,
        },
        brmeet: {
            loginUrl: `/brmeet/login/${z.meetingIdx}/${z.uuid}`,
        },
        'brmeet-b': {
            loginUrl: `/brmeet-b/login/${z.meetingIdx}/${z.uuid}`,
        },
    };
    const sk = socket.socket;
    const setSocketListener = () => {
        // 나만 받는 응답
        sk.on('redirect', (b) => {
            if (b.replace) navigate(b.url, { replace: true });
            else navigate(b.url);
        });
        sk.on('alreadyAttend', (b) => {
            z.userActions.setIsOnAlertAlreadyAttend(true);
        });
        sk.on('getMeeting', (b) => {
            console.debug('[시작] getMeeting', b);

            if (b.isPlayingMeeting) {
                // 미팅 리스폰스 zustand 에 저장
                z.meetingActions.setMeetingResponse(b.meetingResponse);
                // 미팅 시작 시간 zustand 에 저장
                z.meetingActions.setCreateDt(b.createDt);
                // 미팅 조인
                console.debug('[요청] joinMeeting');
                sk.emit('joinMeeting', { meetingResponse: b.meetingResponse });
            } else if (z.infoTy === 1) {
                // 호스트면 방 생성
                console.debug('[요청] createMeeting');
                sk.emit('createMeeting', { meetingIdx: z.meetingIdx });
            }

            console.debug('[종료] getMeeting');
        });
        sk.on('createdMeeting', (b) => {
            console.debug('[시작] createdMeeting');

            // 미팅 리스폰스 zustand 에 저장
            z.meetingActions.setMeetingResponse(b.meetingResponse);

            // 미팅 시작 시간 zustand 에 저장
            z.meetingActions.setCreateDt(b.createDt);

            // 미팅 조인
            console.debug('[요청] joinMeeting');
            sk.emit('joinMeeting', { meetingResponse: b.meetingResponse });

            console.debug('[종료] createdMeeting');
        });
        sk.on('getInitData', (b) => {
            console.debug('[시작] getInitData', b);

            // 소켓 아이디 셋팅
            z.userActions.setSid(b.sid);

            // 카메라 수 검증
            let onCamCnt = 0;
            Object.keys(b.meeting.waitingUsersUUID).forEach((id) => {
                if (id.includes('content')) return false;
                if (b.meeting.waitingUsersUUID[id].isOnCam) {
                    onCamCnt++;
                }
            });
            Object.keys(b.meeting.usersUUID).forEach((id) => {
                if (id.includes('content')) return false;
                if (b.meeting.usersUUID[id].isOnCam) {
                    onCamCnt++;
                }
            });
            console.log('# onCamCnt :', onCamCnt);
            console.log('# z.isOnCam :', z.isOnCam);
            console.log('# z.limitCamCnt :', z.limitCamCnt);
            if (z.isOnCam && onCamCnt > z.limitCamCnt) {
                console.log('######### 카메라 오프!!!');
                z.userActions.setIsOnCam(false);
                socket.request.offCam({ uuid: z.uuid });
            }

            // 룸 셋팅 ( 참석자, 화면공유, 강제음소거 등 )
            z.meetingActions.initMeeting(b.meeting);

            if (z.infoTy < 3) {
                sk.emit('getMeeting', { meetingIdx: z.meetingIdx });
            }

            // 웨비나 진행상태 표기
            if (b.meeting.wbnData.status === 3) {
                z.webinarActions.setWebinarStatus(4);
            } else if (b.meeting.wbnData.status === 2) {
                z.webinarActions.setWebinarStatus(2);
            }

            if (z.infoTy === 3) {
                z.meetingActions.setCreateDt(b.meeting.wbnData.startDt);
            }

            console.debug('[종료] getInitData');
        });
        sk.on('joined', (b) => {
            z.meetingActions.setAttendeeResponse(b.attendeeResponse);
            z.userActions.setReadyState('play');
        });
        sk.on('getAttendeeResponse', (b) => {
            z.meetingActions.setAttendeeResponse(b.attendeeResponse);
        });

        // 전역알림
        sk.on('noticeJoinUser', (b) => {
            console.debug('[시작] noticeJoinUser');
            z.usersActions.addUser(b.user);
            console.debug('[종료] noticeJoinUser');
        });
        sk.on('noticeExitUser', (b) => {
            console.debug('[시작] noticeExitUser');
            z.usersActions.removeUser(b.uuid);
            console.debug('[종료] noticeExitUser');
        });
        sk.on('noticeOnCam', ({ body }) => {
            z.usersActions.onCam({ uuid: body.user.uuid });
        });
        sk.on('noticeOffCam', ({ body }) => {
            z.usersActions.offCam({ uuid: body.user.uuid });
        });
        sk.on('noticeOnSpk', ({ success, body }) => {
            if (success) {
                z.usersActions.onSpk({ uuid: body.user.uuid });
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOffSpk', ({ success, body }) => {
            if (success) {
                z.usersActions.offSpk({ uuid: body.user.uuid });
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOnFix', ({ success, body }) => {
            console.debug('noticeOnFix');
            if (success) {
                z.usersActions.onFix({ uuid: body.user.uuid });
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOffFix', ({ success, body }) => {
            console.debug('noticeOffFix');
            if (success) {
                z.usersActions.offFix({ uuid: body.user.uuid });
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOnMuteAll', () => {
            z.userActions.setIsOnMic(false);
        });
        sk.on('noticeOnForceMuteAll', () => {
            z.meetingActions.setIsOnForceMuteAll(true);
            z.userActions.setIsOnMic(false);
        });
        sk.on('noticeOffForceMuteAll', () => {
            z.meetingActions.setIsOnForceMuteAll(false);
        });
        sk.on('noticeOnMute', () => {
            z.userActions.setIsOnMic(false);
        });
        sk.on('noticeOnForceMute', ({ success, body }) => {
            if (success) {
                // 음소거된 대상이 나라면 음소거 처리
                if (body.uuid === z.uuid) {
                    z.userActions.setIsOnMic(false);
                }

                if (body.forceMuteList) {
                    z.meetingActions.setForceMuteList(body.forceMuteList);
                }
                if (body.unForceMuteList) {
                    z.meetingActions.setUnForceMuteList(body.unForceMuteList);
                }
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOffForceMute', ({ success, body }) => {
            if (success) {
                if (body.forceMuteList) {
                    z.meetingActions.setForceMuteList(body.forceMuteList);
                }
                if (body.unForceMuteList) {
                    z.meetingActions.setUnForceMuteList(body.unForceMuteList);
                }
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOnContentShare', ({ success, body }) => {
            if (success) {
                tool.getIsMobile() ? z.userActions.setViewMode('content-m') : z.userActions.setViewMode('content-h');

                z.meetingActions.setIsPlayingContentShare(true);
                z.usersActions.addUser(body.user);
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOffContentShare', ({ success, body }) => {
            if (success) {
                z.userActions.setViewMode('tile');
                z.meetingActions.setIsPlayingContentShare(false);
                z.usersActions.removeUser(body.user.uuid);
            } else {
                console.error(body);
            }
        });
        sk.on('noticeForceOffContentShare', ({ success, body }) => {
            if (success) {
                document.querySelector('.s-icon-btn.contentShare.success')?.click();
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOnMakeHost', ({ success, body }) => {
            if (success) {
                z.meetingActions.setMakeHostList(body.makeHostList);
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOffMakeHost', ({ success, body }) => {
            if (success) {
                z.meetingActions.setMakeHostList(body.makeHostList);
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOnForceExit', ({ success, body }) => {
            if (success) {
                z.meetingActions.setForceExitList(body.forceExitList);
            } else {
                console.error(body);
            }
        });
        sk.on('noticeOffForceExit', ({ success, body }) => {
            if (success) {
                z.meetingActions.setForceExitList(body.forceExitList);
            } else {
                console.error(body);
            }
        });
        sk.on('noticeChangeNickname', ({ success, body }) => {
            if (success) {
                z.meetingActions.setChangeNicknameMap(body.changeNicknameMap);
            } else {
                console.error(body);
            }
        });
        sk.on('noticeReceiveMessage', ({ uuid, nickname, org1, org2, message, insDt }) => {
            z.messagesActions.addMessage({
                uuid,
                nickname,
                org1,
                org2,
                message,
                insDt,
            });
        });
        sk.on('noticeOnRecord', () => {
            z.userActions.setIsOnRecord(true);
        });
        sk.on('noticeCloseMeeting', () => {
            navigate(_data[meetingType].loginUrl, {replace: true});
        });
        sk.on('disconnect', (e) => {
            let errCode;
            if (e === 'io server disconnect') errCode = 101;
            else if (e === 'ping timeout') errCode = 103;
            else if (e === 'transport close') errCode = 104;
            else if (e === 'transport error') errCode = 105;

            if (errCode)
                navigate(`${_data[meetingType].loginUrl}?errCode=${errCode}`, {replace: true});

            console.log('소켓 연결 끊어짐', e);
        });

        // 웨비나 시청자 전용 소켓
        sk.on('start chime webinar', ({ meeting, startDt }) => {
            console.log('[시작] start chime webinar', meeting, startDt);
            if (z.infoTy === 3) {
                sk.emit('joinWebinar', { meeting, viewer: z.userActions.getViewerInfo() });
                z.meetingActions.setCreateDt(startDt);
            }
            z.webinarActions.setWebinarStatus(2);
            console.log('[종료] start chime webinar');
        });
        sk.on('end chime webinar', () => {
            console.log('[시작] end chime webinar');
            z.webinarActions.setWebinarStatus(3);
            console.log('[시작] end chime webinar');
        });
        sk.on('restart chime webinar', ({ meeting }) => {
            console.log('[시작] restart chime webinar');
            sk.emit('joinWebinar', { meeting, viewer: z.userActions.getViewerInfo() });
            console.log('[시작] 종료 chime webinar');
        });

        sk.on('joinedWebinar', ({ meetingResponse, attendeeResponse }) => {
            console.log('[시작] joinedWebinar', { meetingResponse, attendeeResponse });
            z.meetingActions.setMeetingResponse(meetingResponse);
            z.meetingActions.setAttendeeResponse(attendeeResponse);
        });
        sk.on('noticeJoinViewer', ({ viewer }) => {
            console.log('[시작] noticeJoinViewer');
            z.usersActions.addViewer(viewer);
            console.log('[종료] noticeJoinViewer');
        });
        sk.on('noticeExitViewer', ({ uuid }) => {
            console.log('[시작] noticeExitViewer');
            z.usersActions.removeViewer(uuid);
            console.log('[종료] noticeExitViewer');
        });
    }; 
    
    // 미팅 시작 전 리스터 셋팅 (미팅 세션 없음)
    const meeting = {
        init: () => {
            const logger = new ConsoleLogger('MyLogger', LogLevel.ERROR);
            const deviceController = new DefaultDeviceController(logger);
            const configuration = new MeetingSessionConfiguration(z.meetingResponse.Meeting, z.attendeeResponse.Attendee);
            configuration.videoDownlinkBandwidthPolicy = new AllHighestVideoBandwidthPolicy(configuration.credentials.attendeeId);
            configuration.videoDownlinkBandwidthPolicy.chooseRemoteVideoSources([]);
            
            const ms = new DefaultMeetingSession(configuration, logger, deviceController);
            socket.setMeetingSession(ms);
            meetingSessionActions.init(ms);
            window.meetingSession = ms;
        },
        startMeeting: async () => {
            console.log('[startMeeting 호출]');
            // 스피커 연결
            meetingSession.audioVideo.bindAudioElement(audioRef.current);
            if (audioRef.current.setSinkId && z.infoTy < 3) {
                audioRef.current.setSinkId(z.usingSpk);
            }

            // 옵저버 등록
            observer.current = {
                audioVideoDidStart: async () => {
                    if (z.usingMic && z.usingMic !== 'undefined') {
                        meetingSession.audioVideo.startAudioInput(z.usingMic);
                    }

                    if (z.isOnMic && z.usingMic !== 'undefined') {
                        meetingSession.audioVideo.realtimeUnmuteLocalAudio();
                    } else {
                        meetingSession.audioVideo.realtimeMuteLocalAudio();
                    }

                    audioRef.current.muted = !z.isOnSpk;
                },

                audioVideoDidStop: (sessionStatus) => {
                    // See the "Stopping a session" section for details.
                    console.log(`[회의 종료됨] [${z.createDt} ~ ${dayjs().format('YYYY-MM-DD HH:mm:ss')}] - 종료 코드 ${sessionStatus.statusCode()}`);
                    if (z.infoTy < 3) {
                        navigate(`${_data[meetingType].loginUrl}?errCode=${sessionStatus.statusCode()}`, { replace: true })
                    }
                },

                videoTileDidUpdate: (tileState) => {
                    // 자신 비디오 바인드
                    if (tileState.localTile) {
                        let localTile = document.getElementsByClassName(`v-${z.sid}`)[0];
                        if (localTile) {
                            let isOn = document.querySelector('.s-icon-btn.cam').classList.contains('on');
                            if(isOn) z.usersActions.setTileId(tileState.boundAttendeeId, tileState.tileId);
                            else z.usersActions.removeTileId(tileState.tileId)
                        }
                    }

                    // 타인 비디오 바인드
                    if (!tileState.localTile && tileState.boundAttendeeId) {
                        z.usersActions.setTileId(tileState.boundAttendeeId, tileState.tileId);
                    }
                },

                videoTileWasRemoved: (tileId) => {
                    console.log('#removeTile', tileId)
                    z.usersActions.removeTileId(tileId)
                },

                audioInputDidChange: (e) => {},

                contentShareDidStart: (e) => {
                    // sk.emit('connWowza', {type: 'publisher', meetingIdx: z.meetingIdx, uuid: 'content'})
                },

                contentShareDidStop: () => {
                    socket.request.offContentShare({ uuid: z.uuid });
                    z.userActions.setIsOnContentShare(false);
                },
                eventDidReceive: (name, attributes) => {
                    // signalingDropped, receivingAudioDropped 주요적으로 확인해보기
                    console.log('eventDidRecieve', {name, attributes})
                }
            };
            // meetingSession.audioVideo.setAudioProfile(AudioProfile.fullbandMusicStereo());
            meetingSession.audioVideo.chooseVideoInputQuality(960, 540, 15);
            meetingSession.audioVideo.setVideoMaxBandwidthKbps(600);
            meetingSession.audioVideo.addObserver(observer.current);
            meetingSession.audioVideo.addContentShareObserver(observer.current);
            meetingSession.audioVideo.addDeviceChangeObserver(observer.current);

            await meetingSession.audioVideo.start();
            z.userActions.fetchDevices();
            z.userActions.setMeetingStarted(true);

            // 참석자 마이크 감지
            const activatorEl = document.querySelector('.activator');
            meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present) => {
                if (present) {
                    meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, (attendeeId, volume, muted, signalStrength) => {
                        const myAttendeeId = z.attendeeResponse.Attendee.AttendeeId;
                        const presentVideoGroup = document.querySelector('.vg-' + presentAttendeeId);
                        const presentUserGroup = document.querySelector('.ug-' + presentAttendeeId);

                        // 상태표시줄 컨트롤
                        if (muted === true) {
                            z.usersActions.offMic(presentAttendeeId);
                        } else if (muted === false) {
                            z.usersActions.onMic(presentAttendeeId);
                        }

                        // 마이크 입력 볼륨 CSS 조절
                        if (presentVideoGroup) presentVideoGroup.querySelector('.mic-sensor .value').style.height = volume * 100 + '%';
                        if (presentUserGroup) presentUserGroup.querySelector('.mic-sensor .value').style.height = volume * 100 + '%';

                        // 모바일 말하는사람 녹색 태두리
                        if (presentVideoGroup) {
                            if(volume > 0.1) {
                                presentVideoGroup.classList.add('speaking');
                                activatorEl.classList.remove('hide')
                            } else {
                                presentVideoGroup.classList.remove('speaking');
                                activatorEl.classList.add('hide')
                            }
                        }

                        if (myAttendeeId === presentAttendeeId) {
                            const value = document.querySelector(`.footer .mic-sensor .value`);
                            if (value) value.style.height = volume * 100 + '%';
                        }
                    });
                }
            });

            // 주화자 감지
            meetingSession.audioVideo.subscribeToActiveSpeakerDetector(new DefaultActiveSpeakerPolicy(), (attendeeIds) => {
                console.log('test')
                if (attendeeIds.length > 0) {
                    z.usersActions.onActivators([attendeeIds[0]]);
                }
            });
        },
    };

    // 소켓 서버에 정보 저장 및 회의 정보 가져오기 요청,
    useEffect(() => {
        // zustand 에 meetingIdx, uuid 없으면 리턴
        if (!(z.meetingIdx || z.uuid)) {
            navigate(`/error/user-not-found`, { replace: true });
        }

        // 1. 권한 허용 여부 확인 및 장치정보 가져오기
        // 2. zustand 에서 유저정보 가져와서 소켓 서버에 저장
        // 3. 키네시스 신호채널 생성
        // 4. 백그라운드 필터 프로세서 생성
        (async () => {
            setSocketListener();

            // 웨비나 사용 시 웨비나 상태 불러오기
            // let { data: status } = await axios.get(`/api/webinar/status/${z.meetingIdx}`);
            // z.webinarActions.setWebinarStatus(status);

            // 웨비나 토론자라면 controlSocket 에 enter 메세지 넣어주기
            if (z.infoTy < 3) {
                controlSocket.emit('debater enter', {
                    user: {
                        uuid: z.uuid,
                        wbnIdx: z.meetingIdx,
                        urTy: z.urTy,
                        infoTy: z.infoTy,
                        nickname: z.nickname,
                        org1: z.org1,
                        org2: z.org2,
                    },
                });

                // 장치 허용 여부 가져오기
                let { isAccess } = await tool.getAccessOfMediaDevice();
                z.userActions.setHasMediaAccess(isAccess);

                // 모바일인지 체크
                let isMobile = tool.getIsMobile();
                z.userActions.setIsMobile(isMobile);
                // z.userActions.setViewMode('spotlight-h'); // ##임시변경##

                // 소켓에 유저 정보 저장
                const user = {
                    uuid: z.uuid,
                    userid: z.userid,
                    meetingIdx: z.meetingIdx,
                    nickname: z.nickname,
                    org1: z.org1,
                    org2: z.org2,
                    infoTy: z.infoTy,
                    isOnSpk: z.isOnSpk,
                    isOnCam: z.isOnCam,
                    isOnMic: z.isOnMic,
                    isMobile: tool.getIsMobile(),
                };
                sk.emit('joinWaitingRoom', { user });

                // 백그라운드 필터 프로세서 준비
                let isSupportBlur = await BackgroundBlurVideoFrameProcessor.isSupported();
                let isSupportBgFilter = await BackgroundReplacementVideoFrameProcessor.isSupported();
                z.userActions.setIsSupportBgBlur(isSupportBlur);
                z.userActions.setIsSupportBgFilter(isSupportBgFilter);
                if (isSupportBlur && isSupportBgFilter && !isMobile) {
                    let blobs = [];
                    for (const x of z.bgFilterImgs) {
                        const image = await fetch(x);
                        const imageBlob = await image.blob();
                        blobs.push(imageBlob);
                    }
                    z.userActions.setBgFilterImgsBlob(blobs);
                } else {
                    z.userActions.setUsingBgFilter(-1);
                }

                // 장치 변경 감지
                navigator.mediaDevices.ondevicechange = () => {
                    z.userActions.fetchDevices();
                };
            }
            // 웨비나 시청자라면
            else if (z.infoTy === 3) {
                const viewer = z.userActions.getViewerInfo();
                controlSocket.emit('enter', viewer);
                sk.emit('enterWebinar', { viewer });

                // 스피커 음소거 처리
                audioRef.current.muted = true;
                z.userActions.setIsOnSpk(false);

                // 모바일인지 체크
                let isMobile = tool.getIsMobile();
                z.userActions.setIsMobile(isMobile);
                z.userActions.setViewMode('tile');
            }
        })();

        let visibiltychange = () => {
            if (process.env.REACT_APP_ENV === 'local') return;

            if (document.visibilityState !== 'visible' && (tool.getIsMobile() || tool.getIsTablet()))
                navigate(`${_data[meetingType].loginUrl}?errCode=201`, {replace: true});
        };

        document.addEventListener('visibilitychange', visibiltychange);
        return () => {
            document.removeEventListener('visibilitychange', visibiltychange);
            // kinesisMaster.current?.stopMaster();
            meetingSession?.audioVideo.stop();
            socket.disconnect();
            controlSocket?.disconnect();
            z.resetStore();
        };
    }, []);

    // 미팅 리스폰스와 어탠디 리스폰스를 받아오면 미팅세션 컨텍스트에 미팅세션 저장
    useEffect(() => {
        if (z.meetingResponse && z.attendeeResponse) {
            meeting.init();
        }
    }, [z.meetingResponse, z.attendeeResponse]);

    // 미팅세션 컨텍스트에 미팅세션 저장 되면 회의 시작
    useEffect(() => {
        if (meetingSession) {
            meeting.startMeeting();
        }
        return () => {
            if (meetingSession) {
                meetingSession.audioVideo.removeObserver(observer.current);
            }
        };
    }, [meetingSession]);

    // 회의 시작 이후 장치 설정
    // 카메라 변경
    useEffect(() => {
        (async () => {
            if (z.meetingStarted) {
                if (!z.usingCam || z.usingCam === 'undefined') {
                    return meetingSession.audioVideo.stopLocalVideoTile();
                }

                if (z.usingBgFilter > -1) {
                    let processors = [];
                    if (z.usingBgFilter === 0) {
                        processors.push(
                            await BackgroundBlurVideoFrameProcessor.create({
                                filterCPUUtilization: 10,
                                blurStrength: 100,
                            })
                        );
                    } else {
                        const options = { imageBlob: z.bgFilterImgsBlob[z.usingBgFilter] };
                        const replacementProcessor = await BackgroundReplacementVideoFrameProcessor.create(null, options);
                        processors.push(replacementProcessor);
                    }

                    let transformDevice = new DefaultVideoTransformDevice(new ConsoleLogger('MyLogger', LogLevel.ERROR), z.usingCam, processors);

                    await meetingSession.audioVideo.startVideoInput(transformDevice);
                } else {
                    await meetingSession.audioVideo.startVideoInput(z.usingCam);
                }

                if (z.isOnCam) {
                    socket.request.onCam({ uuid: z.uuid });
                    await meetingSession.audioVideo.startLocalVideoTile();
                } else {
                    socket.request.offCam({ uuid: z.uuid });
                    await meetingSession.audioVideo.stopLocalVideoTile();
                }
            }
        })();
    }, [z.bgFilterImgsBlob, z.usingCam, z.usingBgFilter, z.isOnCam, meetingSession, z.meetingStarted]);
    // 스피커 변경
    useEffect(() => {
        if (audioRef.current.setSinkId && z.infoTy < 3) {
            audioRef.current.setSinkId(z.usingSpk);
        }
    }, [z.usingSpk, meetingSession, z.meetingStarted, z.infoTy]);
    // 마이크 변경/On/Off
    useEffect(() => {
        (async () => {
            if (z.meetingStarted) {
                if (!z.usingMic || z.usingMic === 'undefined') {
                    return meetingSession.audioVideo.realtimeMuteLocalAudio();
                }
                let stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: z.usingMic } });
                let cloneStream = stream.clone();

                if (z.isOnMic) {
                    meetingSession.audioVideo.realtimeUnmuteLocalAudio();
                } else {
                    meetingSession.audioVideo.realtimeMuteLocalAudio();
                }

                stream.getAudioTracks()[0].enabled = z.isOnMic;
                cloneStream.getAudioTracks()[0].enabled = z.isOnMic;
                // kinesisMaster.current.updateAudioStream(cloneStream);
                meetingSession.audioVideo.startAudioInput(stream);
            }
        })();
    }, [z.usingMic, z.isOnMic, meetingSession, z.meetingStarted]);

    // 스피커 On/Off
    // useEffect(() => {
    //     if (z.meetingStarted) {
    //         console.log('비디오 On/Off 변경', {isOnCam : z.isOnCam})
    //         if (z.isOnCam) {
    //             meetingSession.audioVideo.startLocalVideoTile()
    //         } else {
    //             meetingSession.audioVideo.stopLocalVideoTile()
    //         }
    //     }
    // }, [z.isOnCam, meetingSession, z.meetingStarted])
    // 스피커 On/Off
    useEffect(() => {
        if (audioRef.current) {
            if (z.isOnSpk) {
                audioRef.current.muted = false;
                socket.request.onSpk({ uuid: z.uuid });
            } else {
                audioRef.current.muted = true;
                socket.request.offSpk({ uuid: z.uuid });
            }
        }
    }, [z.isOnSpk, meetingSession]);

    // 화면사이즈 변경 시 모바일 여부 판단
    useEffect(() => {
        const handleResize = () => {
            let userDevice = '';

            // 모바일, 태블릿
            if (window.innerWidth <= c.bp.mw.maxWidth && window.innerHeight <= c.bp.mw.maxHeight) {
                userDevice = 'mobileW';
            } else if (window.innerWidth <= c.bp.mh.maxWidth && window.innerWidth / window.innerHeight < 1) {
                userDevice = 'mobileH';
            } else if (window.innerWidth <= c.bp.tw.maxWidth && window.innerWidth / window.innerHeight > 1) {
                userDevice = 'tabletW';
            } else if (window.innerWidth <= c.bp.th.maxWidth && window.innerWidth / window.innerHeight < 1) {
                userDevice = 'tabletH';
            } else {
                userDevice = 'desktop';
            }

            if(userDevice !== z.userDevice) z.userActions.setUserDevice(userDevice);

            let isMobile = userDevice.includes('mobile')
            z.userActions.setIsMobile(isMobile);

            if (isMobile) {
                if (z.viewMode.includes('spotlight')) z.userActions.setViewMode('spotlight-m');
                else if (z.viewMode.includes('activator')) z.userActions.setViewMode('activator-m');
                else if (z.viewMode.includes('content')) z.userActions.setViewMode('content-m');
            } else {
                if (z.viewMode.includes('spotlight')) z.userActions.setViewMode('spotlight-h');
                else if (z.viewMode.includes('activator')) z.userActions.setViewMode('activator-h');
                else if (z.viewMode.includes('content')) z.userActions.setViewMode('content-h');
            }
        };
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [z.viewMode, z.isMobile, z.userDevice]);

    // 모바일일 경우, 전체화면모드일 자동 헤더푸터 숨김 모드
    useEffect(() => {
        let headerFooterTimer;
        let headerFooterDuration = 3000;
        const onTouchEnd = (e) => {
            console.log('# target :', e.target);
            const headerDom = document.querySelector('#root > div > .header');
            if (e.target.classList.contains('action') && (headerDom.classList.contains('exiting') || headerDom.classList.contains('exited'))) return;
            if (headerDom.classList.contains('exiting') || headerDom.classList.contains('exited')) z.userActions.setIsOnHeaderFooter(true);
            clearTimeout(headerFooterTimer);
            headerFooterTimer = setTimeout(() => {
                z.userActions.setIsOnHeaderFooter(false);
            }, headerFooterDuration);
        };

        if (z.isMobile || z.viewMode === 'fullscreen') {
            z.userActions.setIsOnHeaderFooter(false);
            window.addEventListener('touchend', onTouchEnd);
            window.addEventListener('click', onTouchEnd);
        } else {
            z.userActions.setIsOnHeaderFooter(true);
        }

        return () => {
            clearTimeout(headerFooterTimer);
            window.removeEventListener('touchend', onTouchEnd);
            window.removeEventListener('click', onTouchEnd);
        };
    }, [z.isMobile, z.viewMode]);

    // 강제퇴장 당했을 경우 /error/force-exit 으로 이동
    useEffect(() => {
        if (z.forceExitList.filter((x) => x.uuid === z.uuid)[0]) navigate('/error/force-exit', { replace: true });
    }, [z.forceExitList]);

    return (
        <div>
            <audio muted autoPlay ref={audioRef}></audio>
        </div>
    );
};

export default MeetingSetting;
