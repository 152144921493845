/* eslint-disable */
import styled from 'styled-components';
import c from '../../config/config';

const S = {
    Content: styled.div`
        z-index: 2;
        padding: 2rem 0 calc(var(--footer-height) + 1rem) 0;
        width: 100%;
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        height: 100%;

        .user-list-wrap {
            position: relative;
            flex-direction: column;
            align-items: center;
            z-index: 2;
            height: 100%;
            transition: transform 0.5s, bottom 0.5s;

            .user-list {
                z-index: 2;
                transition: opacity 0.5s;
                height: 100% !important;
                .swiper-wrapper {
                    transition-duration: 0.3s !important;
                }
                .swiper-slide {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    justify-content: center;
                    .video-group {
                        width: fit-content;
                        height: fit-content;
                        aspect-ratio: 16 / 9;
                    }
                }

                .swiper-slide:nth-child(1) {
                    z-index: 25;
                }
                .swiper-slide:nth-child(2) {
                    z-index: 24;
                }
                .swiper-slide:nth-child(3) {
                    z-index: 23;
                }
                .swiper-slide:nth-child(4) {
                    z-index: 22;
                }
                .swiper-slide:nth-child(5) {
                    z-index: 21;
                }
                .swiper-slide:nth-child(6) {
                    z-index: 20;
                }
                .swiper-slide:nth-child(7) {
                    z-index: 19;
                }
                .swiper-slide:nth-child(8) {
                    z-index: 18;
                }
                .swiper-slide:nth-child(9) {
                    z-index: 17;
                }
                .swiper-slide:nth-child(10) {
                    z-index: 16;
                }
                .swiper-slide:nth-child(11) {
                    z-index: 15;
                }
                .swiper-slide:nth-child(12) {
                    z-index: 14;
                }
                .swiper-slide:nth-child(13) {
                    z-index: 13;
                }
                .swiper-slide:nth-child(14) {
                    z-index: 12;
                }
                .swiper-slide:nth-child(15) {
                    z-index: 11;
                }
                .swiper-slide:nth-child(16) {
                    z-index: 10;
                }
                .swiper-slide:nth-child(17) {
                    z-index: 9;
                }
                .swiper-slide:nth-child(18) {
                    z-index: 8;
                }
                .swiper-slide:nth-child(19) {
                    z-index: 7;
                }
                .swiper-slide:nth-child(20) {
                    z-index: 6;
                }
                .swiper-slide:nth-child(21) {
                    z-index: 5;
                }
                .swiper-slide:nth-child(22) {
                    z-index: 4;
                }
                .swiper-slide:nth-child(23) {
                    z-index: 3;
                }
                .swiper-slide:nth-child(24) {
                    z-index: 2;
                }
                .swiper-slide:nth-child(25) {
                    z-index: 1;
                }

                .video-group {
                    height: 125px;
                    aspect-ratio: 16 / 9;

                    .info {
                        bottom: 0.5rem;
                        left: 0.5rem;
                    }

                    .btn-more-wrap {
                        top: 0.5rem;
                        right: 0.5rem;
                    }

                    .video-wrap {
                        border-radius: 1rem;

                        video {
                            background-size: 25%;
                        }
                    }

                    .avatar {
                        font-weight: 500;
                        font-size: 2rem;
                        padding: 3rem;
                    }
                }
            }

            .user-list::-webkit-scrollbar {
                display: none;
            }

            .btn-toggle-user-list {
                width: 150px;
                height: 30px;
                margin: 0;
                position: relative;
                border-radius: 1rem;

                &:after {
                    content: '';
                    width: 7px; /* 사이즈 */
                    height: 7px; /* 사이즈 */
                    border-radius: 1px;
                    border-top: 3px solid #9b9a9f; /* 선 두께 */
                    border-right: 3px solid #9b9a9f; /* 선 두께 */
                    display: inline-block;
                    transition: transform 0.25s;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }

                &.entering:after {
                    transform: translate(-50%, -50%) rotate(-45deg); /* 각도 */
                }

                &.entered:after {
                    transform: translate(-50%, -50%) rotate(-45deg); /* 각도 */
                }

                &.exiting:after {
                    transform: translate(-50%, -50%) rotate(135deg); /* 각도 */
                }

                &.exited:after {
                    transform: translate(-50%, -50%) rotate(135deg); /* 각도 */
                }
            }

            &.entering {
                position: static;
                transform: translate(0, 0);

                .user-list {
                    opacity: 1;
                }
            }

            &.entered {
                position: static;
                transform: translate(0, 0);

                .user-list {
                    opacity: 1;
                }
            }

            &.exiting {
                position: absolute;
                transform: translate(0, calc(-100% + 1rem));

                .user-list {
                    opacity: 0;
                }
            }

            &.exited {
                position: absolute;
                transform: translate(0, calc(-100% + 1rem));

                .user-list {
                    opacity: 0;
                }
            }
        }

        .spotlight-wrap {
            z-index: 1;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            // 스와이퍼 Navigation CSS Desktop Tablet
            .swiper-button-next,
            .swiper-button-prev {
                color: white;
                transform: translateY(-70%);
            }
            .swiper-button-disabled {
                display: none;
            }

            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100% !important;
            }
            .spotlight {
                width: auto;
                height: 100%;
                display: inline-grid;
                padding: 1rem 2rem 0.5rem 2rem;
                gap: 1.12rem 2rem;
                aspect-ratio: 16 / 9;

                .video-group {
                    .avatar {
                        font-size: min(clamp(3rem, 3.64vw, 7rem), clamp(2rem, 3.64vh, 7rem));
                        padding: min(clamp(4rem, 5.2vw, 10rem), clamp(3rem, 5.2vh, 10rem));
                    }
                }

                &.fix-1 {
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr;
                }

                &.fix-2 {
                    grid-template-rows: 1fr;
                    grid-template-columns: repeat(2, 1fr);
                }

                &.fix-3 {
                    grid-template-rows: repeat(2, 1fr);
                    grid-template-columns: repeat(4, 1fr);

                    .video-group:nth-child(1) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(2) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(3) {
                        grid-column: 2/4;
                    }
                }

                &.fix-4 {
                    grid-template-rows: repeat(2, 1fr);
                    grid-template-columns: repeat(2, 1fr);
                }

                &.fix-5 {
                    aspect-ratio: 48 / 18;
                    grid-template-rows: repeat(2, 1fr);
                    grid-template-columns: repeat(6, 1fr);

                    .video-group:nth-child(1) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(2) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(3) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(4) {
                        grid-column: 2/4;
                    }

                    .video-group:nth-child(5) {
                        grid-column: 4/6;
                    }
                }

                &.fix-6 {
                    aspect-ratio: 48 / 18;
                    grid-template-rows: repeat(2, 1fr);
                    grid-template-columns: repeat(3, 1fr);
                }

                &.fix-7 {
                    grid-template-rows: repeat(3, 1fr);
                    grid-template-columns: repeat(3, 1fr);

                    .video-group:nth-child(7) {
                        grid-column: 2/3;
                    }
                }

                &.fix-8 {
                    grid-template-rows: repeat(3, 1fr);
                    grid-template-columns: repeat(6, 1fr);

                    .video-group:nth-child(1) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(2) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(3) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(4) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(5) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(6) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(7) {
                        grid-column: 2/4;
                    }

                    .video-group:nth-child(8) {
                        grid-column: 4/6;
                    }
                }

                &.fix-9 {
                    grid-template-rows: repeat(3, 1fr);
                    grid-template-columns: repeat(3, 1fr);
                }

                &.fix-10 {
                    aspect-ratio: 64 / 27;
                    grid-template-rows: repeat(3, 1fr);
                    grid-template-columns: repeat(4, 1fr);

                    .video-group:nth-child(9) {
                        grid-column: 2/3;
                    }

                    .video-group:nth-child(10) {
                        grid-column: 3/4;
                    }
                }

                &.fix-11 {
                    aspect-ratio: 64 / 27;
                    grid-template-rows: repeat(3, 1fr);
                    grid-template-columns: repeat(8, 1fr);

                    .video-group:nth-child(1) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(2) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(3) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(4) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(5) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(6) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(7) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(8) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(9) {
                        grid-column: 2/4;
                    }

                    .video-group:nth-child(10) {
                        grid-column: 4/6;
                    }

                    .video-group:nth-child(11) {
                        grid-column: 6/8;
                    }
                }

                &.fix-12 {
                    aspect-ratio: 64 / 27;
                    grid-template-rows: repeat(3, 1fr);
                    grid-template-columns: repeat(4, 1fr);
                }

                &.fix-13 {
                    padding: 2rem 6rem;
                    gap: 1rem;

                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(8, 1fr);

                    .video-group:nth-child(1) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(2) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(3) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(4) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(5) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(6) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(7) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(8) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(9) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(10) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(11) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(12) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(13) {
                        grid-column: 4/6;
                    }
                }

                &.fix-14 {
                    padding: 2rem 6rem;
                    gap: 1rem;

                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(4, 1fr);

                    .video-group:nth-child(13) {
                        grid-column: 2/3;
                    }

                    .video-group:nth-child(14) {
                        grid-column: 3/4;
                    }
                }

                &.fix-15 {
                    padding: 2rem 6rem;
                    gap: 1rem;

                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(8, 1fr);

                    .video-group:nth-child(1) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(2) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(3) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(4) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(5) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(6) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(7) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(8) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(9) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(10) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(11) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(12) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(13) {
                        grid-column: 2/4;
                    }

                    .video-group:nth-child(14) {
                        grid-column: 4/6;
                    }

                    .video-group:nth-child(15) {
                        grid-column: 6/8;
                    }
                }

                &.fix-16 {
                    padding: 2rem 6rem;
                    gap: 1rem;

                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(4, 1fr);
                }

                &.fix-17 {
                    padding: 2rem 6rem;
                    gap: 1rem;

                    aspect-ratio: 80 / 36;
                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(10, 1fr);

                    .video-group:nth-child(1) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(2) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(3) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(4) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(5) {
                        grid-column: 9/11;
                    }

                    .video-group:nth-child(6) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(7) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(8) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(9) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(10) {
                        grid-column: 9/11;
                    }

                    .video-group:nth-child(11) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(12) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(13) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(14) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(15) {
                        grid-column: 9/11;
                    }

                    .video-group:nth-child(16) {
                        grid-column: 4/6;
                    }

                    .video-group:nth-child(17) {
                        grid-column: 6/8;
                    }
                }

                &.fix-18 {
                    padding: 2rem 6rem;
                    gap: 1rem;

                    aspect-ratio: 80 / 36;
                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(5, 1fr);

                    .video-group:nth-child(16) {
                        grid-column: 2/3;
                    }

                    .video-group:nth-child(17) {
                        grid-column: 3/4;
                    }

                    .video-group:nth-child(18) {
                        grid-column: 4/5;
                    }
                }

                &.fix-19 {
                    padding: 2rem 6rem;
                    gap: 1rem;

                    aspect-ratio: 80 / 36;
                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(10, 1fr);

                    .video-group:nth-child(1) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(2) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(3) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(4) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(5) {
                        grid-column: 9/11;
                    }

                    .video-group:nth-child(6) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(7) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(8) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(9) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(10) {
                        grid-column: 9/11;
                    }

                    .video-group:nth-child(11) {
                        grid-column: 1/3;
                    }

                    .video-group:nth-child(12) {
                        grid-column: 3/5;
                    }

                    .video-group:nth-child(13) {
                        grid-column: 5/7;
                    }

                    .video-group:nth-child(14) {
                        grid-column: 7/9;
                    }

                    .video-group:nth-child(15) {
                        grid-column: 9/11;
                    }

                    .video-group:nth-child(16) {
                        grid-column: 2/4;
                    }

                    .video-group:nth-child(17) {
                        grid-column: 4/6;
                    }

                    .video-group:nth-child(18) {
                        grid-column: 6/8;
                    }

                    .video-group:nth-child(19) {
                        grid-column: 8/10;
                    }
                }

                &.fix-20 {
                    padding: 2rem 6rem;
                    gap: 1rem;

                    aspect-ratio: 80 / 36;
                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(5, 1fr);
                }
            }
        }

        .fullscreen-wrap {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            //padding: calc(var(--header-height) + 1.5rem) 1.5rem 1.5rem 1.5rem;
            padding: 0;

            .video-group {
                width: 100%;
                height: 100%;

                &:before {
                    border-radius: 0;
                }

                .content-cover {
                    border-radius: 0;
                }
            }
        }

        // 말하는 사람 초록 테두리
        .video-group.speaking .video-wrap:after {
            content: '';
            position: absolute;
            border: 2px solid var(--color-success);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: inherit;
            box-sizing: border-box;
        }

        // contain spotlight div

        // user-list-wrap 열렸을때 CSS
        @media screen and (max-aspect-ratio: 1.8) {
            .user-list-wrap.entering + .spotlight-wrap,
            .user-list-wrap.entered + .spotlight-wrap {
                .spotlight {
                    width: 100%;
                    height: auto;
                }
            }
        }

        // user-list-wrap 닫혔을때 CSS
        @media screen and (max-aspect-ratio: 1.55) {
            .spotlight-wrap {
                .spotlight {
                    width: 100%;
                    height: auto;
                }
            }
        }
        @media screen and (max-aspect-ratio: 44 / 18) {
            .spotlight-wrap {
                .spotlight {
                    &.fix-5,
                    &.fix-6 {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        @media screen and (max-aspect-ratio: 59 / 27) {
            .spotlight-wrap {
                .spotlight {
                    &.fix-10,
                    &.fix-11,
                    &.fix-12 {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        @media screen and (max-aspect-ratio: 73 / 36) {
            .spotlight-wrap {
                .spotlight {
                    &.fix-17,
                    &.fix-18,
                    &.fix-19,
                    &.fix-20 {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        // 세로모드 CSS
        &.spotlight-h,
        &.activator-h,
        &.content-h,
        &.fullscreen {
            padding: var(--header-height) 2rem var(--footer-height) 2rem;
            flex-direction: row-reverse;

            .spotlight-wrap {
            }

            .user-list-wrap {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;

                .user-list {
                    padding: 0;
                    margin-bottom: 0;
                    max-width: 223px;

                    .video-group {
                        width: 223px;
                        height: auto;
                    }
                }

                .btn-toggle-user-list {
                    width: 30px;
                    height: 95px;
                    border-radius: 0.75rem;
                    right: 5px;

                    &.entering:after {
                        transform: translate(-75%, -50%) rotate(45deg); /* 각도 */
                    }

                    &.entered:after {
                        transform: translate(-75%, -50%) rotate(45deg); /* 각도 */
                    }

                    &.exiting:after {
                        transform: translate(-25%, -50%) rotate(225deg); /* 각도 */
                    }

                    &.exited:after {
                        transform: translate(-25%, -50%) rotate(225deg); /* 각도 */
                    }
                }

                &.entering {
                    position: static;
                    transform: translate(0, 0);
                }

                &.entered {
                    position: static;
                    transform: translate(0, 0);
                }

                &.exiting {
                    position: absolute;
                    transform: translate(calc(100% - 1rem), 0);
                }

                &.exited {
                    position: absolute;
                    transform: translate(calc(100% - 1rem), 0);
                }

                &.exited + .spotlight-wrap,
                &.exiting + .spotlight-wrap {
                    padding-top: 0;
                }

                .user-list-navigator {
                    position: absolute;
                    bottom: 10px;
                    right: 0;
                    width: 100%;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    z-index: 10;
                    .btn {
                        position: relative;
                        width: 30px;
                        height: 30px;
                    }
                    .swiper-button-lock {
                        display: block;
                    }
                    .swiper-button-disabled {
                        opacity: 0.5;
                        pointer-events: none;

                        & + .btn-user-list-start {
                            opacity: 0.5;
                            pointer-events: none;
                        }
                        & + .btn-user-list-end {
                            opacity: 0.5;
                            pointer-events: none;
                        }
                    }
                    .arrow {
                        &:after {
                            content: '';
                            width: 7px; /* 사이즈 */
                            height: 7px; /* 사이즈 */
                            border-radius: 1px;
                            border-top: 3px solid #9b9a9f; /* 선 두께 */
                            border-right: 3px solid #9b9a9f; /* 선 두께 */
                            display: inline-block;
                            transition: transform 0.25s;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                        }

                        &.btn-user-list-prev:after {
                            transform: translate(-50%, -25%) rotate(-45deg); /* 각도 */
                        }
                        &.btn-user-list-next:after {
                            transform: translate(-50%, -75%) rotate(135deg); /* 각도 */
                        }
                    }
                    .arrow2 {
                        &:after,
                        &:before {
                            content: '';
                            width: 7px; /* 사이즈 */
                            height: 7px; /* 사이즈 */
                            border-radius: 1px;
                            border-top: 3px solid #9b9a9f; /* 선 두께 */
                            border-right: 3px solid #9b9a9f; /* 선 두께 */
                            display: inline-block;
                            transition: transform 0.25s;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                        }
                        &.btn-user-list-start:after {
                            transform: translate(-50%, 0%) rotate(-45deg); /* 각도 */
                        }
                        &.btn-user-list-start:before {
                            transform: translate(-50%, -50%) rotate(-45deg); /* 각도 */
                        }
                        &.btn-user-list-end:after {
                            transform: translate(-50%, -50%) rotate(135deg); /* 각도 */
                        }
                        &.btn-user-list-end:before {
                            transform: translate(-50%, -100%) rotate(135deg); /* 각도 */
                        }
                    }

                    .btn-user-list-start {
                        order: 1;
                    }
                    .btn-user-list-prev {
                        order: 2;
                    }
                    .user-list-page {
                        order: 3;
                        margin: 0 0.7rem;
                        white-space: nowrap;
                    }
                    .btn-user-list-next {
                        order: 4;
                    }
                    .btn-user-list-end {
                        order: 5;
                    }
                }
            }
        }

        // 비디오 사용자만 보기
        &.display-only-cam {
            .video-group:not(.play) {
                display: none;
            }
        }

        // 태블릿 가로
        @media screen and (max-width: ${c.bp.tw.maxWidth}px) and (orientation: ${c.bp.tw.orientation}) {
            .user-list-wrap {
                .user-list {
                    max-height: 105px;

                    .video-group {
                        max-height: 105px;
                    }
                }

                .btn-toggle-user-list {
                    width: 135px;
                    height: 25px;
                }
            }

            &.spotlight-h,
            &.activator-h,
            &.content-h,
            &.fullscreen {
                .spotlight-wrap {
                }

                .user-list-wrap {
                    .user-list {
                        max-width: 187px;
                        max-height: none;

                        /* .swiper-slide {
                            margin: 5px 0;
                        } */

                        .video-group {
                            width: 187px;
                            height: auto;
                        }
                    }

                    .btn-toggle-user-list {
                        width: 25px;
                        height: 85px;
                    }
                }
            }
        }

        // 태블릿 세로
        @media screen and (max-width: ${c.bp.th.maxWidth}px) and (orientation: ${c.bp.th.orientation}) {
            .user-list-wrap {
                .user-list {
                    max-height: 105px;

                    .video-group {
                        max-height: 105px;
                    }
                }

                .btn-toggle-user-list {
                    width: 135px;
                    height: 25px;
                }
            }
            .spotlight-wrap {
                .spotlight {
                    &.fix-10 {
                        aspect-ratio: 48 / 36;
                        grid-template-rows: repeat(4, 1fr);
                        grid-template-columns: repeat(3, 1fr);

                        .video-group:nth-child(9) {
                            grid-column: auto;
                        }
                        .video-group:nth-child(10) {
                            grid-column: 2/3;
                        }
                    }

                    &.fix-11 {
                        aspect-ratio: 48 / 36;
                        grid-template-rows: repeat(4, 1fr);
                        grid-template-columns: repeat(6, 1fr);
                        .video-group:nth-child(1) {
                            grid-column: 1/3;
                        }
                        .video-group:nth-child(2) {
                            grid-column: 3/5;
                        }
                        .video-group:nth-child(3) {
                            grid-column: 5/7;
                        }
                        .video-group:nth-child(4) {
                            grid-column: 1/3;
                        }
                        .video-group:nth-child(5) {
                            grid-column: 3/5;
                        }
                        .video-group:nth-child(6) {
                            grid-column: 5/7;
                        }
                        .video-group:nth-child(7) {
                            grid-column: 1/3;
                        }
                        .video-group:nth-child(8) {
                            grid-column: 3/5;
                        }
                        .video-group:nth-child(9) {
                            grid-column: 5/7;
                        }
                        .video-group:nth-child(10) {
                            grid-column: 2/4;
                        }
                        .video-group:nth-child(11) {
                            grid-column: 4/6;
                        }
                    }

                    &.fix-12 {
                        aspect-ratio: 48 / 36;
                        grid-template-rows: repeat(4, 1fr);
                        grid-template-columns: repeat(3, 1fr);
                        .video-group:nth-child(9) {
                            grid-column: auto;
                        }
                        .video-group:nth-child(10) {
                            grid-column: auto;
                        }
                        .video-group:nth-child(11) {
                            grid-column: auto;
                        }
                    }
                }
            }

            &.spotlight-h,
            &.activator-h,
            &.content-h,
            &.fullscreen {
                .user-list-wrap {
                    .user-list {
                        max-width: 187px;
                        max-height: none;

                        /* .swiper-slide {
                            margin: 5px 0;
                        } */

                        .video-group {
                            width: 187px;
                            height: auto;
                        }
                    }

                    .btn-toggle-user-list {
                        width: 25px;
                        height: 85px;
                    }
                }
            }
        }

        // 모바일 가로/세로 공통 CSS
        @media screen and (max-width: ${c.bp.mw.maxWidth}px) and (max-height: ${c.bp.mw.maxHeight}px),
            screen and (max-width: 500px) and (orientation: portrait) {
            &.spotlight-m,
            &.activator-m,
            &.content-m,
            &.tile {
                padding: 0;

                .user-list-wrap {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    flex-direction: row-reverse;
                    height: auto;

                    &.entering {
                        position: absolute;
                        transform: translate(0, -20px);
                    }

                    &.entered {
                        position: absolute;
                        transform: translate(0, -20px);
                    }

                    &.exiting {
                        position: absolute;
                        transform: translate(calc(100% - 30px), -20px);
                    }

                    &.exited {
                        position: absolute;
                        transform: translate(calc(100% - 30px), -20px);
                    }

                    .user-list {
                        margin-bottom: 0;
                        overflow: hidden;
                        width: 100px;

                        .video-group {
                            width: 100%;
                            aspect-ratio: 1;
                        }
                    }

                    .btn-toggle-user-list {
                        width: 25px;
                        height: 65px;
                        margin: auto 2.5px auto 0;
                        padding: 0.5rem;
                        /* border-radius: 1rem 0 0 1rem; */

                        &.entering:after {
                            transform: translate(-60%, -50%) rotate(45deg); /* 각도 */
                        }

                        &.entered:after {
                            transform: translate(-60%, -50%) rotate(45deg); /* 각도 */
                        }

                        &.exiting:after {
                            transform: translate(-25%, -50%) rotate(225deg); /* 각도 */
                        }

                        &.exited:after {
                            transform: translate(-25%, -50%) rotate(225deg); /* 각도 */
                        }
                    }
                }
            }

            // 스와이퍼 Navigation CSS Mobile
            .spotlight-list-navigator {
                position: absolute;
                display: flex;
                gap: 5px;
                z-index: 10;
                transform: translateY(0);
                transition: transform 0.5s;

                .arrow {
                    &:after {
                        content: '';
                        width: 7px; /* 사이즈 */
                        height: 7px; /* 사이즈 */
                        border-radius: 1px;
                        border-top: 3px solid #9b9a9f; /* 선 두께 */
                        border-right: 3px solid #9b9a9f; /* 선 두께 */
                        display: inline-block;
                        transition: transform 0.25s;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                    }

                    &.btn-spotlight-list-prev:after {
                        transform: translate(-50%, -25%) rotate(-45deg); /* 각도 */
                    }
                    &.btn-spotlight-list-next:after {
                        transform: translate(-50%, -75%) rotate(135deg); /* 각도 */
                    }
                }
                .arrow2 {
                    &:after,
                    &:before {
                        content: '';
                        width: 7px; /* 사이즈 */
                        height: 7px; /* 사이즈 */
                        border-radius: 1px;
                        border-top: 3px solid #9b9a9f; /* 선 두께 */
                        border-right: 3px solid #9b9a9f; /* 선 두께 */
                        display: inline-block;
                        transition: transform 0.25s;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                    }
                    &.btn-spotlight-list-start:after {
                        transform: translate(-50%, 0%) rotate(-45deg); /* 각도 */
                    }
                    &.btn-spotlight-list-start:before {
                        transform: translate(-50%, -50%) rotate(-45deg); /* 각도 */
                    }
                    &.btn-spotlight-list-end:after {
                        transform: translate(-50%, -50%) rotate(135deg); /* 각도 */
                    }
                    &.btn-spotlight-list-end:before {
                        transform: translate(-50%, -100%) rotate(135deg); /* 각도 */
                    }
                }
                .btn {
                    position: relative;
                    width: 30px;
                    height: 30px;
                }
                .btn-spotlight-list-start {
                    order: 1;
                }
                .btn-spotlight-list-prev {
                    order: 2;
                }
                .spotlight-list-page {
                    order: 3;
                    margin: 0 0.7rem;
                    white-space: nowrap;
                }
                .btn-spotlight-list-next {
                    order: 4;
                }
                .btn-spotlight-list-end {
                    order: 5;
                }
                .swiper-button-disabled {
                    display: flex;
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }

        // 모바일 가로
        @media screen and (max-width: ${c.bp.mw.maxWidth}px) and (max-height: ${c.bp.mw.maxHeight}px) {
            &.spotlight-m,
            &.activator-m,
            &.content-m,
            &.tile {
                .spotlight-wrap {
                    padding: 1rem;

                    .spotlight {
                        /* padding: 0.75rem 2rem; */
                        gap: 0.56rem 1rem;
                    }
                }
            }
            .spotlight-list-navigator {
                top: 0px;
                left: 20px;
                width: 30px;
                height: 100%;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }

        // 모바일 세로
        @media screen and (max-width: ${c.bp.mh.maxWidth}px) and (orientation: ${c.bp.mh.orientation}) {
            &.spotlight-m,
            &.activator-m,
            &.content-m,
            &.tile {
                .user-list-wrap {
                    bottom: calc(var(--footer-height));
                }

                &.exited + .spotlight-wrap,
                &.exiting + .spotlight-wrap {
                    padding-top: 0;
                }

                .spotlight-wrap {
                    display: flex;

                    .spotlight {
                        width: 100% !important;
                        height: auto !important;
                        max-height: 100%;
                        overflow-y: auto;
                        display: inline-flex;
                        justify-content: start;
                        flex-wrap: wrap;
                        aspect-ratio: auto !important;
                        padding: 2.5rem;
                        /* padding: 1rem 1rem calc(var(--footer-height) + 1rem) 1rem; */
                        gap: 1rem;

                        .video-group {
                            width: 100%;
                            aspect-ratio: 16 / 9;
                        }

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                }
            }
            .spotlight-list-navigator {
                bottom: 20px;
                right: 0px;
                width: 100%;
                height: 30px;
                justify-content: center;
                align-items: center;
            }
        }
    `,
};

export default S;
