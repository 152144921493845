/* eslint-disable */
import React, { Fragment } from 'react';
import RunningTime from './RunningTime';
import styled, { keyframes } from 'styled-components';
import { getItems, shallow, useStore } from '../../zustand/store';
import Transition from 'react-transition-group/Transition';
import BtnSetting from '../ComponentsF/BtnSetting';
import c from '../../config/config';
import BtnExit from '../ComponentsF/BtnExit';
import Activator from './Activator';
const A = {
    Flash: keyframes`
        50% { opacity: 0; }
    `,
};
const S = {
    Header: styled.div`
        /* width: 100px; */
        display: flex;
        height: var(--header-height);
        justify-content: start;
        align-items: center;
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 2rem;
        gap: 2rem;

        transition: transform 0.5s;

        .right {
            .runningtime {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                color: #8c8d90;
                font-size: 2rem;
                gap: 1rem;

                .indicator-record {
                    width: 0.7rem;
                    height: 0.7rem;
                    border-radius: 2rem;
                    background: var(--color-danger);
                    animation: ${A.Flash} 1.5s step-end infinite;
                }
            }
        }

        .left {
            display: none;
            gap: 1.5rem;

            .btn-wrap {
                width: 45px;
                height: 45px;
            }
        }

        .activator {
            position: relative;
        }

        &.entering {
            transform: ${(props) => (props.viewMode === 'fullscreen' ? 'translateY(0%)' : 'translateY(0)')};
        }

        &.entered {
            transform: ${(props) => (props.viewMode === 'fullscreen' ? 'translateY(0%)' : 'translateY(0)')};
        }

        &.exiting {
            transform: translateY(-140%);
        }

        &.exited {
            transform: translateY(-140%);
        }

        // 태블릿 가로
        @media screen and (max-width: ${c.bp.tw.maxWidth}px) and (orientation: ${c.bp.tw.orientation}) {
        }

        // 태블릿 세로
        @media all and (max-width: ${c.bp.th.maxWidth}px) and (orientation: ${c.bp.th.orientation}) {
        }

        // 모바일 가로
        @media screen and (max-width: ${c.bp.mw.maxWidth}px) and (max-height: ${c.bp.mw.maxHeight}px) {
            padding: 2rem;
            justify-content: space-between;

            &.entering + .content,
            &.entered + .content {
                .user-list-wrap {
                    bottom: calc(var(--footer-height) - 1rem);
                }
            }

            &.exiting + .content,
            &.exited + .content {
                .user-list-wrap {
                    bottom: 0;
                }
            }
        }

        // 모바일 세로
        @media all and (max-width: ${c.bp.mh.maxWidth}px) and (orientation: ${c.bp.mh.orientation}) {
            //background-color: rgba(49, 48, 49, 0.56);
            padding: 4rem 0 0 2rem;
            justify-content: space-between;
            width: 50%;
            .left {
                display: flex;
            }
            .right {
                width: auto;
                position: absolute;
                right: 0;
                top: 3.5rem;
                transform: translateX(50%);
            }
            .activator {
                position: absolute;
                width: 100vw;
                transform: translateY(140%);
                transition: transform 0.5s;
            }

            &.entering, &.entered {
                .activator {
                    transform: translateY(-140%);
                }
            }

            &.entering + .content,
            &.entered + .content {
                .user-list-wrap {
                    bottom: var(--footer-height);
                }
            }

            &.exiting + .content,
            &.exited + .content {
                .user-list-wrap {
                    bottom: 0;
                }
            }
        }
    `,
};

function Header(props) {
    const items = ['infoTy', 'isOnHeaderFooter', 'viewMode', 'userDevice'];
    const z = useStore(getItems(items), shallow);

    return (
        <Transition in={z.isOnHeaderFooter} timeout={500}>
            {(status) => (
                <S.Header className={`header ${status}`} viewMode={z.viewMode}>
                    <div className="left">
                        {z.infoTy < 3 && (
                            <Fragment>
                                <div className="btn-wrap btn-setting-wrap">
                                    <BtnSetting label="설정" />
                                </div>
                                <div className="btn-wrap btn-exit-wrap">
                                    <BtnExit label="나가기" />
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <div className="right">
                        <RunningTime />
                    </div>
                    <Activator />
                </S.Header>
            )}
        </Transition>
    );
}

export default Header;
