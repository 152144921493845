import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {getItems, shallow, useStore} from "../../zustand/store";

const S = {};
S.Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  
  .bar {
    position: absolute;
    left: 50%;
    bottom:35%;
    transform: translateX(-50%);
    border-radius: 9999px;
    width: 39%;
    height: 67%;
    background: #9FA0A0;
    overflow: hidden;
    
    .value {
      position: absolute;
      left: 50%;
      bottom:0;
      transform: translateX(-50%);
      border-radius: 9999px;
      width: 110%;
      //height: 50%;
      background: var(--color-success);
      overflow: hidden;
      transition: height .3s cubic-bezier(0, 0, 0, 1);
    }
  }
  
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  &.force {
    line { stroke: #D2322F !important;}
    path { stroke: #D2322F !important;}
    .bar {
      background: #D2322F !important;
    }
  }
  
`


function MicSensor({uuid}) {
  const [force, setForce] = useState(false);
  const items = ['sid', 'usersActions', 'isOnForceMuteAll', 'forceMuteList', 'unForceMuteList', 'computeIsOnForceMute'];
  const z = useStore(getItems(items), shallow);

  useEffect(() => {
    setForce(z.computeIsOnForceMute(uuid));
  }, [z.isOnForceMuteAll, z.forceMuteList, z.unForceMuteList])

  return (
    <>
      <S.Wrapper className={`mic-sensor ${force ? 'force' : ''}`}>
        <div className="bar">
          <div className="value"></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.3 9">
          <g>
            {/*<line x1="3.15" y1="4" x2="3.15" y2="1.75" fill='none' stroke='#9FA0A0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3.5'/>*/}
            <line x1="3.15" y1="7" x2="3.15" y2="8.7" fill='none' stroke='#9FA0A0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='.68'/>
            <path d="M.34,4.78c.3,1.27,1.44,2.22,2.8,2.22s2.5-.95,2.8-2.22" fill='none' stroke='#9FA0A0' strokeLinecap='round' strokeLinejoin='round' strokeWidth='.68'/>
          </g>
        </svg>
      </S.Wrapper>
    </>
  );
}

export default MicSensor;