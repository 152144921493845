import React, { useState, useRef, useEffect } from 'react';
import { shallow, useStore, getItems } from '../../../zustand/store';
import styled from 'styled-components';
import { useMeetingSession } from '../../../Contexts/MeetingSessionContext';
const S = {
    Container: styled.div`
        position: fixed;
        z-index: 9999;
    `,
};
export default function PopupAttendee({}) {
    const meetingSession = useMeetingSession();
    const items = ['isOnPopupAttendee', 'popupAttendeeTileId', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const [{ x, y }, setPos] = useState({ x: 15, y: 15 });
    
    const containerRef = useRef(null);
    const videoRef = useRef(null);

    const onConfirm = () => {};
    const onCancel = () => {};
    // useEffect(() => {
    //     setTimeout(() => {
    //         z.userActions.setIsOnPopupAttendee(true);
    //     }, 5000)
    // }, [])

    useEffect(() => {
        if(!meetingSession || !videoRef) return;

        if(z.isOnPopupAttendee && z.popupAttendeeTileId) {
            const videoTile = meetingSession.audioVideo.getVideoTile(z.popupAttendeeTileId);
            if(videoTile)
                videoRef.current.srcObject = videoTile.tileState.boundVideoStream;
        } else {
            videoRef.current.srcObject = null;
        }
    }, [meetingSession, videoRef, z.isOnPopupAttendee, z.popupAttendeeTileId]);

    return (
        <S.Container
            ref={containerRef}
            style={{ transform: `translate(${x}px, ${y}px)`, display: z.isOnPopupAttendee ? 'block' : 'none'}}
            className='popup-attendee'
            draggable={false}
            onMouseDown={(clickEvent) => {
                const padding = 15;
                const width = containerRef.current.clientWidth;
                const height = containerRef.current.clientHeight;

                const mouseMoveHandler = (moveEvent) => {
                    const deltaX = moveEvent.screenX - clickEvent.screenX;
                    const deltaY = moveEvent.screenY - clickEvent.screenY;

                    setPos({
                        x: Math.min(Math.max(x + deltaX, padding), window.innerWidth - width - padding),
                        y: Math.min(Math.max(y + deltaY, padding), window.innerHeight - height - padding),
                    });
                };

                const mouseUpHandler = () => {
                    document.removeEventListener('mousemove', mouseMoveHandler);
                };

                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler, { once: true });
            }}
        >
            <div className="video-wrap" style={{width: '250px'}}>
                <video ref={videoRef} muted autoPlay playsInline style={{maxWidth: '100%'}}></video>
            </div>
        </S.Container>
    );
}
