import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import tool from '../util/tools';
import { shallow } from 'zustand/shallow';
import { getCookies, setCookie } from '../util/cookie';
const store = (set, get) => ({
    // 웨비나 설정
    useChimeWebinar: false,
    webinar: {},
    webinarStatus: 1,
    webinarActions: {
        setUseChimeWebinar: (bool) => {
            set(
                (s) => {
                    s.useChimeWebinar = bool;
                },
                false,
                'webinarActions.setUseChimeWebinar'
            );
        },
        setWebinar: (webinar) => {
            set(
                (s) => {
                    s.webinar = webinar;
                },
                false,
                'webinarActions.setWebinar'
            );
        },
        setWebinarStatus: (status) => {
            set(
                (s) => {
                    s.webinarStatus = status;
                },
                false,
                'webinarActions.setWebinarStatus'
            );
        },
    },

    // 회의 설정
    readyState: 'wait',
    meetingType: '',
    hasMediaAccess: false,
    meetingIdx: null,
    isOnKinesis: true,
    meetingStarted: false,
    

    // 유저정보
    USER_STATE_START__________: '',
    userDevice: '', // 화면 사이즈로 모바일, 태블릿, 데스크탑 기기 판별
    uuid: '',
    userid: '',
    nickname: '',
    org1: '',
    org2: '',
    email: '',
    infoTy: '',
    urTy: '',
    isUr: '',
    bridgeYn: '',
    anonNumber: '',
    isMakeHost: false,

    fixUserList: [],
    unFixUserList: [],
    activeFixUserList: [],
    activeUnFixUserList: [],
    cams: [],
    spks: [],
    mics: [],

    isOnCam: false,
    isOnMic: false,
    isOnSpk: false,

    usingCam: '',
    usingSpk: '',
    usingMic: '',
    usingStream: null,
    usingBgFilter: -1,
    usingTransformDevice: null,

    usingPreviewCam: '',
    usingPreviewSpk: '',
    usingPreviewMic: '',
    usingPreviewStream: null,
    usingPreviewBgFilter: -1,
    usingPreviewTransformDevice: null,

    bgFilterImgs: [
        '',
        '/images/bg-filters/1.jpg',
        '/images/bg-filters/2.jpg',
        '/images/bg-filters/3.jpg',
        '/images/bg-filters/4.jpg',
        '/images/bg-filters/5.jpg',
        '/images/bg-filters/6.jpg',
        '/images/bg-filters/7.jpg',
        '/images/bg-filters/8.jpg',
        '/images/bg-filters/9.jpg',
        '/images/bg-filters/10.jpg',
    ],
    bgFilterImgsBlob: [],

    isOnContentShare: false,
    isOnContentCover: true,
    isOnDrawerChat: false,
    isOnDrawerPeople: false,
    isOnModalSetting: false,
    isOnAlertExit: false,
    isOnAlertAlreadyAttend: false,
    isOnAlertRecord: false,
    isOnAlertWebinar: false,
    isOnAlertMeetingError: false,
    isOnAlertOnSpk: false,
    isOnPopupAttendee: false,
    isOnRecord: false,
    isOnModalChangeNickname: false,
    isOnPopupViewMode: false,
    popupAttendeeTileId: 1,
    isOnEmoji: false,
    isOnContentCover: true,
    isOnHeaderFooter: true,
    isOnHideNotUseCam: false,
    
    isMobile: false,
    isSupportBgBlur: false,
    isSupportBgFilter: false,
    processors: [],
    newMsgCnt: 0,
    viewMode: 'tile', // view mode [ 'tile', 'spotlight-w', 'spotlight-h', 'activator-w', 'activator-h', 'content-w', 'content-h' ]
    userListSwiperIdx: 0,
    spotlightListSwiperIdx: 0,

    userActions: {
        getMyInfo: () => {
            return {
                uuid: get().uuid,
                userid: get().userid,
                wbnIdx: get().meetingIdx,
                urTy: get().urTy,
                infoTy: get().infoTy,
                nickname: get().nickname,
                changedNickname: get().changeNicknameMap[get().uuid],
                org1: get().org1,
                org2: get().org2,
                option: {
                    anonNumber: get().anonNumber,
                },
            };
        },
        getViewerInfo: () => {
            return {
                uuid: get().uuid,
                wbnIdx: get().meetingIdx,
                urTy: get().urTy,
                infoTy: get().infoTy,
                nickname: get().nickname,
                isUr: get().isUr,
                org1: get().org1,
                org2: get().org2,
                option: {
                    bridgeYn: get().bridgeYn,
                    anonNumber: get().anonNumber,
                },
            };
        },
        setReadyState: (readyState) => {
            set(
                (s) => {
                    s.readyState = readyState;
                },
                false,
                'userActions.setReadyState'
            );
        },
        setMeetingType: (meetingType) => {
            set(
                (s) => {
                    s.meetingType = meetingType;
                },
                false,
                'userActions.setMeetingType'
            );
        },
        setHasMediaAccess: (hasMediaAccess) => {
            set(
                (s) => {
                    s.hasMediaAccess = hasMediaAccess;
                },
                false,
                'userActions.setHasMediaAccess'
            );
        },
        setUserDevice: (userDevice) => {
            if(get().userDevice === userDevice) return;

            set(
                (s) => {
                    s.userDevice = userDevice;
                },
                false,
                'userActions.setUserDevice'
            );
        },
        setSid: (sid) => {
            set(
                (s) => {
                    s.sid = sid;
                },
                false,
                'userActions.setSid'
            );
        },
        setMeetingStarted: (boolean) => {
            set(
                (s) => {
                    s.meetingStarted = boolean;
                },
                false,
                'userActions.setMeetingStarted'
            );
        },
        setNickname: (nickname) => {
            set(
                (s) => {
                    s.nickname = nickname;
                },
                false,
                'userActions.setNickname'
            );
        },
        setOrg1: (org1) => {
            set(
                (s) => {
                    s.org1 = org1;
                },
                false,
                'userActions.setOrg1'
            );
        },
        setEmail: (email) => {
            set(
                (s) => {
                    s.email = email;
                },
                false,
                'userActions.setEmail'
            );
        },
        setAnonNumber: (anonNumber) => {
            set(
                (s) => {
                    s.anonNumber = anonNumber;
                },
                false,
                'userActions.setAnonNumber'
            );
        },
        initUser: async (user) => {
            set(
                (s) => {
                    s.meetingIdx = user.MEETING_IDX || user.WEBINAR_IDX;
                    s.uuid = user.UUID;
                    s.userid = user.UR_USERID;
                    s.nickname = user.NAME;
                    s.email = user.EMAIL;
                    s.org1 = user.ORG;
                    s.org2 = user.DIVISION;
                    s.infoTy = user.INFO_TY;
                    s.urTy = user.UR_TY;
                    s.isUr = user.UR_YN;
                    s.bridgeYn = user.BRIDGE_YN;
                },
                false,
                'userActions.initUser'
            );

            return true;
        },
        async fetchDevices() {
            tool.getMediaDevices().then(async ({ cams, spks, mics }) => {
                set(
                    (s) => {
                        s.cams = cams;
                        s.spks = spks;
                        s.mics = mics;
                    },
                    false,
                    '장치 목록 셋팅'
                );

                // 쿠키에 저장된 값 셋팅
                const cookies = getCookies();
                let isOnCam = cookies.isOnCam === 'true' || false;
                let isOnSpk = cookies.isOnSpk === 'true' || false;
                let isOnMic = cookies.isOnMic === 'true' || false;
                let usingCam = cookies.usingCam || cams[0]?.deviceId;
                let usingSpk = cookies.usingSpk || spks[0]?.deviceId;
                let usingMic = cookies.usingMic || mics[0]?.deviceId;
                let usingBgFilter = isNaN(cookies.usingBgFilter * 1) ? -1 : cookies.usingBgFilter * 1;
                let usingStream = null;

                if (cams.length) {
                    const defaultCam = cams[0].deviceId;
                    let device = cams.filter((x) => x.deviceId === usingCam)[0];
                    usingCam = device?.deviceId || defaultCam;
                    if (isOnCam) usingStream = (await tool.getVideoStream(usingCam)).stream;
                }
                if (spks.length) {
                    const defaultSpk = spks[0].deviceId;
                    let device = spks.filter((x) => x.deviceId === usingSpk)[0];
                    usingSpk = device?.deviceId || defaultSpk;
                }
                if (mics.length) {
                    const defaultMic = mics[0].deviceId;
                    let device = mics.filter((x) => x.deviceId === usingMic)[0];
                    usingMic = device?.deviceId || defaultMic;
                }

                get().userActions.setUsingCam(usingCam);
                get().userActions.setUsingSpk(usingSpk);
                get().userActions.setUsingMic(usingMic);
                get().userActions.setUsingStream(usingStream);
                get().userActions.setUsingBgFilter(usingBgFilter);
                get().userActions.setUsingPreviewCam(usingCam);
                get().userActions.setUsingPreviewSpk(usingSpk);
                get().userActions.setUsingPreviewMic(usingMic);
                get().userActions.setUsingPreviewBgFilter(usingBgFilter);
                get().userActions.setIsOnCam(isOnCam);
                get().userActions.setIsOnSpk(isOnSpk);
                get().userActions.setIsOnMic(isOnMic);
            });
        },
        setFixUserList: (fixUserList) => {
            set(
                (s) => {
                    s.fixUserList = fixUserList;
                },
                false,
                'userActions.setFixUserList'
            );
        },
        setUnFixUserList: (unFixUserList) => {
            set(
                (s) => {
                    s.unFixUserList = unFixUserList;
                },
                false,
                'userActions.setUnFixUserList'
            );
        },
        setActiveFixUserList: (activeFixUserList) => {
            set(
                (s) => {
                    s.activeFixUserList = activeFixUserList;
                },
                false,
                'userActions.setActiveFixUserList'
            );
        },
        setActiveUnFixUserList: (activeUnFixUserList) => {
            set(
                (s) => {
                    s.activeUnFixUserList = activeUnFixUserList;
                },
                false,
                'userActions.setActiveUnFixUserList'
            );
        },

        async setIsOnCam(boolean) {
            let stream = null;
            if (boolean) {
                let { success, stream: _stream } = await tool.getVideoStream(get().usingCam);

                if (success) {
                    stream = _stream;
                } else {
                    stream = null;
                    boolean = false;
                }
            }

            set(
                (s) => {
                    s.isOnCam = boolean;
                    s.usingStream = stream;
                    setCookie('isOnCam', boolean);
                },
                false,
                'userActions.setIsOnCam'
            );
        },
        async setIsOnSpk(boolean) {
            set(
                (s) => {
                    s.isOnSpk = boolean;
                    setCookie('isOnSpk', boolean);
                },
                false,
                'userActions.setIsOnSpk'
            );
        },
        async setIsOnMic(boolean) {
            set(
                (s) => {
                    if (!get().usingMic || get().usingMic === 'undefined') return (s.isOnMic = false);
                    s.isOnMic = boolean;
                    setCookie('isOnMic', boolean);
                },
                false,
                'userActions.setIsOnMic'
            );
        },

        async setUsingCam(deviceId) {
            let stream = null;
            if (get().isOnCam) {
                let { success, stream: _stream } = await tool.getVideoStream(deviceId);
                if (success) stream = _stream;
            }

            set(
                (s) => {
                    s.usingCam = deviceId;
                    s.usingStream = stream;
                    setCookie('usingCam', deviceId);
                },
                false,
                'userActions.setUsingCam'
            );
        },
        async setUsingSpk(deviceId) {
            set(
                (s) => {
                    s.usingSpk = deviceId;
                    setCookie('usingSpk', deviceId);
                },
                false,
                'userActions.setUsingSpk'
            );
        },
        async setUsingMic(deviceId) {
            set(
                (s) => {
                    s.usingMic = deviceId;
                    setCookie('usingMic', deviceId);
                },
                false,
                'userActions.setUsingMic'
            );
        },
        setUsingStream(stream) {
            set(
                (s) => {
                    s.usingStream = stream;
                },
                false,
                'userActions.setUsingStream'
            );
        },
        setUsingBgFilter(idx) {
            set(
                (s) => {
                    s.usingBgFilter = idx * 1;
                    setCookie('usingBgFilter', idx * 1);
                },
                false,
                'userActions.setUsingBgFilter'
            );
        },

        async setUsingPreviewCam(deviceId) {
            set(
                (s) => {
                    s.usingPreviewCam = deviceId;
                },
                false,
                'userActions.setUsingPreviewCam'
            );
        },
        async setUsingPreviewSpk(deviceId) {
            set(
                (s) => {
                    s.usingPreviewSpk = deviceId;
                },
                false,
                'userActions.setUsingPreviewSpk'
            );
        },
        async setUsingPreviewMic(deviceId) {
            set(
                (s) => {
                    s.usingPreviewMic = deviceId;
                },
                false,
                'userActions.setUsingPreviewMic'
            );
        },
        setUsingPreviewStream(stream) {
            set(
                (s) => {
                    s.usingPreviewStream = stream;
                },
                false,
                'userActions.setUsingPreviewStream'
            );
        },
        setUsingPreviewBgFilter(idx) {
            set(
                (s) => {
                    s.usingPreviewBgFilter = idx * 1;
                },
                false,
                'userActions.setUsingPreviewBgFilter'
            );
        },

        setIsOnContentShare(boolean) {
            set(
                (s) => {
                    s.isOnContentShare = boolean;
                },
                false,
                'userActions.setIsOnContentShare'
            );
        },
        setIsOnContentCover(boolean) {
            set(
                (s) => {
                    s.isOnContentCover = boolean;
                },
                false,
                'userActions.setIsOnContentCover'
            );
        },
        setIsOnForceMute(boolean) {
            set(
                (s) => {
                    s.isOnForceMute = boolean;
                },
                false,
                'userActions.setIsOnForceMute'
            );
        },

        setIsOnRecord(boolean) {
            set(
                (s) => {
                    s.isOnRecord = boolean;
                },
                false,
                'userActions.setIsOnRecord'
            );
        },
        setIsOnHeaderFooter: (boolean) => {
            set(
                (s) => {
                    s.isOnHeaderFooter = boolean;
                },
                false,
                'userActions.setIsOnHeaderFooter'
            );
        },
        setIsOnDrawerChat(boolean) {
            set(
                (s) => {
                    s.isOnDrawerChat = boolean;
                },
                false,
                'userActions.setIsOnDrawerChat'
            );
        },
        setIsOnDrawerPeople(boolean) {
            set(
                (s) => {
                    s.isOnDrawerPeople = boolean;
                },
                false,
                'userActions.setIsOnDrawerPeople'
            );
        },
        setIsOnModalSetting(boolean) {
            set(
                (s) => {
                    s.isOnModalSetting = boolean;
                },
                false,
                'userActions.setIsOnModalSetting'
            );
        },
        setIsOnModalChangeNickname(boolean) {
            set(
                (s) => {
                    s.isOnModalChangeNickname = boolean;
                },
                false,
                'userActions.setIsOnModalChangeNickname'
            );
        },
        setIsOnHideNotUseCam(boolean) {
            set(
                (s) => {
                    s.isOnHideNotUseCam = boolean;
                },
                false,
                'userActions.setIsOnHideNotUseCam'
            );
        },
        setIsMobile: (boolean) => {
            if(get().isMobile === boolean) return;
            
            set(
                (s) => {
                    s.isMobile = boolean;
                },
                false,
                'userActions.setIsMobile'
            );
        },
        setIsSupportBgBlur: (boolean) => {
            set(
                (s) => {
                    s.isSupportBgBlur = boolean;
                },
                false,
                'userActions.setIsSupportBgBlur'
            );
        },
        setIsSupportBgFilter: (boolean) => {
            set(
                (s) => {
                    s.isSupportBgFilter = boolean;
                },
                false,
                'userActions.setIsSupportBgFilter'
            );
        },
        setIsOnAlertExit: (boolean) => {
            set(
                (s) => {
                    s.isOnAlertExit = boolean;
                },
                false,
                'userActions.setIsOnAlertExit'
            );
        },
        setIsOnAlertAlreadyAttend: (boolean) => {
            set(
                (s) => {
                    s.isOnAlertAlreadyAttend = boolean;
                },
                false,
                'userActions.setIsOnAlertAlreadyAttend'
            );
        },
        setIsOnAlertRecord: (boolean) => {
            set(
                (s) => {
                    s.isOnAlertRecord = boolean;
                },
                false,
                'userActions.setIsOnAlertRecord'
            );
        },
        setIsOnAlertWebinar: (boolean) => {
            set(
                (s) => {
                    s.isOnAlertWebinar = boolean;
                },
                false,
                'userActions.setIsOnAlertWebinar'
            );
        },
        setIsOnAlertMeetingError: (boolean) => {
            set(
                (s) => {
                    s.isOnAlertMeetingError = boolean;
                },
                false,
                'userActions.setIsOnAlertMeetingError'
            );
        },
        setIsOnAlertOnSpk: (boolean) => {
            set(
                (s) => {
                    s.isOnAlertOnSpk = boolean;
                },
                false,
                'userActions.setIsOnAlertOnSpk'
            );
        },
        setIsOnPopupAttendee: (boolean) => {
            set(
                (s) => {
                    s.isOnPopupAttendee = boolean;
                },
                false,
                'userActions.setIsOnPopupAttendee'
            );
        },
        setBgFilterImgsBlob(blobs) {
            set(
                (s) => {
                    s.bgFilterImgsBlob = blobs;
                },
                false,
                'userActions.setBgFilterImgsBlob'
            );
        },
        setNewMsgCnt(cnt) {
            set(
                (s) => {
                    s.newMsgCnt = cnt;
                },
                false,
                'userActions.setNewMsgCnt'
            );
        },
        setViewMode: (viewMode) => {
            set(
                (s) => {
                    s.viewMode = viewMode;
                },
                false,
                'userActions.setViewMode'
            );
        },
        setUserListSwiperIdx: (idx) => {
            set(
                (s) => {
                    s.userListSwiperIdx = idx;
                }, false, 'userActions.setUserListSwiperIdx'
            );
        },
        setSpotlightSwiperIdx: (idx) => {
            set(
                (s) => {
                    s.spotlightListSwiperIdx = idx;
                }, false, 'userActions.setSpotlightSwiperIdx'
            );
        },
        applyDevice() {
            get().userActions.setUsingCam(get().usingPreviewCam);
            get().userActions.setUsingSpk(get().usingPreviewSpk);
            get().userActions.setUsingMic(get().usingPreviewMic);
            get().userActions.setUsingBgFilter(get().usingPreviewBgFilter);
        },
    },

    // users data
    USERS_STATE_START__________: '',
    users: {
        /*
          sid: {
            status: ['beforeLogin', 'wait', 'pairing', 'meeting'],
            meetingId: '',
            attendeeId: '',
            uuid: '',
            nickname: '',
            org1: '',
            org2: '',
            isOnCam: false,
            isOnMic: false,
            isOnSpk: false,
            isOnForcedMute: false,
            isOnFix: false,
            isBlock: false,
          }
        */
    },
    viewers: {},
    usersActions: {
        initUsers: (users) => {
            // Object.keys(users).forEach(x => {
            //   users[x].isOnFix = true;
            // })
            set(
                (s) => {
                    s.users = users;
                },
                false,
                'usersActions.initUsers'
            );
        },
        addUser: (user) => {
            set(
                (s) => {
                    s.users[user.uuid] = user;
                },
                false,
                'usersActions.addUser'
            );
        },
        removeUser: (uuid) => {
            set(
                (s) => {
                    delete s.users[uuid];
                },
                false,
                'usersActions.removeUser'
            );
        },
        initViewers: (viewers) => {
            set(
                (s) => {
                    s.viewers = viewers;
                },
                false,
                'usersActions.initViewers'
            );
        },
        addViewer: (viewer) => {
            set(
                (s) => {
                    s.viewers[viewer.uuid] = viewer;
                },
                false,
                'usersActions.addViewer'
            );
        },
        removeViewer: (uuid) => {
            set(
                (s) => {
                    delete s.viewers[uuid];
                },
                false,
                'usersActions.removeViewer'
            );
        },

        onCam: ({ uuid }) => {
            set(
                (s) => {
                    s.users[uuid].isOnCam = true;
                },
                false,
                'usersActions.onCam'
            );
        },
        offCam: ({ uuid }) => {
            set(
                (s) => {
                    s.users[uuid].isOnCam = false;
                },
                false,
                'usersActions.offCam'
            );
        },
        onSpk: ({ uuid }) => {
            set(
                (s) => {
                    s.users[uuid].isOnSpk = true;
                },
                false,
                'usersActions.onSpk'
            );
        },
        offSpk: ({ uuid }) => {
            set(
                (s) => {
                    s.users[uuid].isOnSpk = false;
                },
                false,
                'usersActions.offSpk'
            );
        },
        onMic: (attendeeId) => {
            let sid = Object.keys(get().users).filter((x) => get().users[x].attendeeId === attendeeId)[0];
            if (sid) {
                set(
                    (s) => {
                        s.users[sid].isOnMic = true;
                    },
                    false,
                    'usersActions.onMic'
                );
            }
        },
        offMic: (attendeeId) => {
            let sid = Object.keys(get().users).filter((x) => get().users[x].attendeeId === attendeeId)[0];
            if (sid) {
                set(
                    (s) => {
                        s.users[sid].isOnMic = false;
                    },
                    false,
                    'usersActions.onMic'
                );
            }
        },
        onFix: ({ uuid }) => {
            set(
                (s) => {
                    s.users[uuid].isOnFix = true;
                },
                false,
                'usersActions.onFix'
            );
        },
        offFix: ({ uuid }) => {
            set(
                (s) => {
                    s.users[uuid].isOnFix = false;
                },
                false,
                'usersActions.offFix'
            );
        },
        onForceMute: ({ uuid }) => {
            set(
                (s) => {
                    s.users[uuid].isOnForceMute = true;
                },
                false,
                'usersActions.onForceMute'
            );
        },
        offForceMute: ({ uuid }) => {
            set(
                (s) => {
                    s.users[uuid].isOnForceMute = false;
                },
                false,
                'usersActions.offForceMute'
            );
        },
        onActivators: (attendeeIds) => {
            set(
                (s) => {
                    Object.keys(get().users).forEach((x) => {
                        s.users[x].isOnActivate = attendeeIds.indexOf(get().users[x].attendeeId) > -1;
                    });
                },
                false,
                'usersActions.onActivate'
            );
        },
        setTileId: (attendeeId, tileId) => {
            let users = get().users;
            let sid = Object.keys(users).filter((sid) => users[sid].attendeeId === attendeeId)[0];
            if (sid) {
                set(
                    (s) => {
                        s.users[sid].tileId = tileId;
                    },
                    false,
                    'usersActions.setTileId'
                );
            }
        },
        removeTileId: (tileId) => {
            let users = get().users;
            let sid = Object.keys(users).filter((sid) => users[sid].tileId === tileId)[0];
            if (sid) {
                set(
                    (s) => {
                        delete s.users[sid].tileId
                    },
                    false,
                    'usersActions.removeTileId'
                );
            }
        },
    },

    // meeting & socket data
    MEETING_STATE_START__________: '',
    sid: '',
    localVideoTile: null,
    meetingResponse: null,
    attendeeResponse: null,
    isPlayingContentShare: false,
    isOnForceMuteAll: false,
    forceMuteList: [],
    unForceMuteList: [],
    makeHostList: [],
    changeNicknameMap: {},
    forceExitList: [],
    createDt: null,
    meetingActions: {
        initMeeting: (meeting) => {
            if (meeting.isPlayingContentShare) {
                get().meetingActions.setIsPlayingContentShare(true);
                tool.getIsMobile() ? get().userActions.setViewMode('content-m') : get().userActions.setViewMode('content-h');
            }
            get().usersActions.initUsers(meeting.usersUUID);
            get().usersActions.initViewers(meeting.viewersUUID);
            get().meetingActions.setIsOnForceMuteAll(meeting.isOnForceMuteAll);
            get().meetingActions.setForceMuteList(meeting.forceMuteList);
            get().meetingActions.setUnForceMuteList(meeting.unForceMuteList);
            get().meetingActions.setMakeHostList(meeting.makeHostList);
            get().meetingActions.setChangeNicknameMap(meeting.changeNicknameMap);
            get().meetingActions.setForceExitList(meeting.forceExitList);
            get().userActions.setIsOnRecord(meeting.isOnRecord);
        },
        setMeetingResponse: (meetingResponse) => {
            set(
                (s) => {
                    s.meetingResponse = { ...meetingResponse };
                },
                false,
                'meetingActions.setMeetingResponse'
            );
        },
        setAttendeeResponse: (attendeeResponse) => {
            set(
                (s) => {
                    s.attendeeResponse = { ...attendeeResponse };
                },
                false,
                'meetingActions.setAttendeeResponse'
            );
        },
        setIsOnForceMuteAll: (value) => {
            set(
                (s) => {
                    s.isOnForceMuteAll = value;
                    s.forceMuteList = [];
                    s.unForceMuteList = [];
                },
                false,
                'meetingActions.setIsOnForceMuteAll'
            );
        },
        setForceMuteList: (forceMuteList) => {
            set(
                (s) => {
                    s.forceMuteList = forceMuteList;
                },
                false,
                'meetingActions.setForceMuteList'
            );
        },
        setUnForceMuteList: (unForceMuteList) => {
            set(
                (s) => {
                    s.unForceMuteList = unForceMuteList;
                },
                false,
                'meetingActions.setUnForceMuteList'
            );
        },
        setMakeHostList: (makeHostList) => {
            set(
                (s) => {
                    s.makeHostList = makeHostList;
                    s.isMakeHost = makeHostList.includes(get().uuid);
                },
                false,
                'meetingActions.setMakeHostList'
            );
        },
        setChangeNicknameMap: (changeNicknameMap) => {
            set(
                (s) => {
                    changeNicknameMap = Object.keys(changeNicknameMap).reduce((a, key) => {
                        a[key] = String(changeNicknameMap[key]);
                        return a;
                    }, {});
                    s.changeNicknameMap = changeNicknameMap;
                },
                false,
                'meetingActions.setChangeNicknameMap'
            );
        },
        setForceExitList: (forceExitList) => {
            set(
                (s) => {
                    s.forceExitList = forceExitList;
                },
                false,
                'meetingActions.setForceExitList'
            );
        },
        setCreateDt: (createDt) => {
            set(
                (s) => {
                    s.createDt = createDt;
                },
                false,
                'meetingActions.setCreateDt'
            );
        },
        setIsPlayingContentShare: (value) => {
            set(
                (s) => {
                    s.isPlayingContentShare = value;
                },
                false,
                'meetingActions.setIsPlayingContentShare'
            );
        },
    },

    // messages
    ELSE_STATE_START__________: '',
    messages: [],
    waitingMessages: {},
    messagesActions: {
        initMessages: (messages) => {
            set(
                (s) => {
                    s.messages = messages;
                },
                false,
                'messagesActions.initMessages'
            );
        },
        addMessage: (message) => {
            set(
                (s) => {
                    s.messages.push(message);
                },
                false,
                'messagesActions.addMessage'
            );
        },
        setWaitingMessage: (msg) => {
            set(
                (s) => {
                    s.waitingMessages[msg.uuid] = msg;
                },
                false,
                'messagesActions.setWaitingMessage'
            );
        },
        removeWaitingMessage: (uuid) => {
            set(
                (s) => {
                    delete s.waitingMessages[uuid];
                },
                false,
                'messagesActions.removePendingMessages'
            );
        },
    },

    // temporary data
    changeNicknameUUID: '',
    tempActions: {
        setChangeNicknameUUID(uuid) {
            set(
                (s) => {
                    s.changeNicknameUUID = uuid;
                },
                false,
                'tempActions.changeNicknameUUID'
            );
        },
    },
    limitCamCnt: 240,

    // common function
    computeIsOnForceMute: (uuid) => {
        let result = false;
        if (get().isOnForceMuteAll) {
            result = !(get().unForceMuteList.indexOf(uuid) > -1);
        }
        if (!get().isOnForceMuteAll) {
            result = get().forceMuteList.indexOf(uuid) > -1;
        }
        return result;
    },

    resetStore: () => {
        set((s) => {
            s.webinarStatus = 1;

            s.readyState = 'wait';
            s.hasMediaAccess = false;
            s.meetingStarted = false;
            s.isOnKinesis = true;

            s.fixUserList = [];
            s.unFixUserList = [];

            s.newMsgCnt = 0;

            s.isOnContentShare = false;
            s.isOnDrawerChat = false;
            s.isOnDrawerPeople = false;
            s.isOnModalSetting = false;
            s.isOnAlertExit = false;
            s.isOnAlertAlreadyAttend = false;
            s.isOnAlertRecord = false;
            s.isOnAlertWebinar = false;
            s.isOnAlertMeetingError = false;
            s.isOnAlertOnSpk = false;
            s.isOnRecord = false;
            s.isOnModalChangeNickname = false;
            s.isOnPopupViewMode = false;
            s.isOnPopupAttendee = false;
            s.isOnEmoji = false;
            s.isOnContentCover = true;
            s.isOnHeaderFooter = true;
            s.isMobile = false;
            s.isSupportBgBlur = false;
            s.isSupportBgFilter = false;
            s.processors = [];
            s.viewMode = 'tile';
            s.userListSwiperIdx = 0;
            s.spotlightListSwiperIdx = 0;


            s.users = {};

            s.sid = '';
            s.localVideoTile = null;
            s.meetingResponse = null;
            s.attendeeResponse = null;
            s.isPlayingContentShare = false;
            s.isOnForceMuteAll = false;
            s.forceMuteList = [];
            s.unForceMuteList = [];
            s.makeHostList = [];
            s.changeNicknameMap = {};
            s.forceExitList = [];
            s.createDt = null;

            s.messages = [];
            s.waitingMessages = {};

            s.changeNicknameUUID = '';
        }, 'storeReset');
    },
});

// 새로고침을 하더라도 유지되는 아이템들
const persistOpt = {
    name: 'zustand',
    storage: createJSONStorage(() => sessionStorage),
    merge: (p, c) => {
        c.useChimeWebinar = p.useChimeWebinar;
        c.webinar = p.webinar;
        c.webinarStatus = p.webinarStatus;
        c.meetingIdx = p.meetingIdx;
        c.uuid = p.uuid;
        c.userid = p.userid;
        c.nickname = p.nickname;
        c.org1 = p.org1;
        c.org2 = p.org2;
        c.email = p.email;
        c.infoTy = p.infoTy;
        c.urTy = p.urTy;
        c.isUr = p.isUr;
        c.bridgeYn = p.bridgeYn;

        c.usingCam = p.usingCam;
        c.usingSpk = p.usingSpk;
        c.usingMic = p.usingMic;
        c.isOnCam = p.isOnCam;
        c.isOnSpk = p.isOnSpk;
        c.isOnMic = p.isOnMic;
        c.cams = p.cams;
        c.spks = p.spks;
        c.mics = p.mics;

        return c;
    },
};
const useStore = create(devtools(persist(immer(store), persistOpt)));
const getItems = (items) => {
    return (s) => {
        let obj = {};
        items.forEach((x) => {
            obj[x] = s[x];
        });
        return obj;
    };
};
export { useStore, getItems, shallow };
