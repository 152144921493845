/* eslint-disable */
import React, { useEffect } from 'react';
import S from './Meeting.styled';
import Content from './Content';
import Header from './Header';
import Footer from './Footer';
import MeetingSetting from './MeetingSetting';
import { SocketProvider } from '../../Contexts/SocketContext';
import { MeetingSessionProvider } from '../../Contexts/MeetingSessionContext';
import DrawerPeople from './DrawerPeople';
import DrawerChat from './DrawerChat';
import PopupViewMode from './PopupViewMode';
import ModalSetting from './Modal/ModalSetting';
import ModalChangeNickname from './Modal/ModalChangeNickname';
import Waiting from './Waiting';
import dayjs from 'dayjs';
import { getItems, shallow, useStore } from '../../zustand/store';
import AlertExit from './Modal/AlertExit';
import AlertRecord from './Modal/AlertRecord';
import AlertAlreadyAttend from './Modal/AlertAlreadyAttend';
import AlertWebinar from './Modal/AlertWebinar';
import AlertOnSpk from './Modal/AlertOnSpk';
import PopupAttendee from './Modal/PopupAttendee';

const Meeting = ({meetingType}) => {
    const items = ['meetingIdx'];
    const z = useStore(getItems(items), shallow);
    useEffect(() => {
        const htmlTitle = document.querySelector('title');
        if(meetingType === 'webinar') {
            htmlTitle.innerHTML = `웨비나 진행 (W-${dayjs().format('YY')}-${String(z.meetingIdx).padStart(5, 0)})`;
        } else if (meetingType === 'brmeet') {
            htmlTitle.innerHTML = `BRmeet (M-${dayjs().format('YY')}-${String(z.meetingIdx).padStart(5, 0)})`;
        } else if (meetingType === 'brmeet-b') {
            htmlTitle.innerHTML = `BRmeet-B (W-${dayjs().format('YY')}-${String(z.meetingIdx).padStart(5, 0)})`;
        }
    }, []);

    return (
        <SocketProvider meetingType={meetingType}>
            <MeetingSessionProvider meetingType={meetingType}>
                <S.Container>
                    <Header meetingType={meetingType} />
                    <Footer meetingType={meetingType} />
                    <Content meetingType={meetingType} />
                    <Waiting meetingType={meetingType} />
                    <MeetingSetting meetingType={meetingType} />
                    <DrawerPeople meetingType={meetingType} />
                    <DrawerChat meetingType={meetingType} />
                    <PopupViewMode meetingType={meetingType} />
                    <AlertExit meetingType={meetingType} />
                    <AlertRecord meetingType={meetingType} />
                    <AlertWebinar meetingType={meetingType} />
                    <AlertAlreadyAttend meetingType={meetingType} />
                    <AlertOnSpk meetingType={meetingType} />
                    <ModalSetting meetingType={meetingType} />
                    <ModalChangeNickname meetingType={meetingType} />
                    <PopupAttendee meetingType={meetingType} />
                </S.Container>
            </MeetingSessionProvider>
        </SocketProvider>
    );
};

export default Meeting;
