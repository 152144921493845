import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getItems, shallow, useStore } from '../../zustand/store';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { useMeetingSession } from '../../Contexts/MeetingSessionContext';
dayjs.locale('ko');
const mainDomain = 'https://wbn.br-idge.kr';
const S = {
    Container: styled.div`
        position: fixed;
        z-index: 9999;
        width: 100%;
        height: 100%;
        background-color: var(--color-bg);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4rem;

        .loader {
            color: #ffffff;
            font-size: 35px;
            text-indent: -9999em;
            overflow: hidden;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            position: relative;
            transform: translateZ(0);
            animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
        }

        @keyframes mltShdSpin {
            0% {
                box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
            }
            5%,
            95% {
                box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
            }
            10%,
            59% {
                box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
                    -0.297em -0.775em 0 -0.477em;
            }
            20% {
                box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
                    -0.749em -0.34em 0 -0.477em;
            }
            38% {
                box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
            }
            100% {
                box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
            }
        }

        @keyframes round {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    `,

    AfterBeforeContainer: styled.div`
        @font-face {
            font-family: 'Inter';
            src: url('/font/Inter.ttf') format('TrueType');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: 'NanumSquareOTF';
            src: url('/font/NanumSquareOTF_acEB.otf') format('TrueType');
        }
        @keyframes fadeInOut {
            0% {
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
        img {
            max-width: 100%;
        }
        .none-scrollbar::-webkit-scrollbar {
            display: none;
        }
        .mini-scrollbar::-webkit-scrollbar {
            width: 3px; /*스크롤바의 너비*/
            border-radius: 3rem;
            padding: 0.5rem;
        }
        .mini-scrollbar::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.1); /*스크롤바 배경색상*/
        }
        .mini-scrollbar::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.3); /*스크롤바 트랙 색상*/
        }
        & {
            z-index: 9999;
            text-align: center;
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: black;
            background-image: url(${mainDomain}/img/exec-bg.jpg);
            background-size: cover;
            background-position: center 77%;
            background-repeat: no-repeat;
        }
        &.blind {
            display: none !important;
        }
        &:before {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.5;
        }
        & .title {
            display: none;
        }
        & .content {
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            overflow: auto;
            gap: 4rem;
            color: #ffffff;
            padding: 6rem 2rem 5rem 2rem;
            overflow-x: hidden;
        }
        & .content .row {
            display: flex;
            align-items: center;
            flex-direction: column;
            max-width:100%;
        }
        & .content .logo-wrap {
            width: 18rem;
            max-width: 50%;
            margin-bottom: 3.5rem;
        }
        & .content .logo-wrap svg {
            width: 100%;
        }
        & .content .loading {
            display: flex;
            gap: 0.7rem;
        }
        & .content .after-video-wrap {
            display: none;
            width: 1000px;
            max-width: calc(100% - 20px);
            aspect-ratio: 16 / 9;
            box-shadow: 0 0 20px 0px #00000063;
            background: black;
            position: relative;
        }
        & .content .after-video-wrap .after-video {
            width: auto;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        & .wbn-info {
            font-family: 'Inter';
        }
        & .content .title {
            font-size: 3.7rem;
            font-weight: 600;
            line-height: 3.5rem;
            margin-bottom: 1.6rem;
            font-family: 'Inter';
        }
        & .content .title span {
            font-size: 2.4rem;
            font-weight: 400;
        }
        & .content .loading .loading-item {
            width: 1rem;
            height: 1rem;
            background-color: #fff;
            border-radius: 50%;
            opacity: 0;
        }
        & .content .loading .loading-item:nth-child(1) {
            animation: fadeInOut 2s infinite;
        }
        & .content .loading .loading-item:nth-child(2) {
            animation: fadeInOut 2s 0.2s infinite;
        }
        & .content .loading .loading-item:nth-child(3) {
            animation: fadeInOut 2s 0.4s infinite;
        }
        & .content .wbn-title {
            font-size: 2.4rem;
            margin-bottom: 0.3rem;
            font-weight: 700;
        }
        & .content .wbn-date {
            font-size: 2rem;
            margin-bottom: 3rem;
            font-weight: 700;
        }
        & .content .wbn-desc {
            font-size: 1.6rem;
            color: #8a92b0;
            font-weight: 500;
            margin-bottom: 3rem;
            width: 53rem;
            word-break: keep-all;
            white-space: pre-line;
        }
        & .content .wbn-agenda-wrap {
            width: 100%;
            max-width: 38rem;
            max-height: 20rem;
            overflow-y: scroll;
            margin-bottom: 2rem;
        }
        & .content .wbn-agenda {
            font-size: 2rem;
            display: grid;
            gap: 1rem;
        }
        & .content .agenda-wrap {
            border: 0.5px solid #ffffff;
            border-radius: 10px;
            padding: 1rem;
            width: 100%;
            word-break: keep-all;
            font-size: 1.4rem;
            display: flex;
            text-align: left;
            font-weight: 600;
        }
        & .content .agenda-time {
            width: 10rem;
            min-width: 10rem;
        }
        & .content .agenda-info {
            margin-left: 1rem;
            display: grid;
            gap: 0.2rem;
        }
        & .content .agenda-user-text {
            font-weight: 500;
        }
        & .content .wbn-agenda p {
            line-height: 1.2;
            margin-bottom: 0;
        }
        & .content .btn-add {
            padding: 1.5rem 3rem;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 1rem;
            border: 2px solid #fff;
            -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
            color: #fff;
            font-size: 1.8rem;
            font-family: NanumSquareOTF;
            display: flex;
            align-items: end;
            width: 100%;
            max-width: 38rem;
        }
        & .content .btn-add img {
            margin-left: 1rem;
        }

        &.before .before-title {
            display: block;
        }
        &.after .after-title {
            display: block;
        }
        &.after .after-video-wrap {
            display: block;
        }
        &.end .end-title {
            display: block;
        }
        &.cancel .cancel-title {
            display: block;
        }
        &.after .loading {
            display: none;
        }

        & .post-survey-container {
            position: fixed;
            width: 100%;
            max-width: 700px;
            height: calc(100% - 4rem);
            max-height: 1000px;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            transition: transform 0.5s;
            z-index: 3;
            border-radius: 1rem;
            background-color: white;
        }
        & .post-survey-container iframe {
            width: 100%;
            height: 100%;
        }
        & .post-survey-container.on {
            transform: translate(-50%, -2rem);
        }

        /* 태블릿 가로 */
        @media (max-width: 1366px) and (orientation: landscape) {
            & .content {
                padding: 6rem 2rem 5rem 2rem;
                gap: 3rem;
            }
            & .content .logo-wrap {
                width: 14rem;
            }
            & .content .title {
                font-size: 3rem;
                line-height: 3rem;
            }
            & .content .title span {
                font-size: 2.2rem;
            }
            & .content .waiting-title {
                font-size: 3rem;
            }
            & .content .webinar-title {
                font-size: 2rem;
            }
            & .content .loading .loading-item {
                width: 0.75rem;
                height: 0.75rem;
            }
            & .content .webinar-desc {
                font-size: 1.6rem;
            }
            & .content .webinar-agenda {
                font-size: 1.6rem;
            }
        }
        /* 태블릿 세로 */
        @media (max-width: 1024px) and (orientation: portrait) {
            & .content {
                padding: 6rem 2rem 5rem 2rem;
                gap: 3rem;
            }
            & .content .logo-wrap {
                width: 14rem;
            }
            & .content .title {
                font-size: 3rem;
                line-height: 3rem;
            }
            & .content .title span {
                font-size: 2.2rem;
            }
            & .content .waiting-title {
                font-size: 3rem;
            }
            & .content .webinar-title {
                font-size: 2rem;
            }
            & .content .loading .loading-item {
                width: 0.75rem;
                height: 0.75rem;
            }
            & .content .webinar-desc {
                font-size: 1.6rem;
            }
            & .content .webinar-agenda {
                font-size: 1.6rem;
            }
        }
        /* 모바일 가로 */
        @media (max-width: 920px) and (max-height: 500px) and (orientation: landscape) {
            & .content {
                padding: 4rem 2rem 5rem 2rem;
                gap: 3rem;
            }
            & .content .logo-wrap {
                width: 12rem;
                margin-bottom: 2.5rem;
            }
            & .content .title {
                font-size: 2.5rem;
                line-height: 2.7rem;
            }
            & .content .title span {
                font-size: 1.9rem;
            }
            & .content .wbn-title {
                font-size: 2.2rem;
            }
            & .content .wbn-date {
                font-size: 1.8rem;
            }
            & .content .wbn-desc {
                font-size: 1.4rem;
            }
            & .content .wbn-agenda {
                font-size: 1.4rem;
            }
            & .content .btn-add {
                padding: 1rem 2rem;
                font-size: 1.5rem;
            }
            & .content .after-video-wrap {
                max-width: 80%;
            }
        }
        /* 모바일 세로 */
        @media (max-width: 500px) and (orientation: portrait) {
            & .content {
                padding: 4rem 2rem 5rem 2rem;
                gap: 3rem;
            }
            & .content .logo-wrap {
                width: 12rem;
                margin-bottom: 2.5rem;
            }
            & .content .title {
                font-size: 2.5rem;
                line-height: 2.7rem;
            }
            & .content .title span {
                font-size: 1.9rem;
            }
            & .content .wbn-title {
                font-size: 2.2rem;
            }
            & .content .wbn-date {
                font-size: 1.8rem;
            }
            & .content .wbn-desc {
                font-size: 1.4rem;
            }
            & .content .wbn-agenda {
                font-size: 1.4rem;
            }
            & .content .btn-add {
                padding: 1rem 2rem;
                font-size: 1.5rem;
            }
            & .content .after-video-wrap {
                max-width: 80%;
            }
        }
    `,
};
function Waiting(props) {
    const items = ['webinar', 'webinarStatus', 'infoTy', 'readyState', 'userActions', 'meetingIdx', 'uuid'];
    const z = useStore(getItems(items), shallow);
    const containerClass = z.webinarStatus === 1 ? 'before' : z.webinarStatus === 3 ? 'after' : z.webinarStatus === 4 ? 'end' : 'blind';
    const afterVideoRef = useRef(null);
    const meetingSession = useMeetingSession();
    const [isOnPostSurvey, setIsOnPostSurvey] = useState(false);
    const postSurveyUrl = `${process.env.REACT_APP_ADM_DOMAIN}/m/sur?w=${z.meetingIdx}&t=F&u=${z.uuid}`;
    console.log('#postSurveyUrl', postSurveyUrl)
    const goToBridgeTimout = useRef(null);
    useEffect(() => {
        if (z.infoTy === 3) {
            if (z.webinarStatus === 2) {
                if (goToBridgeTimout.current) {
                    if (goToBridgeTimout.current) {
                        goToBridgeTimout.current = clearTimeout(goToBridgeTimout.current);
                    }
                }
                z.userActions.setIsOnAlertOnSpk(true);
            }
            if (afterVideoRef && z.webinarStatus === 3) {
                meetingSession?.audioVideo.stop();

                // 사후설문 있으면 노출 아니면 사후비디오 재생
                if (z.webinar.POST_SURVEY_YN && !z.webinar.POST_SURVEY_SUBMIT_YN) {
                    setIsOnPostSurvey(true);
                } else {
                    afterVideoRef.current.play();
                }

                // 사후설문 제출 시 사후 비디오 재생
                window.addEventListener('message', ({ data }) => {
                    if (data === 'submit post survey') {
                        setIsOnPostSurvey(false);
                        afterVideoRef.current.play();
                    }
                });

                afterVideoRef.current.addEventListener('ended', function () {
                    if (goToBridgeTimout.current) {
                        goToBridgeTimout.current = clearTimeout(goToBridgeTimout.current);
                    }

                    goToBridgeTimout.current = setTimeout(() => {
                        window.location.replace('https://www.br-idge.co.kr');
                    }, 1000 * 60 * 3);
                });
            }
            if (z.webinarStatus === 4) {
                setIsOnPostSurvey(false);
                if (goToBridgeTimout.current) {
                    goToBridgeTimout.current = clearTimeout(goToBridgeTimout.current);
                }
                if (process.env.REACT_APP_ENV !== 'local') {
                    setTimeout(() => {
                        window.location.replace('https://www.br-idge.co.kr');
                    }, 5000);
                }
            }
        }
    }, [z.webinarStatus, z.infoTy]);

    return (
        <>
            {z.readyState === 'wait' && z.infoTy < 3 && (
                <S.Container>
                    <span className="loader"></span>
                    호스트가 회의를 시작하길 기다리고있습니다.
                </S.Container>
            )}
            {z.infoTy === 3 && (
                <S.AfterBeforeContainer className={`before-after-page ${containerClass}`}>
                    <div className="content none-scrollbar">
                        <div className="row">
                            <div className="logo-wrap">
                                <img src={`${mainDomain}/img/logo-new.png`} alt="" />
                            </div>
                            <div className="title before-title">
                                웨비나가 곧 시작됩니다.
                                <br />
                                <span>잠시만 기다려주세요.</span>
                            </div>
                            <div className="title after-title">
                                웨비나가 종료되었습니다.
                                <br />
                                <span>다음 웨비나를 기대해주세요.</span>
                            </div>
                            <div className="title end-title">
                                종료된 웨비나 입니다.
                                <br />
                                <span>5초 뒤 BRidge로 이동합니다.</span>
                            </div>
                            <div className="title cancel-title">
                                취소된 웨비나 입니다.
                                <br />
                                <span>5초 뒤 BRidge로 이동합니다.</span>
                            </div>
                            <div className="after-video-wrap">
                                <video
                                    ref={afterVideoRef}
                                    src={z.webinarStatus === 3 ? z.webinar.END_VID_SRC : ''}
                                    muted={z.webinarStatus === 3 ? false : true}
                                    playsInline
                                    className="after-video"
                                ></video>
                            </div>
                            <div className="loading">
                                <div className="loading-item"></div>
                                <div className="loading-item"></div>
                                <div className="loading-item"></div>
                            </div>
                        </div>
                        <div className="row none-scrollbar wbn-info">
                            <div className="wbn-title">{z.webinar.TITLE}</div>
                            <div className="wbn-date">{`[${dayjs(z.webinar.EXP_START_DT).format('M월 D일 (ddd) HH:mm')} - ${dayjs(z.webinar.EXP_END_DT).format(
                                'HH:mm'
                            )}]`}</div>
                            {z.webinar.INFO_DISP[0] === '1' && <div className="wbn-desc">{z.webinar.DESCR}</div>}
                            {z.webinar.AGENDA && (
                                <div className="wbn-agenda-wrap mini-scrollbar">
                                    <div className="wbn-agenda">
                                        {JSON.parse(z.webinar.AGENDA).map((x, i) => (
                                            <div className="agenda-wrap" key={i}>
                                                <div className="agenda-time">{x.tm}</div>
                                                <div className="agenda-info">
                                                    <div className="agenda-name">{x.tt}</div>
                                                    <div className="agenda-user-text">{x.ct}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div
                                className="btn btn-add"
                                onClick={() => {
                                    window.open('https://www.br-idge.co.kr');
                                }}
                            >
                                의료정책정보 맛집 BRidge로 바로가기
                                <img src={`${mainDomain}/img/arrow.png`} />
                            </div>
                        </div>
                    </div>
                    
                    <div className={`post-survey-container none-scrollbar ${isOnPostSurvey ? 'on' : 'off'}`}>
                    {z.webinar.POST_SURVEY_YN && !z.webinar.POST_SURVEY_SUBMIT_YN && <iframe src={postSurveyUrl} frameBorder="0"></iframe>}
                    </div>

                </S.AfterBeforeContainer>
            )}
        </>
    );
}

export default Waiting;
