import React from 'react';
import {getItems, shallow, useStore} from "../../zustand/store";
import IconBtn from "../Components/IconBtn";

import styled from 'styled-components';

const S = {
    NewMsgCnt: styled.div`
      position: absolute;
      transform: translate(25%, -25%);
      top: 0;
      right: 0;
      white-space: nowrap;
      cursor: pointer;
      background-color: var(--color-success);
      padding: .2rem .5rem;
      border-radius: 1rem;
    `
}

function BtnChat({label}) {
    const items = ['isOnDrawerChat', 'userActions', 'newMsgCnt']
    const z = useStore(getItems(items), shallow)

    const toggleDrawerChat = async () => {
        if (z.isOnDrawerChat) {
            z.userActions.setIsOnDrawerChat(false)
        } else {
            z.userActions.setNewMsgCnt(0)
            z.userActions.setIsOnDrawerChat(true)
        }
    }
    return (

        <div className="btn-wrap" style={{position: 'relative'}}>

            <IconBtn icon='chat'
                     theme="secondary"
                     isOn={z.isOnDrawerChat}
                     onType={2}
                     onTheme="white"
                     onClick={toggleDrawerChat}
                     label={label}
            />

            {z.newMsgCnt > 0 &&
                <S.NewMsgCnt onClick={toggleDrawerChat}>
                    <div className="circle">
                        <span>{z.newMsgCnt > 10 && '+'}{Math.min(z.newMsgCnt, 10)}</span>
                    </div>
                </S.NewMsgCnt>
            }
        </div>

    );
}

export default BtnChat;