import {createContext, useContext, useMemo, useState} from 'react';

const MeetingSessionContext = createContext();
const MeetingSessionActionsContext = createContext();

function MeetingSessionProvider({ children }) {
  const [meetingSession, setMeetingSession] = useState(null);
  const actions = useMemo(
    () => ({
      init(meetingSession) {
        setMeetingSession(meetingSession);
      },
    }),
    []
  );
  return (
    <MeetingSessionActionsContext.Provider value={actions}>
      <MeetingSessionContext.Provider value={meetingSession}>
        {children}
      </MeetingSessionContext.Provider>
    </MeetingSessionActionsContext.Provider>
  );
}

function useMeetingSession() {
  const value = useContext(MeetingSessionContext);
  if (value === undefined) {
    throw new Error('useMeetingSession should be used within MeetingSessionProvider');
  }
  return value;
}

function useMeetingSessionActions() {
  const value = useContext(MeetingSessionActionsContext);
  if (value === undefined) {
    throw new Error('useMeetingSessionActions should be used within MeetingSessionProvider');
  }
  return value;
}

export {MeetingSessionProvider, useMeetingSession, useMeetingSessionActions}