import io from 'socket.io-client';
import config from '../config/config';
class Socket {
    constructor(socketUrl, userActions, usersActions, meetingActions) {
        this.userActions = userActions;
        this.usersActions = usersActions;
        this.meetingActions = meetingActions;
        this.socket = io.connect(socketUrl, { transports: ['websocket'] });
    }

    request = { 
        onFix: ({ uuid }) => {
            this.socket.emit('onFix', { uuid });
        },
        offFix: ({ uuid }) => {
            this.socket.emit('offFix', { uuid });
        },
        onCam: ({ uuid }) => {
            this.socket.emit('onCam', { uuid });
        },
        offCam: ({ uuid }) => {
            this.socket.emit('offCam', { uuid });
        },
        onSpk: ({ uuid }) => {
            this.socket.emit('onSpk', { uuid });
        },
        offSpk: ({ uuid }) => {
            this.socket.emit('offSpk', { uuid });
        },
        onContentShare: ({ uuid }) => {
            this.socket.emit('onContentShare', { uuid });
        },
        offContentShare: ({ uuid }) => {
            this.socket.emit('offContentShare', { uuid });
        },
        forceOffContentShare: ({ uuid }) => {
            this.socket.emit('forceOffContentShare', { uuid });
        },
        onForceMuteAll: () => {
            this.socket.emit('onForceMuteAll', {});
        },
        offForceMuteAll: () => {
            this.socket.emit('offForceMuteAll', {});
        },
        onMute: ({ uuid }) => {
            this.socket.emit('onMute', { uuid });
        },
        onForceMute: ({ uuid }) => {
            this.socket.emit('onForceMute', { uuid });
        },
        offForceMute: ({ uuid }) => {
            this.socket.emit('offForceMute', { uuid });
        },
        onMakeHost: ({ uuid }) => {
            this.socket.emit('onMakeHost', { uuid });
        },
        offMakeHost: ({ uuid }) => {
            this.socket.emit('offMakeHost', { uuid });
        },
        onForceExit: ({ uuid }) => {
            this.socket.emit('onForceExit', { uuid });
        },
        offForceExit: ({ uuid }) => {
            this.socket.emit('offForceExit', { uuid });
        },
        onMuteAll: () => {
            this.socket.emit('onMuteAll', {});
        },
        changeNickname: ({ uuid, nickname }) => {
            this.socket.emit('changeNickname', { uuid, nickname });
        },
        fileUploaded: ({ uuid, link }) => {
            this.socket.emit('fileUploaded', { uuid, link });
        },
        onRecord: () => {
            this.socket.emit('onRecord', { meetingId: this.meetingSession.configuration.meetingId });
        },
        onWebinar: (meetingIdx) => {
            this.socket.emit('onWebinar', { meetingIdx });
        },
        offWebinar: (meetingIdx) => {
            this.socket.emit('offWebinar', { meetingIdx });
        },
        closeMeeting: () => {
            this.socket.emit('closeMeeting', {});
        },
    };

    disconnect = () => {
        this.socket.disconnect();
    };

    setMeetingSession = (meetingSession) => {
        this.meetingSession = meetingSession;
    };
}

export default Socket;
