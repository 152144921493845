import React, { useEffect, useRef, useState } from 'react';
import { useSocket } from '../../Contexts/SocketContext';
import { getItems, shallow, useStore } from '../../zustand/store';
import { useMeetingSession } from '../../Contexts/MeetingSessionContext';
import KinesisMaster from '../../util/KinesisMaster';
import IconBtn from '../Components/IconBtn';

function BtnContentShare({ label }) {
    const items = ['meetingIdx', 'isPlayingContentShare', 'isOnContentShare', 'uuid', 'isMakeHost', 'infoTy', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const meetingSession = useMeetingSession();
    const socket = useSocket();
    const isProcessingRef = useRef(false);

    const toggleContentShare = async () => {
        if (!z.isOnContentShare) {
            // 호스트가 다른 참석자의 화면공유를 강제 종료
            if (z.isPlayingContentShare && (z.isMakeHost || z.infoTy === 1)) {
                let vgcEl = document.querySelector('.vg-content');
                if (vgcEl) {
                    let contentShareUUID = document.querySelector('.vg-content').classList[4].split('#')[0].split('vg-')[1];
                    socket.request.forceOffContentShare({ uuid: contentShareUUID });
                }
                return;
            }

            if (z.isPlayingContentShare) return alert('이미 다른 토론자가 화면을 공유하고있습니다.');
            // 화면공유 On
            try {
                if (isProcessingRef.current) return;

                isProcessingRef.current = true;
                let stream;
                try {
                    // stream = await meetingSession.audioVideo.startContentShareFromScreenCapture();
                    stream = await navigator.mediaDevices.getDisplayMedia({
                        audio: {
                            autoGainControl: false,
                            echoCancellation: false,
                            googAutoGainControl: false,
                            noiseSuppression: false,
                        },
                    });

                    z.userActions.setIsOnContentCover(true);
                    meetingSession.audioVideo.startContentShare(stream);
                } catch (e) {
                    console.log(e);
                } finally {
                    isProcessingRef.current = false;
                }
                if (stream) {
                    if (document.querySelector('.content-h') || document.querySelector('.content-w')) {
                        await meetingSession.audioVideo.stopContentShare();
                        return alert('이미 다른 토론자가 화면을 공유하고있습니다.');
                    }

                    socket.request.onContentShare({ uuid: z.uuid });
                    z.userActions.setIsOnContentShare(true);
                }
            } catch (e) {
                console.error({ name: e.name, message: e.message });
            }
        } else {
            // 화면공유 Off
            try {
                await meetingSession.audioVideo.stopContentShare();
                socket.request.offContentShare({ uuid: z.uuid });
                z.userActions.setIsOnContentShare(false);
            } catch (e) {
                console.error('에러 발생', e);
            }
        }
    };

    return (
        <IconBtn
            icon="contentShare"
            theme="secondary"
            // 호스트일 경우 다른사람이 시작한 화면공유도 중지 가능하도록 설정
            isOn={z.isOnContentShare || (z.isPlayingContentShare && (z.infoTy === 1 || z.isMakeHost))}
            onType={2}
            onTheme="success"
            onClick={toggleContentShare}
            label={label}
        />
    );
}

export default BtnContentShare;
