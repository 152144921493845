const config = {
	cookieMaxAge: 30 * (60 * 60 * 24), // 30일
	bp: {
		tw: {maxWidth: 1366, orientation: 'landscape'},
		th: {maxWidth: 1024, orientation: 'portrait'},
		mw: {maxWidth: 920, maxHeight: 500},
		mh: {maxWidth: 500, orientation: 'portrait'}
	}
}

export default config;