import React from 'react';
import styled, { keyframes } from 'styled-components';
import Btn from './Btn';

const A = {
    FadeInUp: keyframes`
      0% {
        opacity: 0;
        transform: translate(-50%, calc(-50% - 20px));
      }
      100% {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    `,
};

const S = {
    Container: styled.div`
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;

        .modal-bg {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
        }

        .modal {
            border-radius: 23px;
            overflow: hidden;
            position: fixed;
            width: 500px;
            top: 50%;
            left: 50%;
            max-width: calc(100vw - 20px);
            background: #343434;

            &.sm {
                max-width: 334px;
            }

            &.md {
                max-width: 375px;
            }

            .modal-header {
                display: flex;
                justify-content: end;
                height: 30px;
                margin-bottom: 1rem;
                .btn-modal-close {
                    display: flex;
                    width: 25px;
                    height: 25px;
                    -webkit-box-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    align-items: center;
                    background-image: url(/icon/close.svg);
                    background-size: 12px;
                    background-position: left bottom;
                    background-repeat: no-repeat;
                    cursor: pointer;
                }
            }

            .modal-body {
                min-height: 75px;
                padding: 0 2rem 3rem;
            }

            .modal-footer {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 0 2rem 2rem;
            }

            .s-btn {
                border-radius: 8px;
                padding: 1rem 1.5rem;
                &.transparent {
                    background-color: transparent;

                    &:hover {
                        background-color: #424242;
                    }

                    &:active {
                        background-color: transparent;
                    }
                }

                &.secondary {
                    background-color: #484848;

                    &:hover {
                        background-color: #5a5959;
                    }

                    &:active {
                        background-color: #484848;
                    }
                }
            }
        }

        &.open {
            .modal {
                animation: ${A.FadeInUp} 0.3s forwards ease-out;
            }
        }

        &.close {
            display: none;
        }
    `,
};

function Modal({
    isOpen,
    onConfirm = () => {
        console.log('Click on ConfirmBtn');
    },
    onCancel = () => {
        console.log('Click on CancelBtn');
    },
    children,
    confirmText = '확인',
    cancelText = '닫기',
    confrimBtnTheme = 'success',
    cancelBtnTheme = 'transparent',
    showConfirmBtn = true,
    showCancelBtn = true,
    showCloseBtn = true,
    showSecondBtn = false,
    secondBtnText = 'Second Button',
    secondBtnTheme = 'secondary',
    onSecondBtnClick = () => {
        console.log('Click on SecondBtn');
    },
    size,
}) {
    return (
        <S.Container className={`s-modal-container ${isOpen ? 'open' : 'close'}`}>
            <div className={`modal-bg`}></div>
            <div className={`modal ${size}`}>
                <div className="modal-header">
                    {showCloseBtn && <div className="btn-modal-close" onClick={onCancel}></div>}
                </div>
                <div className="modal-body">{children}</div>
                <div className="modal-footer">
                    {showConfirmBtn && (
                        <Btn theme={confrimBtnTheme} onClick={onConfirm}>
                            {confirmText}
                        </Btn>
                    )}
                    {showSecondBtn && (
                        <Btn theme={secondBtnTheme} onClick={onSecondBtnClick}>
                            {secondBtnText}
                        </Btn>
                    )}
                    {showCancelBtn && (
                        <Btn theme={cancelBtnTheme} onClick={onCancel}>
                            {cancelText}
                        </Btn>
                    )}
                </div>
            </div>
        </S.Container>
    );
}

export default Modal;
