import React from 'react';
import { getItems, shallow, useStore } from '../../zustand/store';
import IconBtn from '../Components/IconBtn';

function BtnSetting({ label }) {
    const items = ['isOnModalSetting', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const toggleOn = async () => {
        if (z.isOnModalSetting) {
            document.querySelector('.preview-video-wrap video').pause();
        } else {
            document.querySelector('.preview-video-wrap video').play();
        }

        z.userActions.setIsOnModalSetting(!z.isOnModalSetting);
    };
    return (
        <div className="btn-wrap" style={{ position: 'relative' }}>
            <IconBtn icon="setting" theme="secondary" isOn={z.isOnModalSetting} onType={2} onTheme="white" onClick={toggleOn} label={label} />
        </div>
    );
}

export default BtnSetting;
