import React, {useEffect, useState} from 'react';
import {getItems, shallow, useStore} from "../../zustand/store";
import CustomSelect from "../Components/CustomSelect";

function SelectCam({theme, isPreview}) {
    const items = ['usingCam', 'usingPreviewCam', 'cams', 'hasMediaAccess', 'userActions'];
    const z = useStore(getItems(items), shallow)
    const [options, setOptions] = useState([]);

    const onClickOption = (e) => {
        if (!isPreview) {
            z.userActions.setUsingCam(e.dataset.value)
        } else {
            z.userActions.setUsingPreviewCam(e.dataset.value);
        }
    }

    useEffect(() => {
        let _options = JSON.parse(JSON.stringify(z.cams))
        if (z.hasMediaAccess === null) _options = [{key: -1, label: '장치를 불러오는 중입니다'}]
        else if (z.hasMediaAccess === false) _options = [{key: -2, label: '카메라에 대한 접근권한을 허용해주세요'}]
        else if (z.cams.length === 0) _options = [{ key: -3, label: '선택할 수 있는 장치가 없습니다' }];
        else
            _options = _options.reduce((a, c) => {
                let label = c.label + '';
                label = label.lastIndexOf(' (') > -1 ? label.substring(0, label.lastIndexOf(' (')) : label;
                if (isPreview) c.isPick = c.deviceId === z.usingPreviewCam;
                else c.isPick = c.deviceId === z.usingCam;
                a.push({
                    key: c.deviceId,
                    value: c.deviceId,
                    label: label,
                    isPick: c.isPick,
                });
                return a;
            }, []);

        if (_options.filter(x => x.isPick).length && _options.length !== 0) _options[0].isPick = true

        setOptions(_options);
    }, [z.hasMediaAccess, z.cams, z.usingCam, z.usingPreviewCam])

    return <CustomSelect onClickOption={onClickOption} options={options} theme={theme}></CustomSelect>
}

export default SelectCam;