import React, { useEffect } from 'react';
import { getItems, shallow, useStore } from '../../../zustand/store';
import Modal from '../../Components/Modal';
import tool from '../../../util/tools';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../../Contexts/SocketContext';
export default function AlertAlreadyAttend({ meetingType }) {
    const items = [
        'sid',
        'uuid',
        'userid',
        'meetingIdx',
        'nickname',
        'org1',
        'org2',
        'infoTy',
        'isOnSpk',
        'isOnCam',
        'isOnMic',
        'isMobile',
        'isOnAlertAlreadyAttend',
        'userActions',
    ];
    const z = useStore(getItems(items), shallow);
    const socket = useSocket();
    const navigate = useNavigate();

    const onConfirm = () => {
        socket.socket.emit('disconnectAndConnect', { uuid: z.uuid });
        const user = {
            uuid: z.uuid,
            userid: z.userid,
            meetingIdx: z.meetingIdx,
            nickname: z.nickname,
            org1: z.org1,
            org2: z.org2,
            infoTy: z.infoTy,
            isOnSpk: z.isOnSpk,
            isOnCam: z.isOnCam,
            isOnMic: z.isOnMic,
            isMobile: tool.getIsMobile(),
        };
        setTimeout(() => {
            socket.socket.emit('joinWaitingRoom', { meetingIdx: z.meetingIdx, user });
        }, 2000);

        z.userActions.setIsOnAlertAlreadyAttend(false);
    };
    const onCancel = () => {
        navigate(`/${meetingType === 'webinar' ? '' : meetingType + '/'}login/${z.meetingIdx}/${z.uuid}`, { replace: true });
        z.userActions.setIsOnAlertAlreadyAttend(false);
    };
    return (
        <Modal
            isOpen={z.isOnAlertAlreadyAttend}
            confirmText="예"
            cancelText="아니오"
            confrimBtnTheme="danger"
            onConfirm={onConfirm}
            onCancel={onCancel}
            showCloseBtn={true}
            size="sm"
        >
            <h3 style={{ textAlign: 'center', marginBottom: '2rem' }}>이미 회의에 참석중입니다</h3>
            <p style={{ textAlign: 'center', color: '#B6B6B6' }}>기존 회의의 접속 해제하고 입장하시겠습니까?</p>
        </Modal>
    );
}
