import React from 'react';
import IconBtn from '../Components/IconBtn';
import { getItems, shallow, useStore } from '../../zustand/store';
import { toast, Flip } from 'react-toastify';


function BtnCam({ label, isLogin }) {
    const items = ['isOnCam', 'usingCam', 'cams', 'userActions', 'limitCamCnt', 'users'];
    const z = useStore(getItems(items), shallow);

    const toggleCam = () => {
        // 캠 활성화 시 최대 24개까지만 활성화 가능
        if(!z.isOnCam) {
            let onCamCnt = Object.keys(z.users).filter((id) => {
                if(id.includes('content')) return false;
                let x = z.users[id];
                return x.isOnCam;
            }).length;

            toast.dismiss();
            if(!isLogin && z.cams.length > 0) {
                if (onCamCnt >= z.limitCamCnt) {
                    document.querySelector('.btn-send-blocked-camera')?.click();
                    return toast.warn(`카메라 최대 가능수 (${z.limitCamCnt}명)를 넘어 카메라를 켜지 못했습니다.`);
                } else {
                    // toast.warn(`동시 활성 가능한 카메라 최대 수 :  ${z.limitCamCnt}대 (${onCamCnt+1}/${z.limitCamCnt})`);
                }
            }
        }
        z.userActions.setIsOnCam(!z.isOnCam);
    };
    return (
        <>
            <IconBtn icon="cam" theme="secondary" isOn={z.isOnCam} onClick={toggleCam} label={label} />
        </>
    );
}

export default BtnCam;
