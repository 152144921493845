import React from 'react';
import IconBtn from '../Components/IconBtn';
import {getItems, shallow, useStore} from "../../zustand/store";


function BtnSpk({label}) {
    const items = ['isOnSpk', 'userActions']
    const z = useStore(getItems(items), shallow)

    const toggleSpk = () => {
        z.userActions.setIsOnSpk(!z.isOnSpk)
    }
    return (
        <IconBtn icon='spk' theme='secondary' isOn={z.isOnSpk} onClick={toggleSpk} label={label}/>
    );
}

export default BtnSpk;