import React, { useEffect } from 'react';
import { getItems, shallow, useStore } from '../../../zustand/store';
import Modal from '../../Components/Modal';
import { useSocket } from '../../../Contexts/SocketContext';
import axios from 'axios';
import dayjs from 'dayjs';
export default function AlertWebinar() {
    const items = ['sid', 'meetingIdx', 'meetingId', 'webinarStatus', 'isOnAlertWebinar', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const { socket: sk } = useSocket();

    const onConfirm = () => {
        console.log(typeof z.webinarStatus, z.webinarStatus);
        if (z.webinarStatus === 1) {
            axios
                .post(`${process.env.REACT_APP_MAIN_DOMAIN}/_adm/webinar/start`, {
                    wbnIdx: z.meetingIdx,
                    date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    isChimeWebinar: true,
                })
                .then((x) => {
                    sk.emit('start chime webinar', {});
                })
                .catch((x) => {
                    console.error(x);
                });
            console.log('test');
        } else if (z.webinarStatus === 2) {
            axios
                .post(`${process.env.REACT_APP_MAIN_DOMAIN}/_adm/webinar/end`, {
                    wbnIdx: z.meetingIdx,
                    date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    isChimeWebinar: true,
                })
                .then((x) => {
                    sk.emit('end chime webinar', {});
                });
            console.log('test');
        }
        z.userActions.setIsOnAlertWebinar(false);
    };
    const onCancel = () => {
        z.userActions.setIsOnAlertWebinar(false);
    };
    return (
        <Modal
            isOpen={z.isOnAlertWebinar}
            confirmText={z.webinarStatus === 2 ? '종료' : '시작'}
            cancelText="취소"
            confrimBtnTheme="danger"
            onConfirm={onConfirm}
            onCancel={onCancel}
            showCloseBtn={true}
            size="sm"
        >
            <h3 style={{ textAlign: 'center', marginBottom: '2rem' }}>웨비나를 {z.webinarStatus === 2 ? '종료' : '시작'}하시겠습니까?</h3>
            <p style={{ textAlign: 'center', color: '#B6B6B6', whiteSpace: 'pre-line' }}>
                {z.webinarStatus === 2 ? '웨비나가 종료되면 시청자들은 퇴장되며\n다시 시작할 수 없습니다.' : '회의가 종료되면 웨비나도 자동 종료처리됩니다.'}
            </p>
        </Modal>
    );
}
