/* eslint-disable */
import React, { Fragment } from 'react';
import IconBtn from '../Components/IconBtn';
import { getItems, shallow, useStore } from '../../zustand/store';
import styled from 'styled-components';
import BtnCam from '../ComponentsF/BtnCam';
import BtnSpk from '../ComponentsF/BtnSpk';
import BtnMic from '../ComponentsF/BtnMic';
import BtnViewMode from '../ComponentsF/BtnViewMode';
import BtnContentShare from '../ComponentsF/BtnContentShare';
import BtnPeople from '../ComponentsF/BtnPeople';
import BtnChat from '../ComponentsF/BtnChat';
import BtnSetting from '../ComponentsF/BtnSetting';
import BtnRecord from '../ComponentsF/BtnRecord';
import 'swiper/css';
import 'swiper/css/navigation';
import Transition from 'react-transition-group/Transition';
import c from '../../config/config';
import BtnExit from '../ComponentsF/BtnExit';
import BtnWebinar from '../ComponentsF/BtnWebinar';
import Activator from './Activator';

const S = {
    Footer: styled.div`
        z-index: 3;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 2rem;
        padding: 1rem 2rem 2.5rem;
        height: var(--footer-height);
        /* background-color: rgb(23 23 23 / 78%); */
        background-color: #1A1919;

        position: absolute;
        bottom: 0;
        left: 0;

        transition: transform 0.5s;

        &.entering, &.entered {
            transform: translateY(0);
        }

        &.exiting, &.exited {
            transform: translateY(100%);
        }


        &.entering + .content .spotlight-list-navigator, &.entered + .content .spotlight-list-navigator {
            transform: translateY(-75px);
        }
        &.exiting + .content .spotlight-list-navigator, &.exited + .content .spotlight-list-navigator {
            transform: translateY(0);
        }

        & > div {
            display: flex;
            gap: 2rem;
        }

        .center {
            .btn-webinar-wrap {
                display: none;
            }
            .btn-record-wrap {
                display: none;
            }

            .btn-setting-wrap {
                display: none;
            }

            .btn-exit-wrap {
                display: none;
            }

            .btn-content-share-wrap {
                margin: 0 1rem;
            }

            &.viewer {
                .btn-chat-wrap {
                    display: flex;
                }
            }
        }

        .right {
            position: absolute;
            right: 2rem;
            bottom: 2rem;
        }

        .btn-wrap {
            width: 50px;
            height: 50px;
        }

        // 터치 스크린 ( 화면공유, 녹화, 스피커 제거 )
        @media all and (pointer: coarse) {
            .center {
                gap: 1.5rem;

                .btn-content-share-wrap {
                    display: none;
                }

                .btn-spk-wrap {
                    display: none;
                }

                .btn-webinar-wrap {
                    display: none;
                }

                .btn-record-wrap {
                    display: none;
                }
            }
        }

        // 태블릿 가로
        @media screen and (max-width: ${c.bp.tw.maxWidth}px) and (orientation: ${c.bp.tw.orientation}) {
            .btn-wrap {
                width: 45px;
                height: 45px;
            }

            &.viewer {
                .center {
                    .btn-chat-wrap {
                        display: flex;
                    }
                    .btn-exit-wrap {
                        display: none;
                    }
                }
            }
            .right {
                display: flex;
            }
        }
        @media screen and (max-width: 1090px) {
            .center {
                .btn-content-share-wrap {
                    margin: 0;
                }

                .btn-webinar-wrap {
                    display: block;
                }

                .btn-record-wrap {
                    display: block;
                }

                .btn-setting-wrap {
                    display: block;
                }

                .btn-exit-wrap {
                    display: block;
                }
            }

            .right {
                display: none;
            }

            &.viewer {
                .right {
                    display: flex;
                }
            }
        }

        // 태블릿 세로
        @media all and (max-width: ${c.bp.th.maxWidth}px) and (orientation: ${c.bp.th.orientation}) {
            .btn-wrap {
                width: 45px;
                height: 45px;
            }
            &.viewer {
                .center {
                    .btn-chat-wrap {
                        display: flex;
                    }
                    .btn-exit-wrap {
                        display: none;
                    }
                }
                .right {
                    display: flex;
                }
            }
        }

        // 모바일 가로
        @media screen and (max-width: ${c.bp.mw.maxWidth}px) and (max-height: ${c.bp.mw.maxHeight}px) {
            padding: .9rem;
            //background-color: rgba(49, 48, 49, 0.56);
            .btn-wrap {
                width: 45px;
                height: 45px;
            }

            .center {
                .btn-setting-wrap,
                .btn-exit-wrap {
                    display: grid;
                }
            }

            .right {
                display: none;
            }

            &.viewer {
                .center {
                    .btn-chat-wrap {
                        display: flex;
                    }
                    .btn-exit-wrap {
                        display: none;
                    }
                }
                .right {
                    display: flex;
                }
            }
        }

        // 모바일 세로
        @media all and (max-width: ${c.bp.mh.maxWidth}px) and (orientation: portrait) {
            padding: .9rem;
            .btn-wrap {
                width: 45px;
                height: 45px;
            }

            .center {
                .btn-setting-wrap,
                .btn-exit-wrap {
                    display: none;
                }
            }

            .right {
                display: none;
            }
        }
    `,
    ModalBody: styled.div`
        .local-video-wrap {
            width: 100%;
            aspect-ratio: 16/9;
            position: relative;
            overflow: hidden;
            //isolation: isolate;
            border-radius: 0.5rem;
            margin-bottom: 1rem;

            video {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scaleX(-1);
            }
        }

        .sel-group {
            margin-bottom: 1rem;

            .sel-group-title {
                margin-bottom: 0.5rem;
            }

            select {
                width: 100%;
                padding: 0.3rem 0.2rem;
                border-radius: 0.5rem;
            }
        }
    `,
};

function Footer({meetingType}) {
    const items = ['isOnHeaderFooter', 'useChimeWebinar', 'infoTy'];
    const z = useStore(getItems(items), shallow);

    return (
        <Transition in={z.isOnHeaderFooter} timeout={500}>
            {(status) => (
                <S.Footer className={`footer ${status} ${z.infoTy === 3 ? 'viewer' : ''}`}>
                    <div className="center">
                        {z.infoTy < 3 && (
                            <Fragment>
                                <div className="btn-wrap btn-cam-wrap">
                                    <BtnCam label="카메라" />
                                </div>
                                <div className="btn-wrap btn-spk-wrap">
                                    <BtnSpk label="스피커" />
                                </div>
                                <div className="btn-wrap btn-mic-wrap">
                                    <BtnMic label="마이크" />
                                </div>
                                <div className="btn-wrap btn-content-share-wrap">
                                    <BtnContentShare label="화면공유" />
                                </div>
                                <div className="btn-wrap btn-view-mode-wrap">
                                    <BtnViewMode label="화면모드" />
                                </div>
                                <div className="btn-wrap btn-people-wrap">
                                    <BtnPeople label="참가자" />
                                </div>
                                <div className="btn-wrap btn-chat-wrap">
                                    <BtnChat label="채팅" />
                                </div>
                            </Fragment>
                        )}
                        {z.infoTy === 3 && (
                            <Fragment>
                                <div className="btn-wrap btn-spk-wrap">
                                    <BtnSpk label="스피커" />
                                </div>
                                <div className="btn-wrap btn-chat-wrap">
                                    <BtnChat label="채팅" />
                                </div>
                            </Fragment>
                        )}
                        {z.infoTy === 1 && meetingType === 'webinar' && z.useChimeWebinar && (
                            <div className="btn-wrap btn-webinar-wrap">
                                <BtnWebinar label="웨비나" />
                            </div>
                        )}
                        {z.infoTy === 1 && ['webinar', 'brmeet', 'brmeet-b'].includes(meetingType) && (
                            <div className="btn-wrap btn-record-wrap">
                                <BtnRecord label="녹화" />
                            </div>
                        )}
                        {z.infoTy < 3 && (
                            <Fragment>
                                <div className="btn-wrap btn-setting-wrap">
                                    <BtnSetting label="설정" />
                                </div>
                                <div className="btn-wrap btn-exit-wrap">
                                    <BtnExit label="나가기" />
                                </div>
                            </Fragment>
                        )}

                        
                    </div>
                    <div className="right">
                        {z.infoTy === 1 && meetingType === 'webinar' && z.useChimeWebinar && (
                            <div className="btn-wrap btn-webinar-wrap">
                                <BtnWebinar label="웨비나" />
                            </div>
                        )}
                        {z.infoTy === 1 && ['webinar', 'brmeet', 'brmeet-b'].includes(meetingType) && (
                            <Fragment>
                                <div className="btn-wrap btn-record-wrap">
                                    <BtnRecord label="녹화" />
                                </div>
                            </Fragment>
                        )}
                        {z.infoTy < 3 && (
                            <Fragment>
                                <div className="btn-wrap btn-setting-wrap">
                                    <BtnSetting label="설정" />
                                </div>
                                <div className="btn-wrap btn-exit-wrap">
                                    <BtnExit label="나가기" />
                                </div>
                            </Fragment>
                        )}
                    </div>
                </S.Footer>
            )}
        </Transition>
    );
}

export default Footer;
