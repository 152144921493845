/* eslint-disable */
import React, { useEffect, useRef, useState, memo } from 'react';
import Spinner from '../Components/Spinner';
import MicSensor from '../Components/MicSensor';
import { getItems, shallow, useStore } from '../../zustand/store';
import BtnMore from '../ComponentsF/BtnMore';
import styled from 'styled-components';
import { useMeetingSession } from '../../Contexts/MeetingSessionContext';
import BtnFullscreen from '../ComponentsF/BtnFullscreen';
import c from '../../config/config';
import { useSocket } from '../../Contexts/SocketContext';

const S = {
    VideoGroup: styled.div`
        position: relative;

        &.my-vg video {
            transform: rotateY(0) !important;
        }
        &.fix:after {
            content: '';
            width: 3rem;
            height: 3rem;
            background-image: url(/icon/pin-2.svg);
            position: absolute;
            top: 1rem;
            left: 1rem;
        }

        .video-wrap {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
            border-radius: 1.5rem;
            background-color: var(--color-card-bg);

            .spinner {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            video {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
                object-position: center;
                background-color: #1e1f22;
                background-position: center;
                background-size: 100px;
                background-repeat: no-repeat;
            }

            .avatar {
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: #57585a;
                transform: translate(-50%, -50%);
                padding: 10%;
                border-radius: 30%;

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &.play.e-play {
                video {
                    display: block;
                }

                .avatar {
                    display: none;
                }

                .spinner {
                    display: none;
                }
            }

            &.play {
                video {
                    display: none;
                }

                .avatar {
                    display: none;
                }

                .spinner {
                    display: block;
                }
            }

            &.e-play {
                video {
                    display: none;
                }

                .avatar {
                    display: none;
                }

                .spinner {
                    display: block;
                }
            }

            &.abort {
                video {
                    display: none;
                }

                .avatar {
                    display: block;
                }

                .spinner {
                    display: none;
                }
            }

            &.loading {
                video {
                    display: none;
                }

                .avatar {
                    display: none;
                }

                .spinner {
                    display: block;
                }
            }
        }

        .info {
            z-index: 2;
            position: absolute;
            bottom: 1.6rem;
            left: 2rem;
            padding: 0.5rem 1.5rem 0.5rem 0.7rem;
            border-radius: 0.5rem;
            background-color: #0000006e;
            display: flex;
            align-items: center;
            //color: #9FA0A0;
            font-size: 1.4rem;
            width: fit-content;
            max-width: 90%;
            white-space: nowrap;

            .nickname {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .separator {
                margin: 0 1rem;
            }

            .mic-sensor-wrap {
                position: relative;
                flex-shrink: 0;

                .line {
                    width: 135%;
                    height: 6px;
                    background: var(--color-danger);
                    transform: translate(-50%, -50%) rotate(-45deg);
                    border-radius: 10px;
                    border: 2px solid var(--color-light-secondary);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }

                &.on .line {
                    display: none;
                }

                &.off .line {
                    display: block;
                }
            }

            .org {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .btn-more-wrap {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 2rem;
            right: 2rem;
            z-index: 2;

            .btn-more {
                transition: background-color 0.25s;
            }
        }

        .content-cover {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            background: #1e1f22;
            border-radius: 1.5rem;
            z-index: 1;
            border: 2px solid var(--color-success);
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            &.open {
                opacity: 1;
            }

            &.close {
                opacity: 0;
            }
        }

        &:hover {
            .btn-more {
                background-color: rgba(0, 0, 0, 0.43);
            }
        }

        // 화면공유 CSS
        &.vg-content {
            &::before {
                z-index: 1;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 2px solid var(--color-success);
                border-radius: 1.5rem;
            }

            .video-wrap {
                video {
                    object-fit: contain;
                }
            }

            .info {
                top: 0;
                left: 50%;
                bottom: inherit;
                transform: translateX(-50%);
                /*background: var(--color-success);
        padding: .5rem 1.1rem .5rem 1.1rem;
        border-radius: 0 0 1rem 1rem;*/
                padding: 0;
                background: none;

                .label {
                    background: var(--color-success);
                    padding: 0.3rem 1.1rem 0 1.1rem;
                    border-radius: 0 0 1rem 1rem;
                    height: 2.7rem;
                }

                .btn-stop {
                    cursor: pointer;
                    background: var(--color-danger);
                    border-top: 2px solid var(--color-success);
                    padding: 1.5px 0.7rem 0px 0.5rem;
                    border-radius: 0 0 1rem 0;
                    height: 2.7rem;
                    display: none;
                    transform: translateX(-2px);
                }
            }
        }

        // 화면공유 전체화면 CSS
        &.vg-content.full-mode {
            .video-wrap {
                //border-radius: 0;
            }

            .content-cover {
                width: 100%;
                height: 100%;
                //border-radius: 0;
            }
        }

        &.vg-content.full-mode:before {
            //border-radius: 0;
            box-sizing: border-box;
        }

        // 전체화면 버튼 CSS
        .btn-fullscreen-wrap {
            position: absolute;
            top: 1rem;
            right: 1rem;
            z-index: 4;
            width: 45px;
            height: 45px;
            border-radius: 9999px;
            background-color: white;
            background-position: center;
            background-size: contain;
            cursor: pointer;
        }

        &.full-mode .btn-fullscreen {
            background-image: url(https://markit-cdn.s3.ap-northeast-2.amazonaws.com/Merz/mexfacetalk/rd/icon-notfullscreen.svg);
        }

        &.not-full-mode .btn-fullscreen {
            background-image: url(https://markit-cdn.s3.ap-northeast-2.amazonaws.com/Merz/mexfacetalk/rd/icon-fullscreen.svg);
        }

        // 자신의 화면공유 일 시 CSS
        &.vg-content.my-content,
        &.vg-content.host {
            .content-cover {
                display: flex;
            }

            .info {
                .label {
                    border-radius: 0 0 0 1rem;
                }

                .btn-stop {
                    display: block;
                }
            }
        }

        // 터치 스크린
        @media all and (pointer: coarse) {
            .btn-more-wrap {
                display: none;
            }
        }
        // 노트북
        @media screen and (max-width: 1680px) {
            .info {
                left: 1rem;
                bottom: 0.6rem;
                font-size: 1.2rem;
            }
        }
        @media screen and (max-height: 1024px) {
            .info {
                left: 1rem;
                bottom: 0.6rem;
                font-size: 1.2rem;
            }
        }

        // 모바일 세로
        @media screen and (max-width: ${c.bp.mh.maxWidth}px) and (orientation: ${c.bp.mh.orientation}) {
            &.vg-content > .info {
                display: none;
            }

            &.full-mode > .btn-fullscreen-wrap {
                bottom: 1.5rem;
                right: 1.5rem;
            }

            .btn-fullscreen-wrap {
                top: unset;
                bottom: 0.5rem;
                right: 0.5rem;
                width: 35px;
                height: 35px;
            }
        }
        // 모바일 가로
        @media screen and (max-width: ${c.bp.mw.maxWidth}px) and (max-height: ${c.bp.mw.maxHeight}px),
            screen and (max-width: 500px) and (orientation: portrait) {
            &.full-mode > .btn-fullscreen-wrap {
                bottom: 1.5rem;
                right: 1.5rem;
            }
            .btn-fullscreen-wrap {
                top: unset;
                bottom: 0.5rem;
                right: 0.5rem;
                width: 35px;
                height: 35px;
            }

            &.fix:after {
                width: 2.5rem;
                height: 2.5rem;
            }

            .video-wrap {
                box-shadow: 0 0 10px -5px #171717;
            }

            &.vg-content > .info {
                display: none;
            }

            .info {
                max-width: 90%;
                padding: 0.25rem 0.5rem;
                font-size: 1.2rem;
                bottom: 0.8rem;
                left: 0.8em;

                .separator {
                    display: none;
                }

                .org {
                    display: none;
                }

                .mic-sensor-wrap {
                    width: 12px !important;
                    height: 12px !important;
                    margin-right: 0.2rem;
                }
            }

            .btn-more {
                display: none;
            }
        }
    `,
};

function VideoGroup({ sid, uuid, infoTy, attendeeId, nickname, org, isOnFix, tileId, isOnCam, isOnMic }) {
    const videoGroupRef = useRef(null);
    const videoWrapRef = useRef(null);
    const videoRef = useRef(null);
    const items = ['sid', 'uuid', 'infoTy', 'attendeeId', 'peer', 'viewMode', 'isMakeHost', 'isOnContentCover', 'userActions', 'usersActions'];
    const z = useStore(getItems(items), shallow);
    const meetingSession = useMeetingSession();
    const isContentShare = uuid.indexOf('content') > -1;
    
    useEffect(() => {
        const videoDom = videoRef.current;
        if (videoDom) {
            const onPlay = () => {
                videoDom.parentElement.classList.add('e-play');
            }
            videoDom.addEventListener('loadedmetadata', onPlay);
            videoDom.addEventListener('play', onPlay);
        }
    }, [videoRef]);

    useEffect(() => {
        if(tileId) meetingSession.audioVideo.bindVideoElement(tileId, videoRef.current);
    }, [tileId])


    const toggleContentCover = () => {
        z.userActions.setIsOnContentCover(false);
    };
    const stopContentShare = () => {
        document.querySelector('.s-icon-btn.contentShare.success')?.click();
    };
    return (
        <S.VideoGroup
            className={`
                video-group 
                vg-${sid} 
                vg-${uuid}
                vg-${attendeeId} 
                ${isContentShare ? 'vg-content' : ''} 
                ${isContentShare && z.uuid === uuid.split('#')[0] ? 'my-content' : ''}
                ${z.infoTy === 1 || z.isMakeHost ? 'host' : ''}
                ${z.viewMode === 'fullscreen' ? 'full-mode' : 'not-full-mode'}
                ${uuid === z.uuid ? 'my-vg' : ''}
                ${isOnFix ? 'fix' : ''}
                ${isOnCam ? 'play' : 'abort'}
            `}
            data-uuid={uuid}
            data-tile-id={tileId}
            data-attendee-id={attendeeId}
            ref={videoGroupRef}
        >
            <div
                className={`
                    video-wrap 
                    vw-${sid} 
                    vw-${uuid}
                    vw-${attendeeId} 
                    ${z.uuid}
                    ${tileId ? 'play' : 'abort'}
                `}
                ref={videoWrapRef}
                onClick={toggleContentCover}
            >
                <video
                    ref={videoRef}
                    className={`v-${sid} v-${uuid} v-${attendeeId}`}
                    muted
                    autoPlay
                    loop
                    playsInline
                    data-tile-id={tileId}
                    style={{
                        transform: z.uuid === uuid ? 'scaleX(-1)' : undefined,
                    }}
                />
                <div className="avatar">
                    <span>{nickname.substring(0, 1)}</span>
                </div>
                <Spinner className="spinner" />
            </div>

            {isContentShare ? (
                /*자신의 화면공유라면 화면공유중
        타인의 화면공유라면 ㅇㅇㅇ님의 화면공유
        일반 비디오 타일이라면 ㅇㅇㅇ | ㅇㅇ병원*/
                z.uuid === uuid.split('#')[0] || z.infoTy === 1 || z.isMakeHost ? (
                    <div className="info">
                        <div className="label">{nickname}님 화면공유 중</div>
                        <div className="btn-stop" onClick={stopContentShare}>
                            ■
                        </div>
                    </div>
                ) : (
                    <div className="info">
                        <div className="label">{nickname}님 화면공유 중</div>
                    </div>
                )
            ) : (
                <div className="info">
                    <div className={`mic-sensor-wrap ${isOnMic ? 'on' : 'off'}`} style={{ width: '18px', height: '18px', marginRight: '.5rem' }}>
                        <MicSensor uuid={uuid} />
                        <div className="line"></div>
                    </div>
                    <span className="nickname">{nickname}</span>
                </div>
            )}

            {isContentShare ? (
                <div className="btn-wrap btn-fullscreen-wrap action">
                    <BtnFullscreen></BtnFullscreen>
                </div>
            ) : (
                <div className="btn-more-wrap">
                    <BtnMore uuid={uuid} isOnFix={isOnFix} meetingSession={meetingSession} infoTy={infoTy} />
                </div>
            )}
            {isContentShare && z.isOnContentCover && z.uuid === uuid.split('#')[0] && (
                <div className="content-cover">
                    {/*<div className="message">화면공유가 가려져있어요</div>*/}
                    <div className={`btn btn-secondary `} onClick={toggleContentCover}>
                        화면 보기
                    </div>
                </div>
            )}
        </S.VideoGroup>
    );
}

export default memo(VideoGroup);
// export default VideoGroup;
