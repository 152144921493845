import c from '../config/config';

const tools = {};
tools.getRandomCreateID = () => {};
tools.isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
tools.getAccessOfMediaDevice = async () => {
    return await navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((x) => {
            return { isAccess: true, stream: x };
        })
        .catch((e) => {
            console.error('getAccess 에러 발생', { name: e.name, message: e.message });
            let passError = ['NotReadableError', 'NotFoundError'];
            return { isAccess: passError.indexOf(e.name) > -1, name: e.name, message: e.message };
        });
};
tools.getVideoAccess = async () => {
    return await navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((x) => {
            return true;
        })
        .catch((e) => {
            let passError = ['NotReadableError', 'NotFoundError'];
            return passError.indexOf(e.name) > -1;
        });
};
tools.getAudioAccess = async () => {
    return await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((x) => {
            return true;
        })
        .catch((e) => {
            let passError = ['NotReadableError', 'NotFoundError'];
            return passError.indexOf(e.name) > -1;
        });
};
tools.getMediaDevices = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return false;
    }
    let isMobile = tools.isMobile();
    let devices = await navigator.mediaDevices.enumerateDevices();
    let cams = [],
        spks = [],
        mics = [];

    devices.forEach((x) => {
        if (isMobile) {
            if (x.kind === 'videoinput') cams.push(x);
            if (x.kind === 'audiooutput') spks.push(x);
            if (x.kind === 'audioinput') mics.push(x);
        } else {
            if (x.kind === 'videoinput' && !x.label.includes('iPhone')) cams.push(x);
            if (x.kind === 'audiooutput') spks.push(x);
            if (x.kind === 'audioinput' && !x.label.includes('iPhone')) mics.push(x);
        }
    });

    return { cams, spks, mics };
};
tools.getVideoStream = async (deviceId) => {
    if (!deviceId) return { success: false };
    let stream = null;
    try {
        stream = await navigator.mediaDevices.getUserMedia({
            video: {
                width: { ideal: 1280 },
                aspectRatio: 16 / 9,
                deviceId: deviceId,
            },
            audio: false,
        });
        return { success: true, stream };
    } catch (err) {
        console.error('getVideoStream 에러 발생', err);
        return { success: false, err };
    }
};
tools.requestFullscreen = () => {
    let doc = document.documentElement;
    if (doc.requestFullscreen) doc.requestFullscreen();
    else if (doc.webkitRequestFullscreen)
        // Chrome, Safari (webkit)
        doc.webkitRequestFullscreen();
    else if (doc.mozRequestFullScreen)
        // Firefox
        doc.mozRequestFullScreen();
    else if (doc.msRequestFullscreen)
        // IE or Edge
        doc.msRequestFullscreen();
};
tools.requestDefaultScreen = () => {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.webkitExitFullscreen)
        // Chrome, Safari (webkit)
        document.webkitExitFullscreen();
    else if (document.mozCancelFullScreen)
        // Firefox
        document.mozCancelFullScreen();
    else if (document.msExitFullscreen)
        // IE or Edge
        document.msExitFullscreen();
};
tools.ondevicechange = (e) => {
    console.log(e);
};

tools.getIsTouchScreen = () => window.matchMedia('(pointer:coarse)').matches || window.matchMedia('(any-pointer:coarse)').matches;
tools.getIsMobile = () =>
    ((window.innerWidth <= c.bp.mw.maxWidth && window.innerHeight <= c.bp.mw.maxHeight) ||
        (window.innerWidth <= c.bp.mh.maxWidth && window.innerWidth / window.innerHeight < 1)) &&
    (window.matchMedia('(any-pointer:coarse)')?.matches);
tools.getIsTablet = () =>
    ((window.innerWidth <= c.bp.tw.maxWidth && window.innerHeight <= c.bp.tw.maxHeight) ||
        (window.innerWidth <= c.bp.th.maxWidth && window.innerWidth / window.innerHeight < 1)) &&
    (window.matchMedia('(any-pointer:coarse)')?.matches);
tools.getIsTabletW = () =>
    window.innerWidth <= c.bp.tw.maxWidth &&
    window.innerWidth / window.innerHeight > 1 &&
    (window.matchMedia('(any-pointer:coarse)')?.matches);
tools.getIsTabletH = () =>
    window.innerWidth <= c.bp.th.maxWidth &&
    window.innerWidth / window.innerHeight < 1 &&
    (window.matchMedia('(any-pointer:coarse)')?.matches);
tools.getIsMobileW = () =>
    window.innerWidth <= c.bp.mw.maxWidth &&
    window.innerWidth / window.innerHeight > 1 &&
    (window.matchMedia('(any-pointer:coarse)')?.matches);
tools.getIsMobileH = () =>
    window.innerWidth <= c.bp.mh.maxWidth &&
    window.innerWidth / window.innerHeight < 1 &&
    (window.matchMedia('(any-pointer:coarse)')?.matches);

tools.chunkArray = (array, size) => {
    let result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};
tools.bytesToMB = (bytes) => {
    return (((bytes / 1000 ** 2) * 100) / 100).toFixed(1) * 1;
};
tools.bytesToKB = (bytes) => {
    return (((bytes / 1000) * 100) / 100).toFixed(0) * 1;
};
tools.getPublicLink = (meetingType, meetingIdx) => {
    let tempArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
    let publicCode = '';
    for(let x of String(meetingIdx)) {
        publicCode += tempArray[x] + x;
    }
    publicCode = btoa(publicCode);
    return `${window.location.origin}/${meetingType !== 'webinar' ? `${meetingType}/` : ''}login/${publicCode}`;
}
export default tools;
