/* eslint-disable */
import styled, {keyframes} from "styled-components";

const A = {}
const S = {}

A.FadeInUp = keyframes`
  0% {
    opacity: 0;
    top: 20px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

S.Container = styled.div`
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;

  .dragable {
    -webkit-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text
  }

  font-size: 1.4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .btn-rel {
    position: relative;
    text-align: left;
    flex: 1;
    max-width: 80px;
    //padding: 1rem 2rem 0 2rem;
    display: flex;
    align-items: center;

    .btn {
      position: relative;
      background-color: transparent;
      fill: white;
      border: none;
      color: white;
      width: 100%;
      padding: 0;

      svg {
        width: 30px;
        height: 30px;
        //margin-bottom: 1rem;
      }
    }

    .btn.on {
      fill: var(--color-success);
    }

    .btn.off:after {
      content: '';
      background-image: url(https://markit-cdn.s3.ap-northeast-2.amazonaws.com/Merz/mexfacetalk/rd/icon-off.svg);
      background-position: center;
      background-size: 100%;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 0;
      left: 50%;
      transform: translateX(-50%) scale(1.25);
    }

    .set {
      position: absolute;
      left: 50%;
      width: max-content;
      background-color: var(--color-dark-3);
      padding: 1.3rem 1rem;
      border-radius: 6px;
      display: none;
      transform: translate(-50%, -100%);
      box-shadow: 0 0 30px #00000067;


      li {
        border-radius: 6px;
        padding: 1rem;
        cursor: pointer;
        margin-bottom: .4rem;
      }

      li:last-child {
        margin-bottom: 0;
      }

      li:hover {
        background-color: var(--color-dark-4);
      }

      li.on {
        background-color: var(--color-dark-5);
      }
    }
  }

  .btn-rel:hover, .set:hover {
    .set {
      display: block;
      animation: ${A.FadeInUp} .3s forwards;
    }
  }
  
  
  
`;


export default S;