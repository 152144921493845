import React from 'react';
import IconBtn from "../Components/IconBtn";
import {getItems, shallow, useStore} from "../../zustand/store";
import styled from 'styled-components';

const S = {
    BtnWrap: styled.div`
        position: relative;

        .tooltip {
            position: absolute;
            background-color: rgb(76, 76, 78);
            color: white;
            border-radius: 1rem;
            top: -10px;
            left: 50%;
            transform: translate(-50%, -100%);
            width: max-content;
            padding: 1rem 2rem;
            text-align: center;
            visibility: hidden;
            pointer-events: none;
            opacity: 0;
            transition: opacity .15s, visibility .15s;
        }

        &.on:hover {
            .tooltip {
                opacity: 1;
                visibility: visible;
            }
        }
    `
}

function BtnWebinar({label}) {
    const items = ['webinarStatus', 'userActions']
    const z = useStore(getItems(items), shallow)
    const toggleWebinar = async () => {
        if(z.webinarStatus === 3) return;
        z.userActions.setIsOnAlertWebinar(true)
    }
    
    return (
        <S.BtnWrap className={`btn-wrap ${z.webinarStatus === 2 ? 'on' : 'off'}`}>
            <IconBtn icon='webinar'
                    theme="secondary"
                    onTheme="secondary"
                    isOn={z.webinarStatus === 2}
                    onType={2}
                    onClick={toggleWebinar}
                    disabled={z.webinarStatus === 3}
                    label={label}
            />
            {/* <div className='tooltip'>
                웨비나가 종료되었습니다.
            </div> */}
        </S.BtnWrap>
    );
}

export default BtnWebinar;