import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { getItems, shallow, useStore } from '../../zustand/store';
const A = {
    hide: keyframes`
        0% {
            display: flex;
            opacity: 1;
        }
        99% {
            display: flex;
            opacity: 1;
        }
        100% {
            display: none;
            opacity: 0;
        }
    `,
}
const S = {
    Container: styled.div`
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: var(--footer-height);
        justify-content: center;
        align-items: center;

        &.hide {
            animation: ${A.hide} 1s forwards;
        }

        & > div {
            background: var(--color-dark-2);
            color: white;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            gap: 1rem;
            .img-wrap {
                display: inline-block;
                width: 25px;
                img {
                    width: 100%;
                    filter: brightness(0) invert(1);
                }
            }
        }
    `,
};
export default function Activator() {
    const containerRef = useRef(null);
    const items = ['changeNicknameMap', 'fixUserList', 'unFixUserList', 'users', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const activator = Object.keys(z.users).reduce((a, c, i) => {
        let user = z.users[c];
        if (user.isOnActivate) a.push(user);
        return a;
    }, [])[0];
    const handleOnClick = () => {
        const el = document.querySelector('.v-' + activator.uuid);
        const idx = el.closest('.swiper-slide').dataset.idx * 1;
        const targetSwiper = el.closest('.swiper').classList.contains('user-list') ? 'user-list' : 'spotlight-list';
        if (targetSwiper === 'user-list') {
            z.userActions.setUserListSwiperIdx(idx);
        } else {
            z.userActions.setSpotlightSwiperIdx(idx);
        }
    };

    return (
        <S.Container ref={containerRef} className="activator action" onClick={handleOnClick}>
            {activator && (
                <div className="action">
                    <div className="img-wrap action">
                        <img className="action" src="/icon/activator.png" />
                    </div>
                    {z.changeNicknameMap[activator.uuid] || `${activator.nickname} | ${activator.org1}`}
                </div>
            )}
        </S.Container>
    );
}
