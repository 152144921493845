import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getItems, shallow, useStore } from '../../zustand/store';
import { useSocket } from '../../Contexts/SocketContext';

const S = {
    Wrapper: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        background-size: 65%;
        background-image: url('/icon/more.svg');
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 5px;
        transition: background-color;
        cursor: pointer;

        .set {
            position: absolute;
            top: 0;
            right: 0;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            background-color: #313132;
            color: #b4b5b6;
            border-radius: 1rem;
            z-index: 10;
            width: max-content;

            li {
                padding: 0.5rem 1rem;
                border-radius: 5px;
                min-width: max-content;
                width: 100%;
            }

            li:hover {
                background-color: #666565;
                color: white;
            }

            &.close {
                display: none;
            }
        }
    `,
};

function BtnMore({ isOnFix, uuid, infoTy }) {
    const items = ['uuid', 'sid', 'infoTy', 'isMobile', 'userActions', 'usersActions', 'tempActions', 'isOnForceMuteAll', 'forceMuteList', 'unForceMuteList', 'isMakeHost', 'makeHostList', 'computeIsOnForceMute'];
    const z = useStore(getItems(items), shallow);
    const setRef = useRef(null);
    const [isOnForceMute, setIsOnForceMute] = useState(false);
    const socket = useSocket();

    const onClickWrapper = (e) => {
        e.stopPropagation();
        setRef.current.classList.remove('close');
        setRef.current.classList.add('open');
    };
    const onClickUl = (e) => {
        e.stopPropagation();
        setRef.current.classList.remove('open');
        setRef.current.classList.add('close');
    };
    const onFix = () => {
        socket.request.onFix({ uuid });
    };
    const offFix = () => {
        socket.request.offFix({ uuid });
    };
    const onForceMute = () => {
        socket.request.onForceMute({ uuid });
    };
    const offForceMute = () => {
        socket.request.offForceMute({ uuid });
    };
    const onMute = () => {
        socket.request.onMute({ uuid });
    }
    const onMakeHost = () => {
        socket.request.onMakeHost({ uuid });
    };
    const offMakeHost = () => {
        socket.request.offMakeHost({ uuid });
    };
    const onModalChangeNickname = () => {
        z.userActions.setIsOnModalChangeNickname(true);
        z.tempActions.setChangeNicknameUUID(uuid);
    };
    const onForceExit = () => {
        socket.request.onForceExit({ uuid });
    };
    useEffect(() => {
        setIsOnForceMute(z.computeIsOnForceMute(uuid));
    }, [z.isOnForceMuteAll, z.forceMuteList, z.unForceMuteList]);

    return (
        (z.infoTy === 1 || z.isMakeHost) &&
        !z.isMobile && (
            <S.Wrapper onClick={onClickWrapper} className="btn-more">
                <ul className="set close" onClick={onClickUl} ref={setRef}>
                    <li onClick={onModalChangeNickname}>이름 바꾸기</li>
                    {/* {!isOnForceMute ? <li onClick={onForceMute}>음소거</li> : <li onClick={offForceMute}>음소거 해제</li>} */}
                    <li onClick={onMute}>음소거</li>
                    {!isOnFix ? <li onClick={onFix}>고정</li> : <li onClick={offFix}>고정 해제</li>}
                    {uuid !== z.uuid && infoTy !== 1 && <li onClick={onForceExit}>강제퇴장</li>}
                    {uuid !== z.uuid && infoTy !== 1 && (!z.makeHostList.includes(uuid) ? <li onClick={onMakeHost}>호스트 만들기</li> : <li onClick={offMakeHost}>토론자 만들기</li>)}
                </ul>
            </S.Wrapper>
        )
    );
}

export default BtnMore;
