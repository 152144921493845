import React, { useEffect } from 'react';
import {getItems, shallow, useStore} from "../../../zustand/store";
import Modal from '../../Components/Modal';
import { useSocket } from '../../../Contexts/SocketContext';
export default function AlertRecord() {
    const items = ['sid', 'meetingId', 'isOnAlertRecord', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const socket = useSocket();

    const onConfirm = () => {
        socket.request.onRecord();
        z.userActions.setIsOnAlertRecord(false);
    }
    const onCancel = () => {
        z.userActions.setIsOnAlertRecord(false);
    }
    return (
        <Modal
            isOpen={z.isOnAlertRecord}
            confirmText="녹화"
            cancelText="취소"
            confrimBtnTheme="danger"
            onConfirm={onConfirm}
            onCancel={onCancel}
            showCloseBtn={true}
            size='sm'
        >
            <h3 style={{textAlign:'center', marginBottom: '2rem'}}>회의 녹화를 시작하시겠습니까?</h3>
            <p style={{textAlign:'center', color:'#B6B6B6'}}>회의가 종료되면 녹화가 자동 중지되며,<br/> 파일은 호스트의 메일로 전달됩니다.</p>
        </Modal>
    );
}
