import React from 'react';
import { getItems, shallow, useStore } from '../../zustand/store';
import { useNavigate } from 'react-router-dom';
import IconBtn from '../Components/IconBtn';

function BtnExit({ label }) {
    const items = ['userActions'];
    const z = useStore(getItems(items), shallow);

    const onAlert = () => {
        z.userActions.setIsOnAlertExit(true);
    };

    return (
        <div className="btn-wrap" style={{ position: 'relative' }}>
            <IconBtn icon="exit" theme="secondary" hoverTheme="danger" onClick={onAlert} label={label} />
        </div>
    );
}

export default BtnExit;
