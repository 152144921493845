import { KinesisVideo, KinesisVideoSignalingChannels } from 'aws-sdk';
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import axios from 'axios';
import io from "socket.io-client";

class KinesisMaster {
    constructor({ channelName, localStream, type, socket }) {
        this.formValues = {
            region: 'ap-northeast-2',
            channelName: channelName,
            clientId: channelName,
            sendVideo: true,
            sendAudio: true,
            streamName: '',
            ingestMedia: false,
            openDataChannel: true,
            widescreen: true,
            fullscreen: false,
            useTrickleICE: true,
            natTraversalDisabled: false,
            forceSTUN: false,
            forceTURN: false,
            endpoint: null,
            accessKeyId: 'AKIAYUIFAWXQMDXU6LXI',
            secretAccessKey: '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',
            sessionToken: null,
            enableDQPmetrics: false,
        };
        this.millisecondsInTenMinutes = 600_000;
        this.maxConnectionFailuresWithinTenMinutesForRetries = 5;
        this.retryIntervalForJoinStorageSession = 6000;
        this.master = {
            kinesisVideoClient: null,
            signalingClient: null,
            storageClient: null,
            channelARN: null,
            streamARN: null,
            peerConnectionByClientId: {},
            dataChannelByClientId: {},
            videoTrack: null,
            audioTrack: null,
            localStream: localStream,
            remoteStreams: [],
            peerConnectionStatsInterval: null,
            runId: 0,
            sdpOfferReceived: false,
            websocketOpened: false,
            connectionFailures: [], // Dates of when PeerConnection transitions to failed state.
            currentJoinStorageSessionRetries: 0,
        };
        this.socket = socket;


        this.socket.emit('joinKinesisRoom', { roomName: this.formValues.channelName, type: 'master' });
        this.socket.emit('connMaster', { channelName: this.formValues.channelName });
        return;
        
        (async () => {
            if(type !== 'contentShare') {
                await axios
                .post('/api/kinesis/signalingChannel/' + channelName)
                .then(({ data: x }) => {
                    console.debug(x);
                })
                .catch((e) => {
                    console.debug(e);
                })
            }
                
            this.connectMaster();
        })();
    }

    async connectMaster() {
        return;

        this.master.sdpOfferReceived = false;
        this.master.connectionFailures = [];
        this.master.currentJoinStorageSessionRetries = 0;

        try {
            // this.master.localView = localView;
            // this.master.remoteView = remoteView;

            // Create KVS client
            const kinesisVideoClient = new KinesisVideo({
                region: this.formValues.region,
                accessKeyId: this.formValues.accessKeyId,
                secretAccessKey: this.formValues.secretAccessKey,
                sessionToken: this.formValues.sessionToken,
                endpoint: this.formValues.endpoint,
                correctClockSkew: true,
            });
            this.master.kinesisVideoClient = kinesisVideoClient;

            // Get signaling channel ARN
            const describeSignalingChannelResponse = await kinesisVideoClient
                .describeSignalingChannel({
                    ChannelName: this.formValues.channelName,
                })
                .promise();
            const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
            console.debug('[MASTER] Channel ARN:', channelARN);

            this.master.channelARN = channelARN;

            const protocols = ['WSS', 'HTTPS'];

            console.debug('[MASTER] Not using media ingestion feature.');
            this.master.streamARN = null;

            // Get signaling channel endpoints
            const getSignalingChannelEndpointResponse = await kinesisVideoClient
                .getSignalingChannelEndpoint({
                    ChannelARN: channelARN,
                    SingleMasterChannelEndpointConfiguration: {
                        Protocols: protocols,
                        Role: Role.MASTER,
                    },
                })
                .promise();
            const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
                (endpoints, endpoint) => {
                    endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
                    return endpoints;
                },
                {}
            );
            console.debug('[MASTER] Endpoints:', endpointsByProtocol);

            // Create Signaling Client
            this.master.signalingClient = new SignalingClient({
                channelARN,
                channelEndpoint: endpointsByProtocol.WSS,
                role: Role.MASTER,
                region: this.formValues.region,
                credentials: {
                    accessKeyId: this.formValues.accessKeyId,
                    secretAccessKey: this.formValues.secretAccessKey,
                    sessionToken: this.formValues.sessionToken,
                },
                systemClockOffset: kinesisVideoClient.config.systemClockOffset,
            });

            // Get ICE server configuration
            const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
                region: this.formValues.region,
                accessKeyId: this.formValues.accessKeyId,
                secretAccessKey: this.formValues.secretAccessKey,
                sessionToken: this.formValues.sessionToken,
                endpoint: endpointsByProtocol.HTTPS,
                correctClockSkew: true,
            });
            const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
                .getIceServerConfig({
                    ChannelARN: channelARN,
                })
                .promise();
            const iceServers = [];
            // Don't add stun if user selects TURN only or NAT traversal disabled
            if (!this.formValues.natTraversalDisabled && !this.formValues.forceTURN) {
                iceServers.push({
                    urls: `stun:stun.kinesisvideo.${this.formValues.region}.amazonaws.com:443`,
                });
            }

            // Don't add turn if user selects STUN only or NAT traversal disabled
            if (!this.formValues.natTraversalDisabled && !this.formValues.forceSTUN) {
                getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
                    iceServers.push({
                        urls: iceServer.Uris,
                        username: iceServer.Username,
                        credential: iceServer.Password,
                    })
                );
            }
            console.debug('[MASTER] ICE servers:', iceServers);

            const configuration = {
                iceServers,
                iceTransportPolicy: this.formValues.forceTURN ? 'relay' : 'all',
            };

            // const resolution = this.formValues.widescreen
            //     ? {
            //           width: { ideal: 1280 },
            //           height: { ideal: 720 },
            //       }
            //     : { width: { ideal: 640 }, height: { ideal: 480 } };
            // const constraints = {
            //     video: this.formValues.sendVideo ? resolution : false,
            //     audio: this.formValues.sendAudio,
            // };

            // // Get a stream from the webcam and display it in the local view.
            // // If no video/audio needed, no need to request for the sources.
            // // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
            // try {
            //     this.master.localStream = await navigator.mediaDevices.getUserMedia(constraints);
            // } catch (e) {
            //     console.error(
            //         `[MASTER] Could not find ${Object.keys(constraints).filter((k) => constraints[k])} input device.`,
            //         e
            //     );
            //     return;
            // }

            this.master.signalingClient.on('open', async () => {
                const masterRunId = ++this.master.runId;
                this.master.websocketOpened = true;
                console.debug('[MASTER] Connected to signaling service');
                
                if (this.master.streamARN) {
                    if (this.formValues.ingestMedia) {
                        await this.connectToMediaServer(masterRunId);
                    } else {
                        console.debug('[MASTER] Waiting for media ingestion and storage viewer to join...');
                    }
                } else {
                    console.debug('[MASTER] Waiting for peers to join...');
                }
            });

            this.master.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
                this.printSignalingLog('[MASTER] Received SDP offer from client', remoteClientId);
                this.master.sdpOfferReceived = true;
                this.master.currentJoinStorageSessionRetries = 0;
                console.debug('SDP offer:', offer);

                // Create a new peer connection using the offer from the given client
                if (
                    this.master.peerConnectionByClientId[remoteClientId] &&
                    this.master.peerConnectionByClientId[remoteClientId].connectionState !== 'closed'
                ) {
                    this.master.peerConnectionByClientId[remoteClientId].close();
                }
                const peerConnection = new RTCPeerConnection(configuration);
                this.master.peerConnectionByClientId[remoteClientId] = peerConnection;

                if (this.formValues.openDataChannel) {
                    peerConnection.ondatachannel = (event) => {
                        this.master.dataChannelByClientId[remoteClientId] = event.channel;
                        event.channel.onmessage = this.onRemoteDataMessage;
                    };
                }

                // Poll for connection stats
                if (!this.master.peerConnectionStatsInterval) {
                    this.master.peerConnectionStatsInterval = setInterval(
                        () => peerConnection.getStats().then(this.onStatsReport),
                        10000
                    );
                }

                peerConnection.addEventListener('connectionstatechange', async (event) => {
                    // printPeerConnectionStateInfo(event, '[MASTER]', remoteClientId);

                    if (this.master.streamARN && event.target.connectionState === 'connected') {
                        console.debug(
                            '[MASTER] Successfully joined the storage session. Media is being recorded to',
                            this.master.streamARN
                        );
                    }
                });

                // Send any ICE candidates to the other peer
                peerConnection.addEventListener('icecandidate', ({ candidate }) => {
                    if (candidate) {
                        this.printSignalingLog('[MASTER] Generated ICE candidate for client', remoteClientId);
                        // console.debug('ICE candidate:', candidate);

                        // When trickle ICE is enabled, send the ICE candidates as they are generated.
                        if (this.formValues.useTrickleICE) {
                            this.printSignalingLog('[MASTER] Sending ICE candidate to client', remoteClientId);
                            this.master.signalingClient.sendIceCandidate(candidate, remoteClientId);
                        }
                    } else {
                        this.printSignalingLog(
                            '[MASTER] All ICE candidates have been generated for client',
                            remoteClientId
                        );

                        // When trickle ICE is disabled, send the answer now that all the ICE candidates have ben generated.
                        if (!this.formValues.useTrickleICE) {
                            this.printSignalingLog('[MASTER] Sending SDP answer to client', remoteClientId);
                            console.debug('SDP answer:', peerConnection.localDescription);
                            this.master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
                        }
                    }
                });

                // As remote tracks are received, add them to the remote view
                peerConnection.addEventListener('track', (event) => {
                    // this.printSignalingLog(
                    //     '[MASTER] Received remote track from client',
                    //     remoteClientId
                    // );
                    // this.addViewerTrackToMaster(remoteClientId, event.streams[0]);
                });

                // If there's no video/audio, this.master.localStream will be null. So, we should skip adding the tracks from it.
                if (this.master.localStream) {
                    this.master.localStream.getTracks().forEach((track) => {
                        peerConnection.addTrack(track, this.master.localStream);
                    });
                }
                await peerConnection.setRemoteDescription(offer);

                // Create an SDP answer to send back to the client
                this.printSignalingLog('[MASTER] Creating SDP answer for client', remoteClientId);
                await peerConnection.setLocalDescription(
                    await peerConnection.createAnswer({
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true,
                    })
                );

                // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
                if (this.formValues.useTrickleICE) {
                    this.printSignalingLog('[MASTER] Sending SDP answer to client', remoteClientId);
                    console.debug('SDP answer:', peerConnection.localDescription);
                    this.master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
                }
                this.printSignalingLog('[MASTER] Generating ICE candidates for client', remoteClientId);
            });

            this.master.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {
                this.printSignalingLog('[MASTER] Received ICE candidate from client', remoteClientId);
                // console.debug('[MASTER] ICE candidate:', candidate);

                // Add the ICE candidate received from the client to the peer connection
                const peerConnection = this.master.peerConnectionByClientId[remoteClientId];
                peerConnection.addIceCandidate(candidate);
            });

            this.master.signalingClient.on('close', () => {
                this.master.websocketOpened = false;
                this.master.runId++;
                this.master.signalingClient.open();
                console.debug('[MASTER] Disconnected from signaling channel');
            });

            this.master.signalingClient.on('error', (error) => {
                console.error('[MASTER] Signaling client error', error);
            });
            
            setInterval(() => {
                this.master.signalingClient.close();
            }, 1000 * 60 * 3)

            this.socket.emit('joinKinesisRoom', { roomName: this.formValues.channelName, type: 'master' });
            this.socket.emit('connMaster', { channelName: this.formValues.channelName });
            this.master.signalingClient.open();
            console.debug('[MASTER] Starting master connection');
            
        } catch (e) {
            console.error('[MASTER] Enthis.setting.countered error starting:', e);
        }
    }

    // 차임에서 나온 스트림을 가지고 변경하기떄문에 on/off 처리는 stream이 있으면 on, null이면 off 처리임
    updateVideoStream(stream) {
        return;
        const masterVideoTrack = this.master.localStream.getVideoTracks()[0];
        const videoTrack = stream ? stream.getVideoTracks()[0] : null;

        if (stream) {
            if (masterVideoTrack === videoTrack) {
                masterVideoTrack.enabled = true;
            } else {
                this.master.localStream.removeTrack(masterVideoTrack);
                this.master.localStream.addTrack(videoTrack);
                Object.keys(this.master.peerConnectionByClientId).forEach((clientId) => {
                    let peerConnection = this.master.peerConnectionByClientId[clientId];
                    let sender = peerConnection.getSenders();
                    sender = sender.find((s) => s.track.kind === 'video');
                    sender.replaceTrack(videoTrack);
                });
            }
        } else {
            masterVideoTrack.enabled = false;
        }
    }

    updateAudioStream(stream) {
        return;
        if (!stream) return;
        const masterAudioTrack = this.master.localStream.getAudioTracks()[0];
        const audioTrack = stream.getAudioTracks()[0] || null;
        if (masterAudioTrack !== audioTrack) {
            masterAudioTrack.stop();
            this.master.localStream.removeTrack(masterAudioTrack);
            this.master.localStream.addTrack(audioTrack);

            Object.keys(this.master.peerConnectionByClientId).forEach((clientId) => {
                let peerConnection = this.master.peerConnectionByClientId[clientId];
                let sender = peerConnection.getSenders();
                sender = sender.find((s) => s.track.kind === 'audio');
                sender.replaceTrack(audioTrack);
            });
        }
    }

    stopMaster() {
        try {
            console.debug('[MASTER] Stopping master connection');
            this.master.sdpOfferReceived = true;

            if (this.master.signalingClient) {
                this.master.signalingClient.close();
                this.master.signalingClient = null;
            }

            Object.keys(this.master.peerConnectionByClientId).forEach((clientId) => {
                this.master.peerConnectionByClientId[clientId].close();
                // removeViewerTrackFromMaster(clientId);
            });
            this.master.peerConnectionByClientId = [];

            if (this.master.localStream) {
                this.master.localStream.getTracks().forEach((track) => track.stop());
                this.master.localStream = null;
            }

            this.master.remoteStreams.forEach((remoteStream) =>
                remoteStream.getTracks().forEach((track) => track.stop())
            );
            this.master.remoteStreams = [];

            if (this.master.peerConnectionStatsInterval) {
                clearInterval(this.master.peerConnectionStatsInterval);
                this.master.peerConnectionStatsInterval = null;
            }

            // if (this.master.localView) {
            //     this.master.localView.srcObject = null;
            // }

            // if (this.master.remoteView) {
            //     this.master.remoteView.srcObject = null;
            // }

            if (this.master.dataChannelByClientId) {
                this.master.dataChannelByClientId = {};
            }
        } catch (e) {
            console.error('[MASTER] Enthis.setting.countered error stopping', e);
        }
    }

    onStatsReport(report) {
        // Only print these to the console, as this prints a LOT of stuff.
        // console._debug('[STATS]', Object.fromEntries([...report.entries()]));
    }

    printSignalingLog(message, clientId) {
        return;
        console.debug(`${message}${clientId ? ': ' + clientId : ' (no senderClientId provided)'}`);
    }

    async callJoinStorageSessionUntilSDPOfferReceived(runId, kinesisVideoWebrtcStorageClient, channelARN) {
        let firstTime = true; // Used for log messages
        let shouldRetryCallingJoinStorageSession = true;
        while (
            shouldRetryCallingJoinStorageSession &&
            !this.master.sdpOfferReceived &&
            this.master.runId === runId &&
            this.master.websocketOpened
        ) {
            if (!firstTime) {
                console.warn(
                    `Did not receive SDP offer from Media Service. Retrying... (${++this.master
                        .currentJoinStorageSessionRetries})`
                );
            }
            firstTime = false;
            try {
                // The AWS SDK for JS will perform limited retries on this API call.
                await kinesisVideoWebrtcStorageClient
                    .joinStorageSession({
                        channelArn: channelARN,
                    })
                    .promise();
            } catch (e) {
                console.error(e);
                // We should only retry on ClientLimitExceededException, or internal failure. All other
                // cases e.g. IllegalArgumentException we should not retry.
                shouldRetryCallingJoinStorageSession =
                    e.code === 'ClientLimitExceededException' ||
                    e.code === 'NetworkingError' ||
                    e.code === 'TimeoutError' ||
                    e.statusCode === 500;
            }
            await new Promise((resolve) => setTimeout(resolve, this.calculateJoinStorageSessionDelayMilliseconds()));
        }
        return shouldRetryCallingJoinStorageSession && this.master.runId === runId && this.master.websocketOpened;
    }

    async connectToMediaServer(masterRunId) {
        console.debug('[MASTER] Joining storage session...');
        const success = await this.callJoinStorageSessionUntilSDPOfferReceived(
            masterRunId,
            this.master.storageClient,
            this.master.channelARN
        );
        if (success) {
            console.debug('[MASTER] Join storage session API call(s) completed.');
        } else if (masterRunId === this.master.runId) {
            console.error('[MASTER] Error joining storage session');
        } else if (!this.master.websocketOpened && !this.master.sdpOfferReceived) {
            // TODO: ideally, we send a ping message. But, that's unavailable in browsers.
            console.debug('[MASTER] Websocket is closed. Reopening...');
            this.master.signalingClient.open();
        }
    }

    shouldStopRetryingJoinStorageSession() {
        const tenMinutesAgoEpochMillis = new Date().getTime() - this.millisecondsInTenMinutes;

        let front = this.master.connectionFailures[0];
        while (front && front < tenMinutesAgoEpochMillis) {
            this.master.connectionFailures.shift();
            front = this.master.connectionFailures[0];
        }

        return this.master.connectionFailures.length >= this.maxConnectionFailuresWithinTenMinutesForRetries;
    }

    calculateJoinStorageSessionDelayMilliseconds() {
        return (
            this.retryIntervalForJoinStorageSession +
            Math.min(Math.random() * Math.pow(200, this.master.currentJoinStorageSessionRetries - 1), 10_000)
        );
    }
}


export default KinesisMaster;
