import axios from 'axios';

const api = {
    async getWebinar(webinarIdx) {
        return await axios
            .get(`/api/webinar/${webinarIdx}`)
            .then(({ data }) => data)
            .catch(({ response }) => response.data);
    },
    async getWebinarUser(meetingIdx, uuid) {
        return await axios
            .get(`/api/webinar/user/${meetingIdx}/${uuid}`)
            .then(({ data }) => data)
            .catch(({ response }) => response.data);
    },

    async getBrmeet(meetingIdx) {
        return await axios
            .get('/api/brmeet/' + meetingIdx)
            .then(({ data }) => data)
            .catch(({ response }) => response.data);
    },
    async getBrmeetUser(meetingIdx, uuid) {
        return await axios
            .get(`/api/brmeet/user/${meetingIdx}/${uuid}`)
            .then(({ data }) => data)
            .catch(({ response }) => response.data);
    },
    async createBrmeetDevator(meetingIdx, nickname, org, email) {
        return await axios
            .post(`/api/brmeet/debator/${meetingIdx}`, { nickname: nickname, org: org, email: email })
            .then(({ data }) => data)
            .catch(({ response }) => response.data);
    },

    async getBrmeetB(meetingIdx) {
        return await axios
            .get('/api/brmeet-b/' + meetingIdx)
            .then(({ data }) => data)
            .catch(({ response }) => response.data);
    },
    async getBrmeetBUser(meetingIdx, uuid) {
        return await axios
            .get(`/api/brmeet-b/user/${meetingIdx}/${uuid}`)
            .then(({ data }) => data)
            .catch(({ response }) => response.data);
    },
    async createBrmeetBDevator(meetingIdx, nickname, org, email) {
        return await axios
            .post(`/api/brmeet-b/debator/${meetingIdx}`, { nickname: nickname, org: org, email: email })
            .then(({ data }) => data)
            .catch(({ response }) => response.data);
    },
};

export default api;
