import React from 'react';
import {getItems, shallow, useStore} from "../../zustand/store";
import IconBtn from "../Components/IconBtn";

function BtnPeople({label}) {
    const items = ['isOnDrawerPeople', 'userActions']
    const z = useStore(getItems(items), shallow)

    const toggleDrawerPeople = async () => {
        if (z.isOnDrawerPeople) {
            z.userActions.setIsOnDrawerPeople(false);
        } else {
            z.userActions.setIsOnDrawerPeople(true);
        }
    }
    return (

        <>
            <IconBtn icon='people'
                     theme="secondary"
                     isOn={z.isOnDrawerPeople}
                     onType={2}
                     onTheme="white"
                     onClick={toggleDrawerPeople}
                     label={label}
            />
        </>

    );
}

export default BtnPeople;