import React, { useEffect, useState } from 'react';
import { getItems, shallow, useStore } from '../../zustand/store';
import CustomSelect from '../Components/CustomSelect';

function SelectSpk({ theme, isPreview }) {
    const items = ['usingSpk', 'usingPreviewSpk', 'spks', 'hasMediaAccess', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const [options, setOptions] = useState([]);

    const onClickOption = (e) => {
        if (!isPreview) {
            z.userActions.setUsingSpk(e.dataset.value);
        } else {
            z.userActions.setUsingPreviewSpk(e.dataset.value);
        }
    };

    useEffect(() => {
        let _options = JSON.parse(JSON.stringify(z.spks));
        if (z.hasMediaAccess === null) _options = [{ key: -1, label: '장치를 불러오는 중입니다' }];
        else if (z.hasMediaAccess === false) _options = [{ key: -2, label: '스피커에 대한 접근권한을 허용해주세요' }];
        else if (z.spks.length === 0) _options = [{ key: -3, label: '선택할 수 있는 장치가 없습니다' }];
        else
            _options = _options.reduce((a, c) => {
                let label = c.label + '';
                label = label.lastIndexOf(' (') > -1 ? label.substring(0, label.lastIndexOf(' (')) : label;
                label = label || 'Default'
                if (isPreview) c.isPick = c.deviceId === z.usingPreviewSpk;
                else c.isPick = c.deviceId === z.usingSpk;

                a.push({
                    key: c.deviceId,
                    value: c.deviceId,
                    label: label,
                    isPick: c.isPick,
                });
                return a;
            }, []);

        if (_options.filter((x) => x.isPick).length && _options.length !== 0) _options[0].isPick = true;

        setOptions(_options);
    }, [z.hasMediaAccess, z.spks, z.usingSpk, z.usingPreviewSpk]);

    return <CustomSelect onClickOption={onClickOption} options={options} theme={theme}></CustomSelect>;
}

export default SelectSpk;
