import React, {useState} from 'react';
import IconBtn from '../Components/IconBtn';
import {getItems, shallow, useStore} from "../../zustand/store";
import PopupViewMode from "../Meeting/PopupViewMode";


function BtnViewMode({label}) {
    const items = ['viewMode', 'userActions']
    const z = useStore(getItems(items), shallow)

    const [isOnPopupViewMode, setIsOnPopupViewMode] = useState(false)
    const togglePopupViewMode = () => {
        setIsOnPopupViewMode(!isOnPopupViewMode);
    }
    return (
        <>
            <IconBtn icon='viewmode'
                     theme="secondary"
                     isOn={isOnPopupViewMode}
                     onType={2}
                     onTheme="white"
                     onClick={togglePopupViewMode}
                     label={label}
            />
            <PopupViewMode isOn={isOnPopupViewMode} setIsOn={setIsOnPopupViewMode}/>
        </>
    );
}

export default BtnViewMode;