import styled, { keyframes } from 'styled-components';
import c from '../../config/config';

const A = {
    FadeInUp: keyframes`
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  `,
    FadeInDown: keyframes`
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  `,
};
const S = {
    Container: styled.div`
        width: 100%;
        height: 100%;
        font-size: 1.4rem;
        overflow-y: auto;
        padding: 0;

        background: url(${({bgImg}) => bgImg});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .background-video-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .btn-transparent {
            background-color: ${({ meetingType }) => (meetingType === 'brmeet' ? '#0095F5' : '#683A92')};
            transition: background-color 0.25s;

            &:hover {
                background-color: ${({ meetingType }) => (meetingType === 'brmeet' ? '#27a4f7' : '#683A92')};
            }

            &:active {
                background-color: ${({ meetingType }) => (meetingType === 'brmeet' ? '#0095F5' : '#683A92')};
            }
        }

        .left {
            z-index: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 350px;
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding: 0 0 8rem 8rem;

            .img-wrap {
                width: 250px;

                img {
                    width: 100%;
                }
            }

            .desc {
                color: silver;
                font-size: 1.6rem;
            }
        }

        .right {
            z-index: 1;
            height: 100%;

            .cover {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                min-height: 100%;
                max-width: 750px;
                margin-left: auto;
                background: rgba(0, 0, 0, 0.6);

                .row.preview-video {
                    padding: 5rem 5rem 0 5rem;
                    margin-bottom: 3rem;

                    video {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scaleX(1);
                    }

                    .btn-join,
                    .btn-back,
                    .txt-permission-denied {
                        display: none;
                    }
                }

                .row.info {
                    flex: 1;
                    padding-bottom: 5rem;
                }

                .row.join,
                .row.back {
                    margin-top: auto;
                    padding: 0 5rem 5rem 5rem;
                }

                .preview-video-wrap {
                    width: 100%;
                    aspect-ratio: 16 / 9;
                    border-radius: 1rem;
                    overflow: hidden;
                    isolation: isolate;
                    position: relative;
                    background-image: url(${({ logoImg }) => logoImg});
                    background-size: 25%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: #706c8961;
                    margin-bottom: 1.5rem;

                    video {
                        width: 100%;
                    }

                    .device-control-wrap {
                        left: 0;
                        bottom: 5%;
                        width: 100%;
                        //height: 45px;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        gap: 1.5rem;

                        .btn-wrap {
                            width: 45px;
                            height: 45px;
                        }
                    }
                }

                .tab {
                    .info-group {
                        display: none;
                        padding: 0 5rem;
                        width: 100%;

                        .ipt-group {
                            margin-bottom: 2.5rem;

                            .label {
                                color: silver;
                                margin-left: 0.5rem;
                                margin-bottom: 0.5rem;
                            }

                            .ipt-transparent {
                                background: #706c8961;
                                border: none;
                                outline: none;
                                color: #dcdddf;
                                width: 100%;
                                font-size: 1.6rem;
                                padding: 0 1.5rem;
                                line-height: 4.5rem;
                                border-radius: 1rem;

                                &:disabled {
                                    color: #dcdddf;
                                }

                                &:read-only {
                                    color: #dcdddf;
                                }

                                -webkit-text-fill-color: inherit;
                                opacity: 1;
                            }
                        }
                    }

                    .setting-group {
                        display: none;
                        padding: 0 5rem;
                        width: 100%;

                        .label {
                            color: silver;
                            margin-bottom: 0.7rem;
                        }

                        .sel-group {
                            margin-bottom: 3rem;
                        }
                    }

                    &.active-info-group .info-group {
                        display: block;
                        animation: ${A.FadeInUp} 0.4s;
                    }

                    &.active-setting-group .setting-group {
                        display: block;
                        animation: ${A.FadeInUp} 0.4s;
                    }
                }
            }
        }

        .txt-permission-denied {
            color: var(--color-light-danger);
            padding: 0 0 0.5rem 0.5rem;
        }

        // 태블릿 가로
        @media screen and (max-width: ${c.bp.tw.maxWidth}px) and (orientation: ${c.bp.tw.orientation}) {
            .right {
                .cover {
                    max-width: 100%;
                    flex-direction: row;
                    align-items: center;

                    .row.preview-video {
                        padding: 0 3rem;
                        margin: 0;
                        flex: 0.5;

                        .btn-join,
                        .btn-back,
                        .txt-permission-denied {
                            display: flex;
                        }
                    }

                    .row.info {
                        flex: 0.5;
                        padding-bottom: 0;

                        .tab {
                            .info-group {
                                padding: 0 3rem;
                            }

                            .setting-group {
                                padding: 0 3rem;

                                .sel-group {
                                    margin-bottom: 2rem;
                                }
                            }
                        }
                    }

                    .row.join,
                    .row.back {
                        display: none;
                    }
                }
            }
        }

        // 태블릿 세로
        @media all and (max-width: ${c.bp.th.maxWidth}px) and (orientation: ${c.bp.th.orientation}) {
            .right {
                .cover {
                    max-width: 100%;
                }
            }
        }

        // 모바일 가로모드
        @media screen and (max-width: ${c.bp.mw.maxWidth}px) and (max-height: ${c.bp.mw.maxHeight}px) {
            .right {
                .cover {
                    .row.preview-video {
                        padding: 0 2rem;
                        margin-bottom: 1.5rem;
                    }

                    .row.info {
                        padding-bottom: 0;

                        .tab {
                            .info-group {
                                padding: 0 2rem;
                            }

                            .setting-group {
                                padding: 0 2rem;

                                .sel-group {
                                    margin-bottom: 1.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        // 모바일 세로모드
        @media all and (max-width: ${c.bp.mh.maxWidth}px) and (orientation: ${c.bp.mh.orientation}) {
            .right {
                .cover {
                    .row.preview-video {
                        padding: 2rem 2rem 0 2rem;
                        margin-bottom: 1.5rem;
                    }

                    .row.info {
                        padding-bottom: 2rem;

                        .tab {
                            .info-group {
                                padding: 0 2rem;
                            }

                            .setting-group {
                                padding: 0 2rem;

                                .sel-group {
                                    margin-bottom: 1.5rem;
                                }
                            }
                        }
                    }

                    .row.join,
                    .row.back {
                        padding: 0 2rem 2rem 2rem;
                    }
                }
            }
        }

        // 터치스크린
        @media all and (pointer: coarse) {
            .btn-spk-wrap {
                display: none;
            }
        }

        // 유저로딩 돌돌이
        .loader {
            color: #ffffff;
            font-size: 35px;
            text-indent: -9999em;
            overflow: hidden;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            position: relative;
            transform: translateZ(0);
            animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
        }

        @keyframes mltShdSpin {
            0% {
                box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
            }
            5%,
            95% {
                box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
            }
            10%,
            59% {
                box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
            }
            20% {
                box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
            }
            38% {
                box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
            }
            100% {
                box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
            }
        }

        @keyframes round {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    `,
};

export default S;
