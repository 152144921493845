import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const S = {
    Container: styled.div`
        font-family: Inter;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: auto;
        background-color: black;
        background-image: url(https://wbn.br-idge.kr/img/exec-bg.jpg);
        background-size: cover;
        background-position: center 77%;
        background-repeat: no-repeat;
        &:before {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.5;
        }

        & > div {
            font-size: 3rem;
            margin: auto;
            text-align: center;
            z-index: 2;
            & > div {
                white-space: pre-line;
            }
            .logo-wrap {
                width: 18rem;
                margin: 0 auto 3.5rem auto;
                img {
                    width: 100%;
                }
            }
            .title {
                font-size: 3.7rem;
                line-height: 1.2;
                margin-bottom: 1rem;
                font-weight: 600;
            }

            .desc {
                font-size: 2.4rem;
                font-weight: 400;
                margin-bottom: 1rem;
            }
        }


        /* 태블릿 가로 */
        @media (max-width: 1366px) and (orientation: landscape) {
            & > div .logo-wrap {
                width: 14rem;
            }
            & > div .title {
                font-size: 3rem;
            }
            & > div .desc {
                font-size: 2.2rem;
            }
        }
        /* 태블릿 세로 */
        @media (max-width: 1024px) and (orientation: portrait) {
            & > div .logo-wrap {
                width: 14rem;
            }
            & > div .title {
                font-size: 3rem;
            }
            & > div .desc {
                font-size: 2.2rem;
            }
        }
        /* 모바일 가로 */
        @media (max-width: 920px) and (max-height: 500px) and (orientation: landscape) {
            & > div .logo-wrap {
                width: 12rem;
                margin-bottom: 2.5rem;
            }
            & > div .title {
                font-size: 2.5rem;
            }
            & > div .desc {
                font-size: 1.9rem;
            }
        }
        /* 모바일 세로 */
        @media (max-width: 500px) and (orientation: portrait) {
            & > div .logo-wrap {
                width: 12rem;
                margin-bottom: 2.5rem;
            }
            & > div .title {
                font-size: 2.5rem;
            }
            & > div .desc {
                font-size: 1.9rem;
            }
        }
    `,
};
export default function ErrorPage(error) {
    let { errorType } = useParams();

    const data = {
        'exceeded-attendee': {
            title: '회의 접속인원이 초과되어\n입장이 불가능합니다.',
            desc: '양해 부탁드립니다.',
        },
        'already-attend': {
            title: '이미 다른 회의에 참석중입니다.',
            desc: '양해 부탁드립니다.',
        },
        'user-not-found': {title: '미팅 또는 회원정보를\n찾을 수 없습니다.'},
        'force-exit': {title: '회의에서 강제 퇴장 당하셨습니다.'},
    };
    return (
        <S.Container>
            <div>
                <div class="logo-wrap">
                    <img src="https://wbn.br-idge.kr/img/logo-new.png" alt="" />
                </div>
                {data[errorType] ? (
                    <div>
                        {data[errorType].title && <div className="title">{data[errorType].title}</div>}
                        {data[errorType].desc && <div className="desc">{data[errorType].desc}</div>}
                    </div>
                ) : (
                    <div>
                        <div className="title">알 수 없는 오류가 발생했습니다.</div>
                    </div>
                )}
            </div>
        </S.Container>
    );
}
