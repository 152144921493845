import React from 'react';
import {getItems, shallow, useStore} from "../../zustand/store";
import IconBtn from "../Components/IconBtn";
import tool from '../../util/tools';

function BtnContentShare({label}) {
    const items = ['viewMode', 'userActions']
    const z = useStore(getItems(items), shallow)

    const toggleFullScreen = () => {
        if (z.viewMode === 'fullscreen') {
            tool.getIsMobile() ?
                z.userActions.setViewMode('content-m') :
                z.userActions.setViewMode('content-h')
        } else {
            z.userActions.setViewMode('fullscreen')
        }
    }
    return (
        <IconBtn icon='fullscreen'
                 theme="secondary"
                 isOn={z.viewMode === 'fullscreen'}
                 onType={2}
                 onTheme="secondary"
                 onClick={toggleFullScreen}
                 label={label}
                 action={true}
        />
    );
}

export default BtnContentShare;