/* eslint-disable */
import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import {getItems, shallow, useStore} from "../../zustand/store";
import styled from 'styled-components';

const S = {
  IndicatorRecord: styled.div`
    width: 5px;
    height: 5px;
  `
}
function RunningTime(props) {
  const items = ['createDt', 'isOnRecord'];
  const z = useStore(getItems(items), shallow)
  const [runningTime, setRunningTime] = useState('00:00');
  useEffect(() => {
    if(z.createDt) {
      let createDt = z.createDt;
      let interval = setInterval(() => {
        let a = dayjs().diff(dayjs(createDt), 's');
        let h = String(Math.floor(a / 60)).padStart(2, '0');
        let s = String(a % 60).padStart(2, '0');
        setRunningTime(`${h}:${s}`)
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [z.createDt])
  return (
    <div className="runningtime">
      {!!z.isOnRecord && <div className="indicator-record"></div>}
      {runningTime}
    </div>
  );
}

export default RunningTime;