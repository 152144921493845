import { createContext, useContext, useState } from 'react';
import Socket from '../util/Socket';
import { getItems, shallow, useStore } from '../zustand/store';
import io from 'socket.io-client';

const SocketContext = createContext();

function SocketProvider({ meetingType, children }) {
    const items = ['meetingIdx', 'infoTy', 'uuid', 'userActions', 'usersActions', 'meetingActions'];
    const z = useStore(getItems(items), shallow);
    let data = {
        r: meetingType === 'webinar' ? z.meetingIdx : `${meetingType}:${z.meetingIdx}`,
    };
    const socketUrl = z.infoTy < 3 ?
        `${process.env.REACT_APP_MEETING_SOCKET_URL}?r=${data.r}&u=${z.uuid}&pl=d` :
        `${process.env.REACT_APP_MEETING_SOCKET_URL}?r=${data.r}&u=${z.uuid}&pl=u&viewer=true`;
    const [socket, setSocket] = useState(
        new Socket(
            socketUrl,
            z.userActions,
            z.usersActions,
            z.meetingActions
        )
    );

    const controlSocketUrl =
        z.infoTy < 3
            ? `${process.env.REACT_APP_CONTROL_SOCKET_URL}?r=${data.r}&u=${z.uuid}&pl=d`
            : `${process.env.REACT_APP_CONTROL_SOCKET_URL}?r=${data.r}&u=${z.uuid}&pl=u`;
    const [controlSocket, setControlSocket] = useState(io.connect(controlSocketUrl, { transports: ['websocket'] }));

    let value = { socket, controlSocket }
    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
}

function useSocket() {
    const value = useContext(SocketContext);
    if (!value) throw new Error('SocketProvider 와 함게 사용되지 않았습니다');
    return value.socket;
}

function useControlSocket() {
    const value = useContext(SocketContext);
    if (!value) throw new Error('SocketProvider 와 함게 사용되지 않았습니다');
    return value.controlSocket;
}

export { SocketProvider, useSocket, useControlSocket };
