import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import Meeting from './pages/Meeting/Meeting';
import { CookiesProvider } from 'react-cookie';
import React from 'react';
import ErrorPage from './pages/error/ErrorPage';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
    return (
        <CookiesProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/meeting" element={<Meeting meetingType={'webinar'} />} />
                    <Route path="/login/:meetingIdx/:uuid" element={<Login meetingType={'webinar'} />} />
                    <Route path="/player/:meetingIdx/:uuid" element={<Login meetingType={'webinar'} />} />
                    
                    <Route path="/brmeet/meeting" element={<Meeting meetingType={'brmeet'} />} />
                    <Route path="/brmeet/login/:meetingIdx/:uuid" element={<Login meetingType={'brmeet'} />} />
                    <Route path="/brmeet/login/:publicCode" element={<Login meetingType={'brmeet'} isPublicLink={true} />} />

                    <Route path="/brmeet-b/meeting" element={<Meeting meetingType={'brmeet-b'} />} />
                    <Route path="/brmeet-b/login/:meetingIdx/:uuid" element={<Login meetingType={'brmeet-b'} />} />
                    <Route path="/brmeet-b/login/:publicCode" element={<Login meetingType={'brmeet-b'} isPublicLink={true} />} />
                    
                    <Route path="/error/:errorType" element={<ErrorPage />} />
                </Routes>
                <ToastContainer 
                    style={{fontSize: '1.4rem'}}
                    pauseOnFocusLoss={false}
                    hideProgressBar={true}
                    autoClose={2000}
                    pauseOnHover={true}
                    position='top-right'
                    theme='colored'
                    transition={Flip}
                />
            </BrowserRouter>
        </CookiesProvider>
    );
}

export default App;
