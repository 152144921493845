/* eslint-disable */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import VideoGroup from './VideoGroup';
import { shallow, useStore, getItems } from '../../zustand/store';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { Navigation, Mousewheel, Pagination, FreeMode } from 'swiper';
import { useMeetingSession } from '../../Contexts/MeetingSessionContext';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/mousewheel';
import { VideoSource, Attendee } from 'amazon-chime-sdk-js';
import S from './Content.styled';
import Transition from 'react-transition-group/Transition';
import tool from '../../util/tools';
import config from '../../config/config';

SwiperCore.use([Mousewheel, FreeMode, Pagination, Navigation]);

function UserList({ meetingSession, status, toggleUserList }) {
    const items = [
        'meetingSession',
        'changeNicknameMap',
        'unFixUserList',
        'viewMode',
        'userListSwiperIdx',
        'userDevice',
        'activeUnFixUserList',
        'userActions',
        'usersActions',
    ];
    const z = useStore(getItems(items), shallow);
    const [slidePerView, setSlidePerView] = useState(3);
    const [totalSlides, setTotalSlides] = useState(1);
    const swiperRef = useRef(null);

    const getSortUserList = () => {
        const contentShareEl = document.querySelector('.vg-content');
        let contentShareUUID;
        if (contentShareEl) contentShareUUID = document.querySelector('.vg-content').classList[4].replace('vg-', '').split('#')[0];
        let _unFixUserList = [...z.unFixUserList];
        let top = [];
        let bottom = [];
        _unFixUserList.forEach((x) => {
            if (x.uuid === contentShareUUID || x.isOnActivate) top.push(x);
            else bottom.push(x);
        });
        top.sort((a, b) => {
            if (a.uuid === contentShareUUID) return -1;
        });
        bottom.sort((a, b) => {
            a = z.changeNicknameMap[a.uuid] || a.nickname;
            b = z.changeNicknameMap[b.uuid] || b.nickname;
            return a.localeCompare(b);
        });
        return top.concat(bottom);
    };

    const unFixUserList = getSortUserList();
    const getSlidePerView = () => {
        let height = z.userDevice.includes('tablet') ? 105 : 125;
        return Math.floor(Math.max(window.innerHeight - 270, height) / height);
    };

    useEffect(() => {
        setSlidePerView(getSlidePerView());
    }, []);

    // 화면 사이즈 변경 시 화면에 노출해줄 비디오 수 조정
    useEffect(() => {
        const handleResize = () => {
            setTimeout(() => {
                if (!swiperRef.current?.swiper) return;
                setTotalSlides(swiperRef.current.swiper.slides.length);
            }, 100);

            if (z.viewMode === 'tile') return;
            setSlidePerView(getSlidePerView());
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [swiperRef, z.userDevice, z.unFixUserList.length, slidePerView]);

    useEffect(() => {
        if (!swiperRef.current?.swiper) return;
        const swiper = swiperRef.current.swiper;
        swiper.slideTo(z.userListSwiperIdx);
    }, [swiperRef, z.userListSwiperIdx]);

    useEffect(() => {
        if (!swiperRef.current?.swiper) return;
        const swiper = swiperRef.current.swiper;
        const activeSlide = swiper.slides[swiper.activeIndex];
        const activeUnFixUserList = [];
        if (activeSlide)
            activeSlide.querySelectorAll('.video-group.play').forEach((x) => {
                activeUnFixUserList.push(x.dataset.attendeeId);
            });
        z.userActions.setActiveUnFixUserList(activeUnFixUserList);
    }, [swiperRef, z.userListSwiperIdx, z.unFixUserList, z.viewMode, slidePerView]);

    return (
        <div className={`user-list-wrap ${status}`} style={{ display: z.viewMode !== 'tile' && z.unFixUserList.length ? 'flex' : 'none' }}>
            <Swiper
                ref={swiperRef}
                direction={z.viewMode.indexOf('-h') > -1 || z.viewMode === 'fullscreen' ? 'vertical' : 'horizontal'}
                className="user-list h-100"
                slidesPerView={1}
                observeParents={true}
                observer={true}
                autoHeight={true}
                speed={0}
                touchRatio={0}
            >
                {unFixUserList.length > 0 &&
                    tool.chunkArray(z.unFixUserList, slidePerView).map((x, i) => {
                        return (
                            <SwiperSlide key={i} data-idx={i}>
                                {x.map((y) => (
                                    <VideoGroup
                                        key={y.uuid}
                                        sid={y.sid}
                                        tileId={y.tileId}
                                        infoTy={y.infoTy}
                                        uuid={y.uuid}
                                        attendeeId={y.attendeeId}
                                        nickname={z.changeNicknameMap[y.uuid.replace('#content', '')] || `${y.nickname} | ${y.org1}`}
                                        isOnFix={y.isOnFix}
                                        isOnCam={y.isOnCam}
                                        isOnMic={y.isOnMic}
                                    />
                                ))}
                            </SwiperSlide>
                        );
                    })}
                {!z.userDevice.includes('mobile') && (
                    <div className="user-list-navigator">
                        <div
                            className={`btn btn-secondary btn-user-list-prev arrow ${z.userListSwiperIdx === 0 ? 'swiper-button-disabled' : ''}`}
                            onClick={() => {
                                z.userActions.setUserListSwiperIdx(Math.max(z.userListSwiperIdx - 1, 0));
                            }}
                        ></div>
                        <div
                            className="btn btn-secondary btn-user-list-start arrow2"
                            onClick={() => {
                                z.userActions.setUserListSwiperIdx(0);
                            }}
                        ></div>
                        <div className="user-list-page">
                            {z.userListSwiperIdx + 1} / {totalSlides}
                        </div>
                        <div
                            className={`btn btn-secondary btn-user-list-next arrow ${z.userListSwiperIdx === totalSlides - 1 ? 'swiper-button-disabled' : ''}`}
                            onClick={() => {
                                z.userActions.setUserListSwiperIdx(Math.min(z.userListSwiperIdx + 1, totalSlides - 1));
                            }}
                        ></div>
                        <div
                            className="btn btn-secondary btn-user-list-end arrow2"
                            onClick={() => {
                                z.userActions.setUserListSwiperIdx(totalSlides - 1);
                            }}
                        ></div>
                    </div>
                )}
            </Swiper>

            <div className={`btn btn-secondary btn-toggle-user-list ${status} action`} onClick={toggleUserList}></div>
        </div>
    );
}

function SpotlightList({ meetingSession, onUserList }) {
    const swiperRef = useRef(null);
    const spotlightWrapRef = useRef(null);
    const spotlightRef = useRef(null);
    const items = ['changeNicknameMap', 'fixUserList', 'spotlightListSwiperIdx', 'userDevice', 'viewMode', 'activeFixUserList', 'userActions', 'usersActions'];
    const z = useStore(getItems(items), shallow);
    const [slidePerView, setSlidePerView] = useState(3);
    const [totalSlides, setTotalSlides] = useState(0);

    useEffect(() => {
        console.log('######3 :', z.userDevice);
        if (z.userDevice === 'mobileW') setSlidePerView(4);
        else if (z.userDevice === 'mobileH') setSlidePerView(3);
        else if (z.userDevice === 'tabletW' || z.userDevice === 'tabletH') setSlidePerView(12);
        else setSlidePerView(20);
    }, [z.userDevice]);

    // 토탈 페이지 수 셋팅
    useEffect(() => {
        let tot = Math.ceil(z.fixUserList.length / slidePerView);
        if (z.spotlightListSwiperIdx + 1 > tot) {
            z.userActions.setSpotlightSwiperIdx(Math.max(0, tot - 1));
        }
        setTotalSlides(tot);
    }, [z.spotlightListSwiperIdx, z.fixUserList.length, slidePerView]);

    // spotlightListSwiperIdx의 변경에 따른 스와이퍼 페이지 변경
    useEffect(() => {
        if (!swiperRef.current?.swiper) return;
        const swiper = swiperRef.current.swiper;
        swiper.slideTo(z.spotlightListSwiperIdx);
    }, [swiperRef, z.spotlightListSwiperIdx]);

    // 현재 활성화된 슬라이드의 비디오만 activeFixUserList에 담음
    useEffect(() => {
        if (!swiperRef.current?.swiper) return;
        const swiper = swiperRef.current.swiper;
        const activeSlide = swiper.slides[swiper.activeIndex];
        const activeFixUserList = [];
        if (activeSlide)
            activeSlide.querySelectorAll('.video-group.play').forEach((x) => {
                activeFixUserList.push(x.dataset.attendeeId);
            });
        z.userActions.setActiveFixUserList(activeFixUserList);
    }, [swiperRef, z.spotlightListSwiperIdx, z.fixUserList, z.viewMode, slidePerView]);

    return (
        <div className="spotlight-wrap" ref={spotlightWrapRef}>
            <Swiper className="spotlight-list w-100 h-100" slidesPerView={1} observeParents={true} observer={true} speed={0} ref={swiperRef} touchRatio={0}>
                {/* [데스크탑, 태블릿] 타일, 화면공유, 화자, 스포트라이트 뷰 */}
                {z.viewMode !== 'content-m' &&
                    z.fixUserList.length > 0 &&
                    tool.chunkArray(z.fixUserList, slidePerView).map((x, i) => (
                        <SwiperSlide key={i} data-idx={i} data-ssss="1">
                            <div className={`spotlight fix-${Math.min(Math.max(z.fixUserList.length, 1), slidePerView)}`} ref={spotlightRef}>
                                {x.map((y, j) => (
                                    <VideoGroup
                                        key={y.uuid}
                                        sid={y.sid}
                                        tileId={y.tileId}
                                        infoTy={y.infoTy}
                                        uuid={y.uuid}
                                        attendeeId={y.attendeeId}
                                        nickname={z.changeNicknameMap[y.uuid.replace('#content', '')] || `${y.nickname} | ${y.org1}`}
                                        isOnFix={y.isOnFix}
                                        isOnCam={y.isOnCam}
                                        isOnMic={y.isOnMic}
                                    />
                                ))}
                            </div>
                        </SwiperSlide>
                    ))}

                {/* [모바일 가로] 화면공유 */}
                {z.viewMode === 'content-m' && z.userDevice === 'mobileW' && z.fixUserList[0] && (
                    <SwiperSlide data-idx="0" data-ssss="2">
                        <div className={`spotlight fix-1`}>
                            <VideoGroup
                                key={z.fixUserList[0].uuid}
                                sid={z.fixUserList[0].sid}
                                tileId={z.fixUserList[0].tileId}
                                infoTy={z.fixUserList[0].infoTy}
                                uuid={z.fixUserList[0].uuid}
                                attendeeId={z.fixUserList[0].attendeeId}
                                nickname={
                                    z.changeNicknameMap[z.fixUserList[0].uuid.replace('#content', '')] ||
                                    `${z.fixUserList[0].nickname} | ${z.fixUserList[0].org1}`
                                }
                                isOnFix={z.fixUserList[0].isOnFix}
                                isOnCam={z.fixUserList[0].isOnCam}
                                isOnMic={z.fixUserList[0].isOnMic}
                            />
                        </div>
                    </SwiperSlide>
                )}
                {z.viewMode === 'content-m' &&
                    z.userDevice === 'mobileW' &&
                    tool.chunkArray(z.fixUserList.slice(1), slidePerView).map((x, i) => (
                        <SwiperSlide key={i} data-idx={i + 1} data-ssss="2">
                            <div className={`spotlight fix-${Math.min(Math.max(z.fixUserList.slice(1).length, 1), slidePerView)}`} ref={spotlightRef}>
                                {x.map((y, j) => (
                                    <VideoGroup
                                        key={y.uuid}
                                        sid={y.sid}
                                        tileId={y.tileId}
                                        infoTy={y.infoTy}
                                        uuid={y.uuid}
                                        attendeeId={y.attendeeId}
                                        nickname={z.changeNicknameMap[y.uuid.replace('#content', '')] || `${y.nickname} | ${y.org1}`}
                                        isOnFix={y.isOnFix}
                                        isOnCam={y.isOnCam}
                                        isOnMic={y.isOnMic}
                                    />
                                ))}
                            </div>
                        </SwiperSlide>
                    ))}

                {/* [모바일 세로] 화면공유 */}
                {z.viewMode === 'content-m' && z.userDevice === 'mobileH' && (
                    <SwiperSlide data-idx="0" data-ssss="3">
                        <div className={`spotlight fix-2`} ref={spotlightRef}>
                            {z.fixUserList.slice(0, 2).map((x, i) => (
                                <VideoGroup
                                    key={x.uuid}
                                    sid={x.sid}
                                    tileId={x.tileId}
                                    infoTy={x.infoTy}
                                    uuid={x.uuid}
                                    attendeeId={x.attendeeId}
                                    nickname={z.changeNicknameMap[x.uuid.replace('#content', '')] || `${x.nickname} | ${x.org1}`}
                                    isOnFix={x.isOnFix}
                                    isOnCam={x.isOnCam}
                                    isOnMic={x.isOnMic}
                                />
                            ))}
                        </div>
                    </SwiperSlide>
                )}
                {z.viewMode === 'content-m' &&
                    z.userDevice === 'mobileH' &&
                    tool.chunkArray(z.fixUserList.slice(2), slidePerView).map((x, i) => (
                        <SwiperSlide key={i} data-idx={i + 1} data-ssss="3">
                            <div className={`spotlight fix-${Math.min(Math.max(z.fixUserList.slice(2).length, 1), slidePerView)}`} ref={spotlightRef}>
                                {x.map((y, j) => (
                                    <VideoGroup
                                        key={y.uuid}
                                        sid={y.sid}
                                        tileId={y.tileId}
                                        infoTy={y.infoTy}
                                        uuid={y.uuid}
                                        attendeeId={y.attendeeId}
                                        nickname={z.changeNicknameMap[y.uuid.replace('#content', '')] || `${y.nickname} | ${y.org1}`}
                                        isOnFix={y.isOnFix}
                                        isOnCam={y.isOnCam}
                                        isOnMic={y.isOnMic}
                                    />
                                ))}
                            </div>
                        </SwiperSlide>
                    ))}
            </Swiper>

            {!z.userDevice.includes('mobile') && z.viewMode !== 'content-h' && (
                <Fragment>
                    <div
                        className={`swiper-button-prev ${Math.min(totalSlides, z.spotlightListSwiperIdx) === 0 ? 'swiper-button-disabled' : ''}`}
                        onClick={() => {
                            z.userActions.setSpotlightSwiperIdx(Math.max(z.spotlightListSwiperIdx - 1, 0));
                        }}
                    ></div>
                    <div
                        className={`swiper-button-next ${z.spotlightListSwiperIdx + 1 < totalSlides ? '' : 'swiper-button-disabled'}`}
                        onClick={() => {
                            z.userActions.setSpotlightSwiperIdx(Math.min(z.spotlightListSwiperIdx + 1, totalSlides - 1));
                        }}
                    ></div>
                </Fragment>
            )}

            {z.userDevice.includes('mobile') && !z.viewMode.includes('activator') && totalSlides > 0 && (
                <div className="spotlight-list-navigator">
                    <div
                        className={`btn action btn-secondary btn-spotlight-list-prev arrow ${z.spotlightListSwiperIdx === 0 ? 'swiper-button-disabled' : ''}`}
                        onClick={() => {
                            z.userActions.setSpotlightSwiperIdx(Math.max(z.spotlightListSwiperIdx - 1, 0));
                        }}
                    ></div>
                    <div
                        className={`btn action btn-secondary btn-spotlight-list-start arrow2 ${z.spotlightListSwiperIdx === 0 ? 'swiper-button-disabled' : ''}`}
                        onClick={() => {
                            z.userActions.setSpotlightSwiperIdx(0);
                        }}
                    ></div>
                    <div className="spotlight-list-page">
                        {z.spotlightListSwiperIdx + 1} / {totalSlides}
                    </div>
                    <div
                        className={`btn action btn-secondary btn-spotlight-list-next arrow ${
                            z.spotlightListSwiperIdx + 1 === totalSlides ? 'swiper-button-disabled' : ''
                        }`}
                        onClick={() => {
                            z.userActions.setSpotlightSwiperIdx(Math.min(z.spotlightListSwiperIdx + 1, totalSlides));
                        }}
                    ></div>
                    <div
                        className={`btn action btn-secondary btn-spotlight-list-end arrow2 ${
                            z.spotlightListSwiperIdx + 1 === totalSlides ? 'swiper-button-disabled' : ''
                        }`}
                        onClick={() => {
                            z.userActions.setSpotlightSwiperIdx(totalSlides - 1);
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
}

function Fullscreen() {
    const items = ['changeNicknameMap', 'users', 'viewMode', 'userActions', 'usersActions'];
    const z = useStore(getItems(items), shallow);

    return (
        <div className="fullscreen-wrap">
            {z.users &&
                Object.keys(z.users).map((x, i) => {
                    return (
                        z.users[x].isContentShare && (
                            <VideoGroup
                                key={z.users[x].uuid}
                                sid={z.users[x].sid}
                                tileId={z.users[x].tileId}
                                uuid={z.users[x].uuid}
                                attendeeId={z.users[x].attendeeId}
                                nickname={z.changeNicknameMap[z.users[x].uuid.replace('#content', '')] || `${z.users[x].nickname} | ${z.users[x].org1}`}
                                isOnFix={z.users[x].isOnFix}
                                isOnCam={z.users[x].isOnCam}
                                isOnMic={z.users[x].isOnMic}
                            />
                        )
                    );
                })}
        </div>
    );
}

function Content() {
    const items = [
        'userDevice',
        'viewMode',
        'uuid',
        'users',
        'changeNicknameMap',
        'isOnHideNotUseCam',
        'activeUnFixUserList',
        'activeFixUserList',
        'userActions',
        'usersActions',
    ];
    const z = useStore(getItems(items), shallow);
    const meetingSession = useMeetingSession();
    const [onUserList, setOnUserList] = useState(true);

    // 아무곳이나 클릭했을 시 더보기 닫기 / 외부 클릭 시 drawer 닫기
    const handleClickOutside = (e) => {
        const set = document.querySelector('.set.open');
        if (set) {
            if (!set.contains(e.target)) {
                set.classList.remove('open');
                set.classList.add('close');
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // 유저목록, 뷰모드 변경 시 fixUserList, unFixUserList 셋팅
    useEffect(() => {
        let _fixUserList = []; // 스포트라이트뷰에 뿌려질 리스트
        let _unFixUserList = []; // 유저리스트에 뿌려질 리스트
        let _contentShareUsers = []; // 화면공유자 예외 리스트
        let _fixUserList2 = []; // 고정된 유저

        let isContentShare = false;
        let contentShareUserUUID = null;
        if (z.viewMode.includes('content')) {
            isContentShare = true;
            contentShareUserUUID = Object.keys(z.users)
                .find((x) => z.users[x].isContentShare)
                .split('#')[0];
        }

        Object.keys(z.users).forEach((x, i) => {
            let user = { ...z.users[x] };

            if (z.isOnHideNotUseCam) {
                if (!user.isOnCam) return _unFixUserList.push(user);
            }

            if (z.viewMode.includes('spotlight')) {
                user.isOnFix ? _fixUserList.push(user) : _unFixUserList.push(user);
            } else if (z.viewMode.includes('activator')) {
                user.isOnActivate ? _fixUserList.push(user) : _unFixUserList.push(user);
            } else if (z.viewMode.includes('content')) {
                if (z.userDevice === 'mobileW') {
                    // 화면공유 모바일 가로
                    if (user.uuid == contentShareUserUUID) _unFixUserList.push(user);
                    else if (user.isContentShare) _fixUserList.unshift(user);
                    else _fixUserList.push(user);
                } else if (z.userDevice === 'mobileH') {
                    // 화면공유 모바일 세로
                    if (user.isContentShare) _contentShareUsers[0] = user;
                    else if (user.uuid == contentShareUserUUID) _contentShareUsers[1] = user;
                    else _fixUserList.push(user);
                } else {
                    // 화면공유 데스크탑
                    if (user.isContentShare) _contentShareUsers[0] = user;
                    else if (user.uuid == contentShareUserUUID) _contentShareUsers[1] = user;
                    else _unFixUserList.push(user);
                }
            } else if (z.viewMode.includes('tile')) {
                if (user.isContentShare) _contentShareUsers[0] = user;
                else if (user.uuid === contentShareUserUUID) _contentShareUsers[1] = user;
                else _fixUserList.push(user);
            }
        }, []);

        if (z.viewMode.includes('content')) {
            if (z.userDevice === 'desktop') {
                _fixUserList.unshift(_contentShareUsers[0]);
                _unFixUserList.unshift(_contentShareUsers[1]);
            }
            if (z.userDevice === 'mobileH') {
                _fixUserList.unshift(..._contentShareUsers);
            }
        }

        if (z.viewMode.includes('tile')) {
            let onCamList = [];
            let offCamList = [];

            _fixUserList.forEach((x) => {
                if (x.isOnCam) onCamList.push(x);
                else offCamList.push(x);
            });

            onCamList.sort((a, b) => {
                a = z.changeNicknameMap[a.uuid] || a.nickname || '이름없음';
                b = z.changeNicknameMap[b.uuid] || b.nickname || '이름없음';
                return a.localeCompare(b);
            });

            offCamList.sort((a, b) => {
                a = z.changeNicknameMap[a.uuid] || a.nickname;
                b = z.changeNicknameMap[b.uuid] || b.nickname;
                return a.localeCompare(b);
            });

            _fixUserList = onCamList.concat(offCamList);
            _fixUserList.unshift(..._contentShareUsers);
        }

        z.userActions.setFixUserList(_fixUserList);
        z.userActions.setUnFixUserList(_unFixUserList);
    }, [z.users, z.userDevice, z.isOnHideNotUseCam, z.viewMode]);

    useEffect(() => {
        if (!meetingSession) return;
        const videoSources = [];
        if (z.viewMode === 'fullscreen') {
            let videoSource = new VideoSource();
            let attendee = new Attendee();
            attendee.attendeeId = document.querySelector('.vg-content').dataset.attendeeId;
            videoSource.attendee = attendee;
            videoSources.push(videoSource);
        }
        [...z.activeFixUserList, ...z.activeUnFixUserList].forEach((x) => {
            let videoSource = new VideoSource();
            let attendee = new Attendee();
            attendee.attendeeId = x;
            videoSource.attendee = attendee;
            videoSources.push(videoSource);
        });
        meetingSession.configuration.videoDownlinkBandwidthPolicy.chooseRemoteVideoSources(videoSources);
    }, [meetingSession, z.activeUnFixUserList, z.activeFixUserList, z.viewMode]);

    // Functions
    const toggleUserList = () => {
        setOnUserList(!onUserList);
    };

    const addUser = () => {
        let tempUser = { ...z.users[z.uuid] };
        tempUser.attendeeId += String(Object.keys(z.users).length).padStart(2, '0');
        tempUser.uuid += String(Object.keys(z.users).length).padStart(2, '0');
        tempUser.nickname += String(Object.keys(z.users).length).padStart(2, '0');
        tempUser.sid += String(Object.keys(z.users).length).padStart(2, '0');
        tempUser.isOnCam = false;
        tempUser.tileId = null;
        z.usersActions.addUser(tempUser);
    };

    return (
        <S.Content className={`content ${z.viewMode} ${z.isOnHideNotUseCam ? 'display-only-cam' : 'display-all'}`} data-view-mode={z.viewMode}>
            <>
                {(!z.userDevice.includes('mobile') || z.viewMode === 'content-m') && (
                    <Transition in={onUserList} timeout={500}>
                        {(status) => <UserList meetingSession={meetingSession} status={status} toggleUserList={toggleUserList} />}
                    </Transition>
                )}

                <SpotlightList meetingSession={meetingSession} onUserList={onUserList}></SpotlightList>

                {['fullscreen'].includes(z.viewMode) && <Fullscreen />}
            </>

            {/* 인원수 늘리기 버튼 */}
            <div
                style={{
                    position: 'fixed',
                    top: '60px',
                    left: 0,
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                    zIndex: 4,
                    border: '1px solid red',
                    display: process.env.REACT_APP_ENV === 'production' ? 'none' : 'block',
                }}
                onClick={addUser}
            ></div>
        </S.Content>
    );
}

export default Content;
