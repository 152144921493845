import React from 'react';
import styled from "styled-components";

const S = {
  Btn: styled.button`
    transition: .15s background;
    color: white;
    border: none;
    border-radius: .5rem;
    width: 100%;
    height: 100%;
    padding: .75rem 1rem;

    &.primary {
      background: var(--color-primary);

      &:hover {
        background: var(--color-light-primary);
      }

      &:active {
        background: var(--color-primary);
      }
    }

    &.warning {
      background: var(--color-warning);

      &:hover {
        background: var(--color-light-warning);
      }

      &:active {
        background: var(--color-warning);
      }
    }

    &.danger {
      background: var(--color-danger);

      &:hover {
        background: var(--color-light-danger);
      }

      &:active {
        background: var(--color-danger);
      }
    }

    &.success {
      background: var(--color-success);

      &:hover {
        background: var(--color-light-success);
      }

      &:active {
        background: var(--color-success);
      }
    }

    &.secondary {
      background: var(--color-secondary);

      &:hover {
        background: var(--color-light-secondary);
      }

      &:active {
        background: var(--color-secondary);
      }
    }

    .icon-wrap {
      display: grid;
      place-items: center;

      svg {
        fill: white;
        width: 100%;
        height: 100%;
        transform: scale(.8);
      }
    }
  `
}

function Btn({theme = 'secondary', icon, onClick, children}) {
  let svg;
  switch (icon) {
    case 'cam' : {
      svg = () =>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10.5 -7.5 45 45">
          <g>
            <path
              d="M12.13,0C5.39,.04-.04,5.53,0,12.27c.02,4.08,2.08,7.87,5.48,10.12l-1.91,3.18c-.8,1.34-.36,3.07,.97,3.87,.44,.26,.95,.4,1.46,.4h12.24c1.56,0,2.83-1.25,2.84-2.82,0-.51-.14-1.02-.4-1.46l-1.91-3.18c5.62-3.7,7.18-11.27,3.48-16.89C20.01,2.08,16.21,.02,12.13,0h0Zm6.12,27.01H6.01l2.01-3.36h0c2.66,.96,5.58,.96,8.24,0l1.98,3.36Zm-6.12-5.48c-5.18,0-9.38-4.19-9.39-9.37,0-5.18,4.19-9.38,9.37-9.39s9.38,4.19,9.39,9.37c0,2.49-.99,4.88-2.75,6.64-1.75,1.76-4.14,2.75-6.62,2.75h0Z"/>
            <path
              d="M10.03,12.23c.01,1.16,.96,2.09,2.12,2.08,1.16-.01,2.09-.96,2.08-2.12-.01-1.16-.96-2.09-2.12-2.08-1.14,.01-2.07,.94-2.08,2.08v.04Z"/>
          </g>
        </svg>
      break;
    }
    case 'spk' : {
      svg = () =>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-9 -11 45 45">
          <g>
            <g>
              <path
                d="M25.13,12.34c.2-1.54-.22-3.07-1.18-4.3-.95-1.23-2.33-2.01-3.87-2.21-.31-.04-.61,.04-.86,.23-.25,.19-.4,.47-.44,.78-.07,.63,.37,1.2,1.01,1.29,.03,0,.06,0,.09,0,.8,.12,1.54,.53,2.09,1.14,.46,.54,.75,1.18,.84,1.87,.25,1.92-1.11,3.69-2.96,3.94-.03,0-.08,0-.11,0-.63,.1-1.06,.68-.97,1.33,.1,.58,.59,.98,1.15,.98,.05,0,.11,0,.16-.01,2.65-.34,4.72-2.42,5.05-5.05Z"/>
              <path
                d="M22.7,2.34s-.06-.02-.09-.03h0c-.61-.19-1.26,.14-1.46,.75-.19,.61,.15,1.27,.74,1.45,2.13,.77,3.79,2.43,4.55,4.55,1.4,3.92-.64,8.26-4.57,9.67-.56,.23-.86,.86-.67,1.42,.09,.3,.3,.54,.57,.68,.17,.09,.35,.13,.53,.13,.12,0,.24-.02,.35-.05,.03,0,.05-.02,.08-.03,1.88-.7,3.5-1.94,4.67-3.59,1.17-1.66,1.79-3.62,1.79-5.67,0-4.14-2.61-7.87-6.5-9.28Z"/>
              <path
                d="M16.12,.3c-.49-.24-1.03-.34-1.56-.28-.51,.06-1.02,.27-1.46,.61l-5.08,4.06H3.26c-1.8,0-3.26,1.46-3.26,3.26v7.32c0,1.8,1.46,3.26,3.26,3.26h4.77l5.08,4.06c.5,.41,1.14,.63,1.8,.63,1.55-.01,2.82-1.29,2.82-2.85V2.85c0-.55-.15-1.08-.44-1.52-.3-.46-.7-.81-1.17-1.03Zm-.72,20.07c0,.1-.03,.2-.07,.27-.06,.1-.13,.16-.25,.21-.07,.04-.17,.06-.27,.04-.09,0-.18-.05-.26-.11l-5.42-4.33c-.21-.16-.46-.24-.71-.24H3.26c-.25,0-.48-.1-.66-.28-.17-.16-.27-.4-.27-.65V7.94c0-.25,.1-.48,.28-.66,.16-.17,.4-.27,.65-.27h5.17c.29,0,.57-.11,.75-.28L14.55,2.45c.08-.06,.17-.1,.26-.11,.1,0,.2,0,.28,.05,.1,.05,.18,.12,.23,.2,.05,.08,.07,.17,.07,.27V20.36Z"/>
            </g>
          </g>
        </svg>
      break;
    }
    case 'mic' : {
      svg = () =>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-13 -9 45 45">
          <g>
            <path
              d="M8.9,0C5.75,0,3.21,2.55,3.2,5.69V14.23c0,3.14,2.55,5.69,5.69,5.69s5.69-2.55,5.69-5.69V5.69c0-3.14-2.55-5.69-5.69-5.69Zm0,2.13c1.95-.02,3.54,1.55,3.56,3.5,0,.02,0,.04,0,.06V14.23c0,1.97-1.59,3.56-3.56,3.56s-3.56-1.59-3.56-3.56V5.69c-.02-1.95,1.55-3.54,3.5-3.56,.02,0,.04,0,.06,0h0ZM1.08,11.86c-.59,.02-1.07,.49-1.08,1.09v1.3c0,4.5,3.36,8.29,7.83,8.83v1.82h-2.49c-.59,0-1.07,.48-1.07,1.07s.48,1.07,1.07,1.07h7.12c.59,0,1.07-.48,1.07-1.07s-.48-1.07-1.07-1.07h-2.49v-1.82c4.47-.54,7.83-4.33,7.83-8.83v-1.3c.02-.59-.45-1.08-1.03-1.1-.59-.02-1.08,.45-1.1,1.03,0,.02,0,.04,0,.07v1.3c.05,3.73-2.94,6.8-6.68,6.84-3.73,.05-6.8-2.94-6.84-6.68,0-.05,0-.11,0-.16v-1.3c0-.58-.47-1.06-1.05-1.09h0Z"/>
          </g>
        </svg>
      break;
    }
    case 'setting' : {
      svg = () =>
        <svg viewBox="-19 -18 120 120" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m41 1c-2.7392 0-5.4152 0.28719-8 0.8125v6.1875c-3.4748 0.83887-6.7198 2.1846-9.6875 4l-4.375-4.375c-2.2426 1.4861-4.2923 3.2298-6.1875 5.125-1.8953 1.8952-3.6389 3.9449-5.125 6.1875l4.375 4.375c-1.8154 2.9677-3.1611 6.2126-4 9.6875h-6.1875c-0.5253 2.5848-0.8125 5.2608-0.8125 8s0.2872 5.4152 0.8125 8h6.1875c0.83887 3.4748 2.1846 6.7198 4 9.6875l-4.375 4.375c1.4861 2.2426 3.2297 4.2923 5.125 6.1875 1.8952 1.8952 3.9449 3.6389 6.1875 5.125l4.375-4.375c2.9677 1.8154 6.2126 3.1611 9.6875 4v6.1875c2.5848 0.5253 5.2608 0.8125 8 0.8125 2.7391 0 5.4151-0.2872 8-0.8125v-6.1875c3.4748-0.83888 6.7198-2.1846 9.6875-4l4.375 4.375c2.2426-1.4861 4.2922-3.2298 6.1875-5.125 1.8952-1.8952 3.6389-3.9449 5.125-6.1875l-4.375-4.375c1.8154-2.9677 3.1611-6.2126 4-9.6875h6.1875c0.5253-2.5848 0.8125-5.2608 0.8125-8s-0.2872-5.4152-0.8125-8h-6.1875c-0.8389-3.4748-2.1846-6.7198-4-9.6875l4.375-4.375c-1.4861-2.2426-3.2298-4.2923-5.125-6.1875-1.8953-1.8952-3.9449-3.6389-6.1875-5.125l-4.375 4.375c-2.9677-1.8154-6.2127-3.1611-9.6875-4v-6.1875c-2.5849-0.52531-5.2609-0.8125-8-0.8125zm0 20c11.04 0 20 8.96 20 20s-8.96 20-20 20-20-8.96-20-20 8.96-20 20-20z"
            fill="transparent" stroke="#fff"
            strokeWidth="5"/>
        </svg>


      break;
    }
    case 'share' : {
      svg = () =>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-8.8 -10 50 45">
          <g>
            <g>
              <path d="M10.55,23.18c0,.14,.12,.26,.26,.26h11.21c.14,0,.26-.12,.26-.26v-.91H10.55v.91Z"></path>
              <path
                d="M17.25,6.2c-.46-.46-1.2-.46-1.66,0l-3.32,3.32c-.46,.46-.46,1.2,0,1.66s1.2,.46,1.66,0l1.31-1.31h.01s0,5.37,0,5.37c0,.65,.52,1.17,1.17,1.17s1.17-.52,1.17-1.17v-5.38l1.31,1.31,.04,.04c.47,.45,1.21,.44,1.66-.03,.45-.47,.44-1.21-.03-1.66l-3.32-3.32Z"></path>
              <path
                d="M29.31,0H3.52C1.57,0,0,1.57,0,3.52v15.23c0,1.95,1.58,3.52,3.52,3.52h4.69v.92c0,1.44,1.16,2.6,2.6,2.6h11.21c1.44,0,2.6-1.16,2.6-2.6v-.91h4.69c1.95,0,3.52-1.58,3.52-3.52V3.52c0-1.95-1.58-3.52-3.52-3.52Zm-7.03,23.18c0,.14-.12,.26-.26,.26H10.81c-.14,0-.26-.12-.26-.26v-.91h11.73v.91Zm8.2-4.42c0,.65-.52,1.17-1.17,1.17H3.51c-.65,0-1.17-.52-1.17-1.17V3.52c0-.65,.52-1.17,1.17-1.17H29.31c.65,0,1.17,.52,1.17,1.17v15.24Z"></path>
            </g>
          </g>
        </svg>
    }
  }


  return (
    <S.Btn className={`s-btn ${theme}`} onClick={onClick}>
      <div className="icon-wrap">
        {svg && svg()}
      </div>
      {children}
    </S.Btn>
  );
}

export default Btn;