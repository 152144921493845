import React, { useEffect, useRef } from 'react';
import { getItems, shallow, useStore } from '../../../zustand/store';
import Modal from '../../Components/Modal';
import { useSocket } from '../../../Contexts/SocketContext';
import { useSearchParams } from 'react-router-dom';
export default function AlertMeetingError({ errCode }) {
    const items = ['sid', 'meetingId', 'isOnAlertMeetingError', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const [searchParams, setSearchParams] = useSearchParams();
    const errCodeRef = useRef(null);
    const defError = {
        // 차임 에러
        1: { title: '[1] Left', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        2: { title: '[2] AudioJoinedFromAnotherDevice', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        3: { title: '[3] AudioAuthenticationRejected', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        4: { title: '[4] AudioCallAtCapacity', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        5: { title: '[5] MeetingEnded', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        6: { title: '[6] AudioInternalServerError', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        7: { title: '[7] AudioServiceUnavailable', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        8: { title: '[8] AudioDisconnected', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        9: { title: '[9] VideoCallSwitchToViewOnly', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        10: { title: '[10] VideoCallAtSourceCapacity', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        11: { title: '[11] SignalingBadRequest', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        12: { title: '[12] SignalingInternalServerError', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        13: { title: '[13] SignalingRequestFailed', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        14: { title: '[14] ICEGatheringTimeoutWorkaround', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        15: { title: '[15] ConnectionHealthReconnect', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        16: { title: '[16] RealtimeApiFailed', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        17: { title: '[17] TaskFailed', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        18: { title: '[18] IncompatibleSDP', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        19: { title: '[19] TURNCredentialsForbidden', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        20: { title: '[20] NoAttendeePresent', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        21: { title: '[21] AudioAttendeeRemoved', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        22: { title: '[22] AudioVideoWasRemovedFromPrimaryMeeting', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        23: { title: '[23] AudioDisconnectAudio', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },

        // 소켓 에러
        101: { title: '[101] io server disconnect', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        102: { title: '[102] io client disconnect', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        103: { title: '[103] Ping timeout', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        104: { title: '[104] Transport close', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },
        105: { title: '[105] Transport error', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },

        // 페이지 이탈 에러
        201: { title: '[201] Ping timeout', desc: '네트워크 연결이 불안정합니다\n안정적인 환경에서 다시 시도해주세요.' },

        // 다른 사용자가 접속을 끊고 입장함
        300: { title: '[300] 다른곳에서 접속을 시도했습니다', desc: '다른 사용자가 접속을 시도하여 퇴장되었습니다' },
    };

    useEffect(() => {
        if (z.isOnAlertMeetingError) {
            errCodeRef.current = searchParams.get('errCode');
            searchParams.delete('errCode');
            setSearchParams(searchParams);
        }
    }, [z.isOnAlertMeetingError]);

    const onConfirm = () => {
        z.userActions.setIsOnAlertMeetingError(false);
        window.location.reload();
    };
    const onCancel = () => {
        z.userActions.setIsOnAlertMeetingError(false);
        window.location.reload();
    };
    return (
        <Modal isOpen={z.isOnAlertMeetingError} confirmText="확인" showCancelBtn={false} confrimBtnTheme="danger" onConfirm={onConfirm} onCancel={onCancel} showCloseBtn={true} size="sm">
            <h3 style={{ textAlign: 'center', marginBottom: '2rem' }}>{defError[errCodeRef.current]?.title}</h3>
            <p style={{ textAlign: 'center', color: '#B6B6B6', whiteSpace: 'pre-wrap' }}>{defError[errCodeRef.current]?.desc}</p>
        </Modal>
    );
}
