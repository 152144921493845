import React from 'react';
import styled from "styled-components";
import MicSensor from "./MicSensor";

const S = {
    IconBtn: styled.button`
        transition: 0.15s background;
        color: white;
        border: none;
        border-radius: 40%;
        width: 100%;
        height: 100%;

        &.disabled {
            pointer-events: none;
        }

        &.webinar.disabled {
            opacity: .4;
        }
        
        .icon-wrap {
            display: grid;
            place-items: center;
            position: relative;
            padding: 24%;
            width: 100%;
            height: 100%;

            &.no-padding {
                padding: 0;
            }

            .line {
                width: 85%;
                height: 8px;
                transform: rotate(-45deg);
                background: var(--color-danger);
                position: absolute;
                outline: none;
                border-radius: 10px;
                display: none;
            }
        }
        .label {
            margin-top: 3px;
            color: #9fa0a0;
            font-size: 1.3rem;
            white-space: nowrap;
        }

        path {
            transition: fill 0.075s;
        }

        &.off .icon-wrap .line {
            display: block;
        }

        &.primary {
            background: var(--color-primary);

            .line {
                border: 3px solid var(--color-primary);
            }

            &:hover {
                background: var(--color-light-primary);

                .line {
                    border: 3px solid var(--color-light-primary);
                }
            }

            &:active {
                background: var(--color-primary);

                .line {
                    border: 3px solid var(--color-light-primary);
                }
            }
        }

        &.warning {
            background: var(--color-warning);

            .line {
                border: 3px solid var(--color-warning);
            }

            &:hover {
                background: var(--color-light-warning);

                .line {
                    border: 3px solid var(--color-light-warning);
                }
            }

            &:active {
                background: var(--color-warning);

                .line {
                    border: 3px solid var(--color-warning);
                }
            }
        }

        &.danger {
            background: var(--color-btn-danger);

            .line {
                border: 3px solid var(--color-btn-danger);
            }

            &:hover {
                background: var(--color-btn-danger-hover);

                .line {
                    border: 3px solid var(--color-btn-danger-hover);
                }
            }

            &:active {
                background: var(--color-btn-danger);

                .line {
                    border: 3px solid var(--color-btn-danger);
                }
            }
        }

        &.success {
            background: var(--color-success);

            .line {
                border: 3px solid var(--color-success);
            }

            &:hover {
                background: var(--color-light-success);

                .line {
                    border: 3px solid var(--color-light-success);
                }
            }

            &:active {
                background: var(--color-success);

                .line {
                    border: 3px solid var(--color-success);
                }
            }
        }

        &.secondary {
            background: var(--color-btn-secondary);

            .line {
                border: 3px solid var(--color-btn-secondary);
            }

            &:hover {
                background: var(--color-btn-secondary-hover);

                .line {
                    border: 3px solid var(--color-btn-secondary-hover);
                }
            }

            &:active {
                background: var(--color-btn-secondary);

                .line {
                    border: 3px solid var(--color-btn-secondary);
                }
            }
        }

        &.hover-danger {
            &:hover {
                background: var(--color-btn-danger-hover);
                path {
                    fill: white !important;
                }
                .line {
                    border: 3px solid var(--color-btn-danger-hover);
                }
            }

            &:active {
                background: var(--color-btn-danger);

                .line {
                    border: 3px solid var(--color-btn-danger);
                }
            }
        }
    `,
};

function IconBtn({theme = 'secondary', hoverTheme, isOn = true, onType = 1, onTheme, icon, onClick, force, uuid, disabled, label, tooltip, action}) {
    let svg;
    switch (icon) {
        case 'mic' : {
            svg = (isOn) =>
                <MicSensor uuid={uuid}/>
            break;
        }
        case 'cam' : {
            svg = (isOn) => {
                return (
                    <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 10.18 5.93">
                        <g id="c" data-name="Layer 1">
                            <g>
                                <rect x=".29" y=".29" width="7.05" height="5.34" rx=".84" ry=".84" fill="none"
                                      stroke="#9fa0a0"
                                      strokeLinecap="round" strokeLinejoin="round" strokeWidth=".58"/>
                                <path
                                    d="M9.67,4.71l-1.07-.5c-.13-.06-.21-.19-.21-.33v-1.85c0-.14,.08-.27,.21-.33l1.07-.5c.24-.11,.51,.06,.51,.33v2.85c0,.26-.27,.44-.51,.33Z"
                                    style={{fill: isOn ? 'var(--color-success)' : '#9fa0a0'}}/>
                            </g>
                        </g>
                    </svg>
                )
            }
            break;
        }
        case 'viewmode' : {
            svg = (isOn) =>
                isOn ?
                    <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.09 15.09">
                        <g id="c" data-name="Layer 1">
                            <g>
                                <rect width="6.86" height="6.86" rx="1.79" ry="1.79" fill="#393332"/>
                                <rect x="8.23" width="6.86" height="6.86" rx="1.79" ry="1.79" fill="#393332"/>
                                <rect y="8.23" width="6.86" height="6.86" rx="1.79" ry="1.79" fill="#393332"/>
                                <rect x="8.23" y="8.23" width="6.86" height="6.86" rx="1.79" ry="1.79" fill="#393332"/>
                            </g>
                        </g>
                    </svg> :
                    <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.19 16.19">
                        <g id="c" data-name="Layer 1">
                            <path
                                d="M13.54,0H2.66C1.19,0,0,1.19,0,2.66V13.54c0,1.46,1.19,2.66,2.66,2.66H13.54c1.46,0,2.66-1.19,2.66-2.66V2.66c0-1.46-1.19-2.66-2.66-2.66Zm1.55,2.66V7.54h-6.44V1.11h4.89c.86,0,1.55,.7,1.55,1.55ZM2.66,1.11H7.54V7.54H1.1V2.66c0-.86,.7-1.55,1.55-1.55ZM1.1,13.54v-4.89H7.54v6.44H2.66c-.86,0-1.55-.7-1.55-1.55Zm12.43,1.55h-4.89v-6.44h6.44v4.89c0,.86-.7,1.55-1.55,1.55Z"
                                fill="#9fa0a0"/>
                        </g>
                    </svg>
            break;
        }
        case 'contentShare' : {
            svg = (isOn) => {
                if (!isOn) {
                    return <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.79 16.35">
                        <g id="c" data-name="Layer 1">
                            <g>
                                <path
                                    d="M15.25,0H2.54C1.14,0,0,1.14,0,2.54V11.21c0,1.4,1.14,2.54,2.54,2.54h3.02l-.82,1.52c-.26,.49,.09,1.08,.64,1.08h7.02c.55,0,.9-.59,.64-1.08l-.82-1.52h3.02c1.4,0,2.54-1.14,2.54-2.54V2.54c0-1.4-1.14-2.54-2.54-2.54Zm1.44,11.21c0,.79-.64,1.44-1.44,1.44H2.54c-.79,0-1.44-.64-1.44-1.44V2.54c0-.79,.64-1.44,1.44-1.44H15.25c.79,0,1.44,.64,1.44,1.44V11.21Z"
                                    style={{fill: 'var(--color-success)'}}/>
                                <g>
                                    <path
                                        d="M8.89,10.67c-.31,0-.55-.25-.55-.55V3.86c0-.3,.25-.55,.55-.55s.55,.25,.55,.55v6.26c0,.3-.25,.55-.55,.55Z"
                                        style={{fill: 'var(--color-success)'}}/>
                                    <path
                                        d="M11.45,7.01c-.14,0-.29-.05-.39-.16l-2.17-2.19-2.17,2.19c-.21,.22-.56,.22-.78,0-.22-.21-.22-.56,0-.78l2.56-2.59c.21-.21,.58-.21,.79,0l2.56,2.59c.21,.22,.21,.57,0,.78-.11,.11-.25,.16-.39,.16Z"
                                        style={{fill: 'var(--color-success)'}}/>
                                </g>
                            </g>
                        </g>
                    </svg>
                } else {
                    return <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.66 8.88">
                        <g id="c" data-name="Layer 1">
                            <path
                                d="M8.28,0H1.38C.62,0,0,.62,0,1.38V6.08c0,.76,.62,1.38,1.38,1.38h1.64l-.45,.83c-.14,.26,.05,.58,.35,.58h3.81c.3,0,.49-.32,.35-.58l-.45-.83h1.64c.76,0,1.38-.62,1.38-1.38V1.38c0-.76-.62-1.38-1.38-1.38Zm.78,6.08c0,.43-.35,.78-.78,.78H1.38c-.43,0-.78-.35-.78-.78V1.38c0-.43,.35-.78,.78-.78h6.9c.43,0,.78,.35,.78,.78V6.08Z"
                                fill="#fff"/>
                            <rect x="3.36" y="2.33" width="2.94" height="2.94" rx=".46" ry=".46" fill="#fff"/>
                        </g>
                    </svg>
                }
            }

            break;
        }
        case 'people' : {
            svg = (isOn) =>
                isOn ?
                    <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.41 14.88">
                        <g id="c" data-name="Layer 1">
                            <g>
                                <path
                                    d="M11.22,.55c-.32,0-.63,.06-.93,.15,.69,.86,1.11,1.93,1.11,3.11s-.43,2.25-1.11,3.11c.3,.09,.6,.15,.93,.15,1.8,0,3.26-1.46,3.26-3.26s-1.46-3.26-3.26-3.26Z"
                                    fill="#383839"/>
                                <path
                                    d="M13.35,8.54h-1.51c1.18,.96,1.96,2.42,1.96,4.06v2.28h3.06c.31,0,.55-.25,.55-.55v-1.73c0-2.24-1.82-4.06-4.06-4.06Z"
                                    fill="#383839"/>
                                <circle cx="6.41" cy="3.81" r="3.81" fill="#383839"/>
                                <path
                                    d="M8.54,8.54H4.06c-2.24,0-4.06,1.82-4.06,4.06v1.73c0,.3,.25,.55,.55,.55H12.05c.31,0,.55-.25,.55-.55v-1.73c0-2.24-1.82-4.06-4.06-4.06Z"
                                    fill="#383839"/>
                            </g>
                        </g>
                    </svg> :
                    <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.41 14.88">
                        <g id="c" data-name="Layer 1">
                            <g>
                                <path
                                    d="M11.22,.55c-.32,0-.63,.06-.93,.15,.69,.86,1.11,1.93,1.11,3.11s-.43,2.25-1.11,3.11c.3,.09,.6,.15,.93,.15,1.8,0,3.26-1.46,3.26-3.26s-1.46-3.26-3.26-3.26Z"
                                    fill="#9fa0a0"/>
                                <path
                                    d="M6.41,7.63c-2.1,0-3.81-1.71-3.81-3.81S4.31,0,6.41,0s3.81,1.71,3.81,3.81-1.71,3.81-3.81,3.81Zm0-6.52c-1.49,0-2.71,1.21-2.71,2.71s1.22,2.71,2.71,2.71,2.71-1.21,2.71-2.71-1.22-2.71-2.71-2.71Z"
                                    fill="#9fa0a0"/>
                                <path
                                    d="M12.05,14.88c-.31,0-.55-.25-.55-.55v-1.73c0-1.63-1.33-2.95-2.95-2.95H4.06c-1.63,0-2.95,1.33-2.95,2.95v1.73c0,.3-.25,.55-.55,.55s-.55-.25-.55-.55v-1.73c0-2.24,1.82-4.06,4.06-4.06h4.48c2.24,0,4.06,1.82,4.06,4.06v1.73c0,.3-.25,.55-.55,.55Z"
                                    fill="#9fa0a0"/>
                                <path
                                    d="M13.35,8.54h-1.51c.4,.32,.76,.68,1.05,1.11h.46c1.63,0,2.95,1.33,2.95,2.95v1.73c0,.3,.25,.55,.55,.55s.55-.25,.55-.55v-1.73c0-2.24-1.82-4.06-4.06-4.06Z"
                                    fill="#9fa0a0"/>
                            </g>
                        </g>
                    </svg>
            break;
        }
        case 'chat' : {
            svg = (isOn) =>
                isOn ?
                    <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.24 15.71">
                        <g id="c" data-name="Layer 1">
                            <path
                                d="M14.69,0H2.55C1.15,0,0,1.15,0,2.55V14.61c0,.42,.24,.8,.61,.99,.15,.08,.32,.12,.49,.12,.24,0,.48-.08,.67-.23l3.32-2.55H14.69c1.41,0,2.55-1.15,2.55-2.55V2.55c0-1.41-1.15-2.55-2.55-2.55Z"
                                fill="#393332"/>
                        </g>
                    </svg> :
                    <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.24 15.71">
                        <g id="c" data-name="Layer 1">
                            <path
                                d="M1.1,15.71c-.17,0-.33-.04-.49-.11-.38-.19-.62-.57-.62-.99V2.55C0,1.15,1.15,0,2.55,0H14.69c1.41,0,2.55,1.15,2.55,2.55v7.83c0,1.41-1.15,2.55-2.55,2.55H5.09l-3.32,2.55c-.19,.15-.43,.23-.67,.23ZM2.55,1.12c-.79,0-1.43,.64-1.43,1.43V14.61l3.58-2.8H14.69c.79,0,1.43-.64,1.43-1.43V2.55c0-.79-.64-1.43-1.43-1.43H2.55Z"
                                fill="#9fa0a0"/>
                        </g>
                    </svg>
            break;
        }
        case 'emoji' : {
            svg = (isOn) => <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.25 16.25">
                <g id="c" data-name="Layer 1">
                    <g>
                        <path
                            d="M8.12,16.25C3.64,16.25,0,12.6,0,8.12S3.64,0,8.12,0s8.12,3.64,8.12,8.12-3.64,8.12-8.12,8.12Zm0-15.14C4.25,1.11,1.1,4.25,1.1,8.12s3.15,7.02,7.02,7.02,7.02-3.15,7.02-7.02S11.99,1.11,8.12,1.11Z"
                            fill="#9fa0a0"/>
                        <circle cx="5.57" cy="6.63" r=".84" fill="#9fa0a0"/>
                        <circle cx="10.67" cy="6.63" r=".84" fill="#9fa0a0"/>
                        <path
                            d="M8.12,13.27c-1.64,0-3.15-.77-4.13-2.11-.18-.25-.13-.59,.12-.77,.25-.18,.59-.13,.77,.12,.77,1.05,1.95,1.66,3.24,1.66s2.47-.6,3.24-1.66c.18-.25,.52-.3,.77-.12,.25,.18,.3,.53,.12,.77-.98,1.34-2.49,2.11-4.13,2.11Z"
                            fill="#9fa0a0"/>
                    </g>
                </g>
            </svg>
            break;
        }
        case 'record' : {
            svg = (isOn) =>
                <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.25 16.25">
                    <g id="c" data-name="Layer 1">
                        <g>
                            <path
                                d="M8.12,16.25C3.64,16.25,0,12.6,0,8.12S3.64,0,8.12,0s8.12,3.64,8.12,8.12-3.64,8.12-8.12,8.12Zm0-15.14C4.25,1.11,1.1,4.25,1.1,8.12s3.15,7.02,7.02,7.02,7.02-3.15,7.02-7.02S11.99,1.11,8.12,1.11Z"
                                style={{fill: isOn ? "var(--color-danger)" : "#9fa0a0"}}/>
                            <circle cx="8.12" cy="8.12" r="3.76" style={{fill: isOn ? "var(--color-danger)" : "#9fa0a0"}}/>
                        </g>
                    </g>
                </svg>
            break;
        }
        case 'webinar' : {
            svg = (isOn) =>
                <svg width="25" height="25" viewBox="0 1 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path style={{fill: isOn ? "var(--color-danger)" : "#9fa0a0"}} d="M13.5739 13.1891C15.1908 13.1891 16.5016 11.8784 16.5016 10.2615C16.5016 8.64455 15.1908 7.33374 13.5739 7.33374C11.957 7.33374 10.6462 8.64455 10.6462 10.2615C10.6462 11.8784 11.957 13.1891 13.5739 13.1891Z"></path>
                    <path style={{fill: isOn ? "var(--color-danger)" : "#9fa0a0"}} fillRule="evenodd" clipRule="evenodd" d="M17.831 4.36699C18.2565 3.9228 18.9615 3.90766 19.4057 4.33316C21.0268 5.88611 22.0384 8.0759 22.0384 10.5001C22.0384 12.9242 21.0268 15.114 19.4057 16.6668C18.9615 17.0923 18.2564 17.0772 17.8309 16.633C17.4055 16.1888 17.4206 15.4838 17.8648 15.0583C19.0655 13.9081 19.8109 12.2921 19.8109 10.5001C19.8109 8.70802 19.0655 7.09194 17.8648 5.9417C17.4206 5.5162 17.4055 4.81117 17.831 4.36699Z"></path>
                    <path style={{fill: isOn ? "var(--color-danger)" : "#9fa0a0"}} fillRule="evenodd" clipRule="evenodd" d="M9.16903 16.6328C8.74353 17.077 8.0385 17.0921 7.59432 16.6666C5.9732 15.1136 4.96155 12.9239 4.96155 10.4997C4.96155 8.07552 5.97321 5.88579 7.59434 4.33291C8.03853 3.90742 8.74356 3.92258 9.16905 4.36677C9.59455 4.81097 9.57939 5.51599 9.13519 5.94149C7.93448 7.09166 7.18905 8.70766 7.18905 10.4997C7.18905 12.2917 7.93448 13.9078 9.13521 15.0581C9.5794 15.4836 9.59454 16.1886 9.16903 16.6328Z"></path>
                    <path style={{fill: isOn ? "var(--color-danger)" : "#9fa0a0"}} fillRule="evenodd" clipRule="evenodd" d="M21.3673 0.882895C21.7979 0.443652 22.503 0.436652 22.9423 0.867259C25.4382 3.31412 26.9888 6.72711 26.9888 10.5002C26.9888 14.2733 25.4382 17.6862 22.9422 20.133C22.503 20.5636 21.7978 20.5565 21.3672 20.1173C20.9366 19.678 20.9437 18.9729 21.3829 18.5423C23.4691 16.4972 24.7613 13.6507 24.7613 10.5002C24.7613 7.34976 23.4691 4.50308 21.3829 2.4579C20.9437 2.02729 20.9367 1.32214 21.3673 0.882895Z"></path>
                    <path style={{fill: isOn ? "var(--color-danger)" : "#9fa0a0"}} fillRule="evenodd" clipRule="evenodd" d="M5.63274 20.1173C5.20213 20.5566 4.49698 20.5636 4.05774 20.133C1.56181 17.6861 0.0111727 14.2731 0.011173 10.5C0.0111733 6.72692 1.56182 3.31403 4.05776 0.867273C4.49701 0.436677 5.20216 0.443694 5.63276 0.882947C6.06336 1.3222 6.05634 2.02735 5.61709 2.45795C3.53091 4.50302 2.23867 7.34958 2.23867 10.5C2.23867 13.6505 3.53091 16.4972 5.61711 18.5423C6.05635 18.973 6.06335 19.6781 5.63274 20.1173Z"></path>
                </svg>
            break;
        }
        case 'setting' : {
            svg = (isOn) =>
                isOn ?
                    <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.06 17.06">
                        <g id="c" data-name="Layer 1">
                            <g>
                                <circle cx="8.53" cy="8.53" r="2.65" fill="#fff"/>
                                <path
                                    d="M17.06,11.01V6.05h-1.8c-.07-.18-.14-.35-.22-.52l1.28-1.28L12.81,.74l-1.28,1.28c-.17-.08-.34-.15-.52-.22V0H6.05V1.8c-.18,.07-.35,.14-.52,.22l-1.28-1.28L.74,4.25l1.28,1.28c-.08,.17-.15,.34-.22,.52H0v4.96H1.8c.06,.18,.14,.35,.22,.52l-1.28,1.28,3.51,3.51,1.28-1.28c.17,.08,.34,.15,.52,.22v1.8h4.96v-1.8c.18-.06,.35-.14,.52-.22l1.28,1.28,3.51-3.51-1.28-1.28c.08-.17,.15-.34,.22-.52h1.8Zm-8.53,.16c-1.46,0-2.65-1.18-2.65-2.65s1.18-2.65,2.65-2.65,2.65,1.18,2.65,2.65-1.18,2.65-2.65,2.65Z"
                                    fill="#393332"/>
                            </g>
                        </g>
                    </svg> :
                    <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.04 17.04">
                        <g id="c" data-name="Layer 1">
                            <path
                                d="M10.44,17.04h-3.84c-.31,0-.55-.25-.55-.55v-1.25c-.18-.07-.36-.14-.53-.22l-.88,.88c-.22,.21-.57,.21-.78,0l-2.71-2.71c-.22-.22-.22-.57,0-.78l.88-.88c-.08-.17-.15-.35-.22-.53H.55c-.31,0-.55-.25-.55-.55v-3.84c0-.3,.25-.55,.55-.55H1.8c.07-.18,.14-.36,.22-.53l-.88-.88c-.22-.22-.22-.57,0-.78L3.85,1.14c.22-.21,.57-.21,.78,0l.88,.88c.17-.08,.35-.15,.53-.22V.55c0-.3,.25-.55,.55-.55h3.84c.31,0,.55,.25,.55,.55V1.8c.18,.07,.36,.14,.53,.22l.88-.88c.22-.21,.57-.21,.78,0l2.71,2.71c.22,.22,.22,.57,0,.78l-.88,.88c.08,.17,.15,.35,.22,.53h1.25c.31,0,.55,.25,.55,.55v3.84c0,.3-.25,.55-.55,.55h-1.25c-.07,.18-.14,.36-.22,.53l.88,.88c.22,.22,.22,.57,0,.78l-2.71,2.71c-.22,.21-.56,.22-.78,0l-.88-.88c-.17,.08-.35,.15-.53,.22v1.25c0,.3-.25,.55-.55,.55Zm-3.29-1.11h2.73v-1.09c0-.24,.16-.46,.39-.53,.38-.12,.75-.27,1.09-.45,.21-.11,.48-.08,.65,.1l.77,.77,1.93-1.93-.77-.77c-.17-.17-.21-.44-.1-.65,.19-.35,.34-.71,.45-1.1,.07-.23,.29-.39,.53-.39h1.09v-2.73h-1.09c-.24,0-.46-.16-.53-.39-.12-.38-.27-.75-.45-1.09-.11-.21-.08-.48,.1-.65l.77-.77-1.93-1.93-.77,.77c-.17,.17-.44,.21-.65,.1-.35-.19-.72-.34-1.09-.45-.23-.07-.39-.29-.39-.53V1.11h-2.73v1.09c0,.24-.16,.46-.39,.53-.38,.11-.74,.27-1.09,.45-.21,.12-.48,.08-.65-.1l-.77-.77-1.93,1.93,.77,.77c.17,.17,.21,.44,.1,.65-.19,.35-.34,.72-.45,1.09-.07,.23-.29,.39-.53,.39H1.1v2.73h1.09c.24,0,.46,.16,.53,.39,.11,.38,.27,.75,.45,1.09,.12,.21,.08,.48-.1,.65l-.77,.77,1.93,1.93,.77-.77c.17-.17,.44-.21,.65-.1,.35,.19,.71,.34,1.1,.45,.23,.07,.39,.29,.39,.53v1.09Zm1.37-4.22c-1.76,0-3.2-1.43-3.2-3.2s1.43-3.2,3.2-3.2,3.2,1.43,3.2,3.2-1.43,3.2-3.2,3.2Zm0-5.29c-1.15,0-2.09,.94-2.09,2.09s.94,2.09,2.09,2.09,2.09-.94,2.09-2.09-.94-2.09-2.09-2.09Z"
                                fill="#9fa0a0"/>
                        </g>
                    </svg>
            break;
        }
        case 'exit' : {
            svg = (isOn) =>
                <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.7 17.08">
                    <g id="c" data-name="Layer 1">
                        <g>
                            <path
                                d="M12.85,17.08H4.89c-1.57,0-2.85-1.28-2.85-2.85v-1.85c0-.31,.25-.56,.56-.56s.56,.25,.56,.56v1.85c0,.95,.77,1.73,1.73,1.73h7.96c.95,0,1.73-.77,1.73-1.73V2.85c0-.95-.77-1.73-1.73-1.73H4.89c-.95,0-1.73,.77-1.73,1.73v1.85c0,.31-.25,.56-.56,.56s-.56-.25-.56-.56v-1.85C2.04,1.28,3.32,0,4.89,0h7.96c1.57,0,2.85,1.28,2.85,2.85V14.23c0,1.57-1.28,2.85-2.85,2.85Z"
                                fill="#9fa0a0"/>
                            <path
                                d="M11.51,8.76c.03-.07,.04-.14,.04-.22s-.02-.15-.04-.22c-.03-.07-.07-.13-.12-.18,0,0,0,0,0,0l-3.63-3.58c-.22-.22-.58-.22-.8,0-.22,.22-.22,.58,0,.8l2.65,2.62H.56c-.31,0-.56,.25-.56,.56s.25,.56,.56,.56H9.62l-2.65,2.62c-.22,.22-.22,.57,0,.8,.11,.11,.26,.17,.4,.17s.29-.05,.4-.16l3.63-3.58s0,0,0,0c.05-.05,.09-.11,.12-.18Z"
                                fill="#9fa0a0"/>
                        </g>
                    </g>
                </svg>
            break;
        }
        case 'spk' : {
            svg = (isOn) =>
                <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.84 11.53">
                    <g id="c" data-name="Layer 1">
                        <g>
                            <path
                                d="M5.65,.86l-1.45,1.52c-.2,.21-.48,.33-.77,.33H1.6c-.59,0-1.07,.48-1.07,1.07v3.98c0,.59,.48,1.07,1.07,1.07h1.83c.29,0,.57,.12,.77,.33l1.45,1.52c.2,.21,.48,.33,.77,.33h1.71c.42,0,.76-.34,.76-.76V1.29c0-.42-.34-.76-.76-.76h-1.71c-.29,0-.57,.12-.77,.33Z"
                                fill="#9fa0a0" stroke="#9fa0a0" strokeLinecap="round" strokeLinejoin="round"
                                strokeWidth="1.06"/>
                            <path d="M11.34,3.35c.7,.57,1.14,1.44,1.14,2.42s-.45,1.84-1.14,2.42" fill="none"
                                  style={{stroke: isOn ? 'var(--color-success)' : '#9fa0a0'}} strokeLinecap="round"
                                  strokeLinejoin="round" strokeWidth="1.06"/>
                            <path d="M13.25,1.11c1.27,1.15,2.06,2.81,2.06,4.66s-.8,3.51-2.07,4.66" fill="none"
                                  style={{stroke: isOn ? 'var(--color-success)' : '#9fa0a0'}} strokeLinecap="round"
                                  strokeLinejoin="round" strokeWidth="1.06"/>
                        </g>
                    </g>
                </svg>
            break;
        }
        case 'sendMessage' : {
            svg = (isOn) =>
                <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.25 14.25">
                    <g id="c" data-name="Layer 1">
                        <g>
                            <path
                                d="M13.34,0L.46,3.79C.2,3.87,.02,4.1,0,4.36c-.02,.27,.13,.52,.37,.63l5.44,2.53L13.34,0Z"
                                fill="#fff"/>
                            <path
                                d="M6.72,8.44l2.53,5.44c.11,.23,.34,.37,.58,.37,.02,0,.03,0,.05,0,.27-.02,.49-.2,.57-.46L14.25,.91l-7.53,7.53Z"
                                fill="#fff"/>
                        </g>
                    </g>
                </svg>
            break;
        }
        case 'fullscreen' : {
            svg = (isOn) => {
                if (!isOn) {
                    return <svg id="b" data-name="레이어 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.73 14.73" style={{pointerEvents: 'none'}}>
                        <g id="c" data-name="Layer 1">
                            <g>
                                <path d="M.51,5.01V2.61C.51,1.45,1.45,.51,2.61,.51h2.4" fill="none" stroke="#9fa0a0"
                                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.03"/>
                                <path d="M5.01,14.21H2.61c-1.16,0-2.1-.94-2.1-2.1v-2.4" fill="none" stroke="#9fa0a0"
                                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.03"/>
                                <path d="M14.21,5.01V2.61c0-1.16-.94-2.1-2.1-2.1h-2.4" fill="none" stroke="#9fa0a0"
                                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.03"/>
                                <path d="M9.72,14.21h2.4c1.16,0,2.1-.94,2.1-2.1v-2.4" fill="none" stroke="#9fa0a0"
                                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.03"/>
                            </g>
                        </g>
                    </svg>
                } else {
                    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.51 15.51" style={{pointerEvents: 'none'}}>
                        <g id="레이어_2" data-name="레이어 2">
                            <g id="Layer_1" data-name="Layer 1">
                                <path d="M5.27.51v3.3A1.46,1.46,0,0,1,3.81,5.27H.51" fill="none" stroke="#9fa0a0"
                                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.03"/>
                                <path d="M.51,10.25h3.3A1.45,1.45,0,0,1,5.27,11.7V15" fill="none" stroke="#9fa0a0"
                                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.03"/>
                                <path d="M10.25.51v3.3A1.45,1.45,0,0,0,11.7,5.27H15" fill="none" stroke="#9fa0a0"
                                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.03"/>
                                <path d="M15,10.25H11.7a1.45,1.45,0,0,0-1.45,1.45V15" fill="none" stroke="#9fa0a0"
                                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.03"/>
                            </g>
                        </g>
                    </svg>
                }
            }
            break;
        }
    }
    return (
        <S.IconBtn
            className={`
                s-icon-btn
                ${onType === 1 ? theme : ''} 
                ${onType === 1 ? isOn ? 'on' : 'off' : ''}
                ${(onType === 2 && isOn) ? onTheme : theme} 
                ${icon}
                ${disabled ? 'disabled' : ''}
                ${hoverTheme ? `hover-${hoverTheme}` : ''}
                ${action ? 'action' : ''}
            `}
            onClick={onClick}>
            <div className={`icon-wrap ${action ? 'action' : ''}`}>
                {svg && svg(isOn)}
                <div className="line"></div>
            </div>
            { label && <div className="label">{label}</div>}
        </S.IconBtn>
    );
}

export default IconBtn;