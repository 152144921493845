import React from 'react';
import IconBtn from '../Components/IconBtn';
import {getItems, shallow, useStore} from "../../zustand/store";


function BtnMic({isLoginPage, label}) {
    const items = ['uuid', 'isOnMic', 'computeIsOnForceMute', 'isOnForceMuteAll', 'forceMuteList', 'unForceMuteList', 'userActions']
    const z = useStore(getItems(items), shallow)

    const toggleMic = () => {
        let force = document.querySelector('.footer .mic-sensor')?.classList.contains('force');
        if (force) {
            alert('호스트에 의해 음소거되었습니다.\n호스트에게 음소거 해제를 요청해주세요')
        } else {
            z.userActions.setIsOnMic(!z.isOnMic)
        }
    }

    return (
        <IconBtn
            icon='mic'
            theme="secondary"
            noPadding={true}
            isOn={z.isOnMic}
            onClick={toggleMic}
            uuid={z.uuid}
            label={label}
        />
    );
}

export default BtnMic;