import React from 'react';
import styled from 'styled-components';
const S = {
    Container: styled.div`
        width: 100%;
        height: 100%;
        font-size: 1.4rem;
        overflow-y: auto;
        padding: 0;

        background: ${({ bgImg }) => `url(${bgImg})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #0000007e;
            text-align: center;

            .logo-wrap {
                width: ${({bgImgTy}) => bgImgTy === 1 ? '200px' : '250px'};
                margin-top: -5rem;
                margin-bottom: ${({bgImgTy}) => bgImgTy === 1 ? '0' : '3rem'};
                img {
                    width: 100%;
                }
            }

            .loader {
                color: #ffffff;
                font-size: 35px;
                text-indent: -9999em;
                overflow: hidden;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                position: relative;
                transform: translateZ(0);
                animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
                margin-bottom: 3rem;
            }

            @keyframes mltShdSpin {
                0% {
                    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
                }
                5%,
                95% {
                    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
                }
                10%,
                59% {
                    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
                        -0.297em -0.775em 0 -0.477em;
                }
                20% {
                    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
                        -0.749em -0.34em 0 -0.477em;
                }
                38% {
                    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
                        -0.82em -0.09em 0 -0.477em;
                }
                100% {
                    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
                }
            }

            @keyframes round {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            .title {
                font-weight: 700;
                font-size: 2.5rem;
            }

            .content {
                font-size: 2rem;
            }
        }
    `,
};
export default function Cover({ bgImg, logoImg, useLoader, title, content }) {
    return (
        <S.Container bgImg={bgImg}>
            <div className="wrapper">
                {logoImg && <div className='logo-wrap'><img src={logoImg} alt="" /></div>}
                {useLoader && <div className="loader"></div>}
                {title && <div className="title">{title}</div>}
                {content && <div className="content">{content}</div>}
            </div>
        </S.Container>
    );
}
