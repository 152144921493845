import React, { useEffect } from 'react';
import { getItems, shallow, useStore } from '../../../zustand/store';
import Modal from '../../Components/Modal';
import { useSocket } from '../../../Contexts/SocketContext';
import { useNavigate } from 'react-router-dom';

export default function AlertExit({ meetingType }) {
    const items = ['meetingIdx', 'uuid', 'sid', 'infoTy', 'isOnAlertExit', 'isMakeHost', 'userActions'];
    const z = useStore(getItems(items), shallow);
    const socket = useSocket();
    const navigate = useNavigate();
    // 취소
    const onCancel = () => {
        z.userActions.setIsOnAlertExit(false);
    };

    // 회의 나가기
    const onSecondBtnClick = () => {
        navigate(`/${meetingType === 'webinar' ? '' : meetingType + '/'}login/${z.meetingIdx}/${z.uuid}`, { replace: true })
        z.userActions.setIsOnAlertExit(false);
    };

    // 회의 종료
    const onConfirm = () => {
        socket.request.closeMeeting();
        z.userActions.setIsOnAlertExit(false);
    };

    return (
        <Modal
            showConfirmBtn={false}
            confirmText="모두에 대해 회의 종료"
            confrimBtnTheme="danger"
            onConfirm={onConfirm}

            showSecondBtn={true}
            secondBtnText="회의 나가기"
            secondBtnTheme={z.brmeet && (z.infoTy === 1 || z.isMakeHost) ? 'secondary' : 'danger'}
            onSecondBtnClick={onSecondBtnClick}
            
            showCancelBtn={true}
            cancelText="취소"
            onCancel={onCancel}
            showCloseBtn={true}
            isOpen={z.isOnAlertExit}
            size="sm"
        >
            {z.brmeet && (z.infoTy === 1 || z.isMakeHost) ? (
                <>
                    <h3 style={{ textAlign: 'center', marginBottom: '2rem' }}>회의를 종료하시겠습니까?</h3>
                    <p style={{ textAlign: 'center', color: '#B6B6B6' }}>
                        종료를 원하지 않을 경우,
                        <br />
                        회의 나가기 버튼을 클릭해주시기 바랍니다.
                    </p>
                </>
            ) : (
                <>
                    <h3 style={{ textAlign: 'center', marginBottom: '2rem' }}>회의에서 나가시겠습니까?</h3>
                    <p style={{ textAlign: 'center', color: '#B6B6B6' }}>
                        나가기를 원할 경우,
                        <br />
                        회의 나가기 버튼을 클릭해주시기 바랍니다.
                    </p>
                </>
            )}
        </Modal>
    );
}
